import React, { Component } from "react";
import { FiZap } from "react-icons/fi";

// Assets
import quizIllustration from "../../../assets/quiz-illustration.svg";
import wotdIllustration from "../../../assets/wotd-illustration.svg";
import unizoneIllustration from "../../../assets/unizone-illustration.svg";

class IntroCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showQuizEntry: false, //  Enable / disable quiz entry
    };
  }

  componentDidMount() {
    // For batch state updates
    let stateUpdates = {};

    // Enable quiz if time is greater than 6 PM to next day 12PM
    const d = new Date(
      new Date().toLocaleString("en-US", { timeZone: "Asia/Calcutta" })
    );
    if (d.getHours() >= 18 || d.getHours() < 12) {
      stateUpdates.showQuizEntry = true;
    }

    // State update
    this.setState(stateUpdates);
  }

  render() {
    // Text based on selected language
    let textContent =
      this.props.language === "EN"
        ? {
            header: "Trending News",
          }
        : {
            header: "ട്രെൻഡിംഗ് വാർത്തകൾ",
          };

    // Trim list to 4 items
    let trendingItems = JSON.parse(
      JSON.stringify(this.props.item.intro_trending_items)
    );
    if (this.props.screenViewHeight > 800 && trendingItems.length > 4) {
      trendingItems = trendingItems.splice(0, 4);
    } else if (this.props.screenViewHeight <= 800 && trendingItems.length > 3) {
      trendingItems = trendingItems.splice(0, 3);
    }
    let firstItem = trendingItems[0] || {};

    return (
      <div className="pt-[8vh] h-full flex flex-col overflow-hidden">
        {/* {this.props.screenViewHeight > 800 && (
        )} */}
        <div className="primary-swiper-disable-el overflow-auto w-full flex pb-2 pt-1">
          <div
            className="flex flex-col items-center shrink-0 w-1/4"
            onClick={() => {
              window.gtag("event", "top_ten_from_intro");
              this.props.toggleOverlay("STORIES_TOP10");
            }}
          >
            <div className="outline outline-2 outline-accent rounded-full">
              {this.props.topTenHighlights &&
                this.props.topTenHighlights.length > 0 && (
                  <img
                    data-src={this.props.topTenHighlights[0].image_link}
                    className="w-16 h-16 rounded-full object-cover object-center lazyload blur-up border-4 border-transparent"
                    alt=""
                  />
                )}
            </div>
            <span className="text-lts dark:text-dts text-xs mt-1">
              {this.props.language === "EN" ? "Top-10" : "ടോപ്-10"}
            </span>
          </div>
          {this.state.showQuizEntry && (
            <div
              className="flex flex-col items-center shrink-0 w-1/4"
              onClick={() => {
                window.gtag("event", "quiz_from_intro");
                this.props.toggleOverlay("STORIES_QUIZ");
              }}
            >
              <div className="w-16 h-16 rounded-full border-4 border-transparent outline outline-2 outline-[#33bf31]">
                <div className="bg-gradient-to-r from-[#33bf31] to-[#05acb5] w-full h-full rounded-full p-2">
                  <img
                    data-src={quizIllustration}
                    className="w-full h-full rounded-full object-contain object-center lazyload blur-up"
                    alt=""
                  />
                </div>
              </div>
              <span className="text-lts dark:text-dts text-xs mt-1 whitespace-nowrap">
                {this.props.language === "EN" ? "Quiz" : "ക്വിസ്"}
              </span>
            </div>
          )}
          <div
            className="flex flex-col items-center shrink-0 w-1/4"
            onClick={() => {
              this.props.toggleOverlay("STORIES_UNIZONE");
            }}
          >
            <div className="w-16 h-16 rounded-full border-4 border-transparent outline outline-2 outline-[#FFD200]">
              <div className="bg-gradient-to-r from-[#F7971E] to-[#FFD200] w-full h-full rounded-full p-2">
                <img
                  data-src={unizoneIllustration}
                  className="w-full h-full rounded-full object-contain object-center lazyload blur-up"
                  alt=""
                />
              </div>
            </div>
            <span className="text-lts dark:text-dts text-xs mt-1 whitespace-nowrap">
              {this.props.language === "EN" ? "Earn" : "പണം നേടാം"}
            </span>
          </div>
          <div
            className="flex flex-col items-center shrink-0 w-1/4"
            onClick={() => {
              window.gtag("event", "wotd_from_intro");
              this.props.toggleOverlay("STORIES_MISC");
            }}
          >
            <div className="w-16 h-16 rounded-full border-4 border-transparent outline outline-2 outline-[#BD3F32]">
              <div className="bg-gradient-to-r from-[#355C7D] to-[#BD3F32] w-full h-full rounded-full p-2">
                <img
                  data-src={wotdIllustration}
                  className="w-full h-full rounded-full object-contain object-center lazyload blur-up"
                  alt=""
                />
              </div>
            </div>
            <span className="text-lts dark:text-dts text-xs mt-1 whitespace-nowrap">
              {this.props.language === "EN" ? "Extras" : "കലവറ"}
            </span>
          </div>
          {!this.state.showQuizEntry && (
            <div
              className="flex flex-col items-center shrink-0 w-1/4 grayscale"
              onClick={() => this.props.toggleInfoModal(true, "QUIZ_COUNTDOWN")}
            >
              <div className="w-16 h-16 rounded-full border-4 border-transparent outline outline-2 outline-[#33bf31]">
                <div className="bg-gradient-to-r from-[#33bf31] to-[#05acb5] w-full h-full rounded-full p-2">
                  <img
                    data-src={quizIllustration}
                    className="w-full h-full rounded-full object-contain object-center lazyload blur-up"
                    alt=""
                  />
                </div>
              </div>
              <span className="text-lts dark:text-dts text-xs mt-1 whitespace-nowrap">
                {this.props.language === "EN" ? "Quiz" : "ക്വിസ്"}
              </span>
            </div>
          )}
        </div>
        <div className="flex flex-col text-left bg-lbp dark:bg-dbp text-ltp dark:text-dtp rounded border border-lbr dark:border-dbr max-h-full grow">
          {/* <div className="text-accent flex items-center justify-center m-2">
            <span className="mr-2">
              <FiZap />
            </span>
            {textContent.header}
          </div> */}

          {/* <div
            className="grow px-4 pb-2 flex flex-col shrink min-h-0"
            onClick={() => {
              window.gtag("event", `event_intro_click_main_${firstItem.uid}`);
              this.props.gotoNewsItem(firstItem.uid);
            }}
          >
            <div
              className="grow bg-cover bg-center rounded"
              style={{ backgroundImage: "url(" + firstItem.image_link + ")" }}
            ></div>
            <div className="text-lg line-clamp-3 font-bold pt-2 shrink-0">
              {firstItem.title}
            </div>
          </div> */}

          {/* List  */}
          {/* {trendingItems.slice(1, trendingItems.length).map((item, index) => (
            <div
              key={index}
              className="flex items-center px-4 py-2 border-t border-lbr dark:border-dbr"
              onClick={() => {
                window.gtag("event", `event_intro_click_${item.uid}`);
                this.props.gotoNewsItem(item.uid);
              }}
            >
              <div className="pr-2 line-clamp-3 grow">{item.title}</div>
              <img
                className="w-16 h-16 shrink-0 object-center object-cover rounded"
                src={item.image_link}
                alt=""
              />
            </div>
          ))} */}

          <div className="bg-accent grow rounded p-4 flex justify-center items-center text-center text-dtp">
            {this.props.language === "EN" ? (
              <>
                Dear Friends,
                <br />
                <br />
                We would like to inform you that the Katha app will be
                discontinued in the near future. Thank you to everyone who has
                supported us along the way.
                <br />
                <br />
                We hope to meet again in the future with more Katha services.
              </>
            ) : (
              <>
                പ്രിയ പ്രേക്ഷകരെ,
                <br />
                <br />
                കഥ ആപ്പിന്റെ പ്രവർത്തനം അടുത്ത കുറച്ചു നാളുകൾക്കുള്ളിൽ
                അവസാനിപ്പിക്കുകയാണ് എന്ന വിവരം നിങ്ങളെ അറിയിക്കുന്നു. ഈ യാത്രയിൽ
                ഞങ്ങളോടൊപ്പം കൂടെ നിന്നു സ്നേഹവും പ്രോത്സാഹനവും നൽകിയ ഏവർക്കും
                നന്ദി.
                <br />
                <br />
                കഥ-യുടെ കൂടുതൽ സേവനങ്ങളുമായി മറ്റ് അവസരങ്ങളിൽ നമുക്ക് വീണ്ടും
                കണ്ടുമുട്ടാം.
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default IntroCard;
