import React, { Component } from "react";
import logo from "../../../assets/logo.svg";

class TopNav extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // Push the app to background
  quitWindow() {
    window.gtag("event", "event_bg_play_btn_positive");
    window.quitApplication();
  }

  render() {
    // Text based on selected language
    let textContent =
      this.props.language === "EN"
        ? {
            categoryHeader: "Categories",
            settingsHeader: "Settings",
          }
        : {
            categoryHeader: "വിഭാഗങ്ങൾ",
            settingsHeader: "സെറ്റിംഗ്സ്",
          };

    return (
      <div
        className={
          "flex flex-col border-b border-lbr dark:border-dbr h-[8vh] absolute z-10 w-screen px-4 bg-lbp dark:bg-dbp transition-[top] " +
          (this.props.showTopBar ? "top-0" : "top-[-8vh]")
        }
      >
        {/* {this.props.primarySwiperActiveIndex === 0 && (
          <div
            className="flex items-center grow"
            onClick={() => {
              this.props.onPrimarySwiperChange(
                1,
                this.props.primarySwiperActiveIndex
              );
            }}
          >
            <span className="opacity-0">
              <svg
                viewBox="0 0 24 24"
                width="24"
                height="24"
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="19" y1="12" x2="5" y2="12"></line>
                <polyline points="12 19 5 12 12 5"></polyline>
              </svg>
            </span>
            <span className="text-ltp dark:text-dtp font-bold grow text-center">
              {textContent.categoryHeader}
            </span>
            <span className="text-accent">
              <svg
                viewBox="0 0 24 24"
                width="24"
                height="24"
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="5" y1="12" x2="19" y2="12"></line>
                <polyline points="12 5 19 12 12 19"></polyline>
              </svg>
            </span>
          </div>
        )}

        {this.props.primarySwiperActiveIndex !== 0 && (
          <div className="flex items-center grow">
            {this.props.selectedCategory == null &&
              this.props.primarySwiperActiveIndex === 1 && (
                <span
                  className="text-lts dark:text-dts"
                  onClick={() => {
                    this.props.onPrimarySwiperChange(0, 1);
                  }}
                >
                  <svg
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <line x1="3" y1="12" x2="21" y2="12"></line>
                    <line x1="3" y1="6" x2="21" y2="6"></line>
                    <line x1="3" y1="18" x2="21" y2="18"></line>
                  </svg>
                </span>
              )}

            {this.props.selectedCategory != null &&
              this.props.primarySwiperActiveIndex === 1 && (
                <span
                  className="rounded-full flex items-center text-accent border-2 border-accent px-2 py-1"
                  onClick={() => {
                    this.props.handleCategorySelection(null, "", "");
                  }}
                >
                  {!this.props.selectedCategory.includes("DISTRICT_") && (
                    <img
                      alt=""
                      src={require(`../../../assets/category_icons/${this.props.selectedCategory.toLowerCase()}-icon.svg`)}
                      height="24"
                      width="24"
                    />
                  )}
                  <span className="font-bold">
                    {this.props.language === "EN"
                      ? this.props.selectedCategoryNameEN
                      : this.props.selectedCategoryNameML}
                  </span>
                  <span className="ml-2">
                    <svg
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      stroke="currentColor"
                      strokeWidth="2"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <line x1="18" y1="6" x2="6" y2="18"></line>
                      <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                  </span>
                </span>
              )}

            <div className="grow"></div>

            {this.props.selectedCategory == null &&
              this.props.primarySwiperActiveIndex === 1 && (
                <>
                  <span
                    className="mr-6 text-lts dark:text-dts"
                    onClick={() => {
                      window.gtag("event", "feed_refresh_btn");
                      this.props.handleCategorySelection(null, "", "");
                    }}
                  >
                    <svg
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      stroke="currentColor"
                      strokeWidth="2"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <polyline points="23 4 23 10 17 10"></polyline>
                      <polyline points="1 20 1 14 7 14"></polyline>
                      <path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path>
                    </svg>
                  </span>
                  <span
                    className="mr-6 text-lts dark:text-dts"
                    onClick={() => {
                      window.gtag("event", "news_search_click");
                      this.props.toggleOverlay("SEARCH_NEWS");
                    }}
                  >
                    <svg
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      stroke="currentColor"
                      strokeWidth="2"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <circle cx="11" cy="11" r="8"></circle>
                      <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                    </svg>
                  </span>
                </>
              )}

            {this.props.primarySwiperActiveIndex === 3 && (
              <>
                <span
                  className="mr-6 text-lts dark:text-dts"
                  onClick={() => {
                    window.gtag("event", "job_search_click");
                    this.props.updateGlobalStateValue("isJobSearchOpen", true);
                  }}
                >
                  <svg
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>
                </span>
              </>
            )}

            <span
              className="text-lts dark:text-dts"
              onClick={() => {
                this.props.toggleSettingsPage(true);
              }}
            >
              <svg
                viewBox="0 0 24 24"
                width="24"
                height="24"
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <circle cx="12" cy="12" r="3"></circle>
                <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
              </svg>
            </span>
          </div>
        )} */}
        <div className="flex items-center h-full">
          <img src={logo} alt="" className="w-12" />
          <span className="grow"></span>
          <span
            className="text-lts dark:text-dts"
            onClick={() => {
              this.props.toggleSettingsPage(true);
            }}
          >
            <svg
              viewBox="0 0 24 24"
              width="24"
              height="24"
              stroke="currentColor"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <circle cx="12" cy="12" r="3"></circle>
              <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
            </svg>
          </span>
        </div>
      </div>
    );
  }
}

export default TopNav;
