import React, { Component } from "react";

// Images
import introImg from "../../../assets/quiz/intro.svg";

class QuizEntryCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    // Text based on selected language
    let textContent =
      this.props.language === "EN"
        ? {
            entryBtn: "Daily Quiz",
            header: "Daily Quiz!",
            mainText:
              "Want to test your knowledge on current affairs? Attend the quiz to find out.",
          }
        : {
            entryBtn: "ഡെയ്‌ലി ക്വിസ് കളിക്കാം",
            header: "ഡെയ്‌ലി ക്വിസ് : കളിയിൽ അല്പം കാര്യം",
            mainText:
              "ഇന്നത്തെ പ്രധാന വാർത്തകളെയും രസകരമായ സംഭവങ്ങളെയും അടിസ്ഥാനമാക്കിയ ക്വിസിൽ പങ്കെടുക്കാം വിജയിക്കാം!",
          };

    return (
      <div className="h-full flex flex-col rounded bg-gradient-to-t from-[#9a7a3b] to-[#ddb800]">
        <div className="grow flex flex-col items-center justify-center">
          <img
            className="w-full"
            src={
              "https://storage.katha.today/4Y5TUu6tsoqkzERmAy3tZV3V?id=920785"
            }
            alt=""
          />
          <div className="px-4 w-full">
            {/* <img className="w-3/4 mx-auto mb-4" src={introImg} alt="" />

            <div className="text-lg text-accent font-bold mb-2">
              {textContent.header}
            </div>

            <div className="text-lts dark:text-dts text-sm mb-2">
              {textContent.mainText}
            </div> */}

            <div
              className="bg-lbp p-4 font-bold text-[#9a7a3b] mt-4 flex items-center justify-center rounded-full"
              onClick={() => {
                if (this.props.item.uid === "quiz_entry_card") {
                  window.gtag("event", "event_quiz_click_feed_3");
                } else {
                  window.gtag("event", "event_quiz_click_feed_13");
                }
                this.props.toggleQuiz(true, true);
              }}
            >
              {textContent.entryBtn}
              <span className="ml-2">
                <svg
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  stroke="currentColor"
                  strokeWidth="2"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <polyline points="13 17 18 12 13 7"></polyline>
                  <polyline points="6 17 11 12 6 7"></polyline>
                </svg>
              </span>
            </div>
          </div>
        </div>
        <div
          className="flex justify-center text-dtp pb-4 animate-bounce"
          onClick={() => {
            this.props.secondarySwiper.slideNext();
          }}
        >
          <span>
            <svg
              viewBox="0 0 24 24"
              width="24"
              height="24"
              stroke="currentColor"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline points="17 11 12 6 7 11"></polyline>
              <polyline points="17 18 12 13 7 18"></polyline>
            </svg>
          </span>
        </div>
      </div>
    );
  }
}

export default QuizEntryCard;
