import React from "react";

// Assets
import unizoneIllustration from "../../../assets/unizone-illustration.svg";

const SeasonalCard = (props) => {
  return (
    <>
      {props.item.uid === "seasonal_card" && (
        <div className="h-full flex flex-col rounded bg-gradient-to-t from-[#203f7d] to-[#1f4281]">
          <div className="grow flex flex-col items-center justify-center">
            <img
              className="w-full"
              src={
                "https://storage.katha.today/ZmuY3PkYGsCWYNfQZzDfa1JK?id=963679"
              }
              alt=""
            />
            <div className="px-4 flex flex-col items-center">
              {/* <img
                data-src={unizoneIllustration}
                className="w-2/3 lazyload blur-up mb-8"
                alt=""
              />
              <div className="text-center text-dtp">
                {props.language === "EN"
                  ? "Earn money for sharing ads via WhatsApp status."
                  : "നിങ്ങളുടെ വാട്സ്ആപ്പ് സ്റ്റാറ്റസിലൂടെ പരസ്യങ്ങൾ ഷെയർ ചെയ്യൂ, പണം നേടൂ."}
              </div> */}
              <span
                className="bg-lbp px-16 py-4 relative z-[1000] mt-4 rounded-full text-[#203f7d] font-bold flex items-center"
                onClick={() => {
                  window.gtag("event", "unizone_from_feed");
                  window.openCustomTab(
                    "https://unizone.one/?client=kathapp&utm_source=katha_app"
                  );
                }}
              >
                <span>
                  {props.language === "EN" ? "Earn Money" : "പണം നേടാം"}
                </span>
                <span className="ml-2">
                  <svg
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="13 17 18 12 13 7"></polyline>
                    <polyline points="6 17 11 12 6 7"></polyline>
                  </svg>
                </span>
              </span>
            </div>
          </div>
          <div
            className="flex justify-center text-dtp pb-4 animate-bounce"
            onClick={() => {
              props.secondarySwiper.slideNext();
            }}
          >
            <span>
              <svg
                viewBox="0 0 24 24"
                width="24"
                height="24"
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <polyline points="17 11 12 6 7 11"></polyline>
                <polyline points="17 18 12 13 7 18"></polyline>
              </svg>
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default SeasonalCard;
