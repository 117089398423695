import React, { Component } from "react";
import "./index.css";
import localStorage from "../../services/localStorage";
import quizService from "../../services/quizService";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Virtual } from "swiper";
import Archive from "./Archive";
import {
  FiArchive,
  FiVolume2,
  FiVolumeX,
  FiMaximize2,
  FiList,
  FiExternalLink,
} from "react-fi";
import { Context, getRollbarFromContext } from "@rollbar/react";

// Images
import introImg from "../../assets/quiz/intro.svg";
import clockTickAudio from "../../assets/quiz/clock-ticking.mp3";
import correctAnswerAudio from "../../assets/quiz/correct-answer.mp3";
import introAudio from "../../assets/quiz/intro.mp3";
import Leaderboard from "./Leaderboard";

// Install Swiper modules
SwiperCore.use([Autoplay, Virtual]);

class Quiz extends Component {
  // Rollbar
  static contextType = Context;

  constructor(props) {
    super(props);
    this.state = {
      questions: [], //  List of all questions
      quizSwiper: null, //  Swiper instance
      quizState: "", //  Current quiz state : INIT/ONGOING/FINISHED
      currentQuestionIndex: 0, //  Index of current question
      countDownValue: 0, //  Value to indicate the time left
      countDownTimer: null, //  Timer for countdown
      isTimerPlaying: false, //  Whether the countdown is playing or not
      answers: [], //  Input answers
      score: {
        value: 0,
        questions: [],
      }, //  Final score
      storedData: {
        //  Data stored in storage after quiz completion
        lastAttendedTime: 0, //  Timestamp at which quiz was completed
        previousScore: 0, //  Score obtained
        scoreQuestions: [], //  List of questions with score
      },
      questionAudioMuted: false, //  Whether questioin is audible or not
      allowAnswerSelect: true, //  Whether to allow the selection of an answer or not
      showArchiveOverlay: false, // Whether to show questions archive or not
      showLeaderboardOverlay: false, // Whether to show questions archive or not
      leaderboard: null, // Current leaderboard
      slideNextTimeout: null, //  Timeout interval
      logBucket: [],
    };
  }

  // Init
  componentDidMount() {
    this.rollbar = getRollbarFromContext(this.context);

    // Check if android webview or not
    if (
      this.props.isAndroidWebView ||
      window.location.hostname === "localhost"
    ) {
      // Get previous data from local storage
      const quizData = JSON.parse(localStorage.getItem("quizData"));

      // Get IST timestamp
      const d = new Date(
        new Date().toLocaleString("en-US", { timeZone: "Asia/Calcutta" })
      );

      this.log("Entered quiz");

      if (quizData !== null) {
        // Calculate hours difference
        let hoursDiff = Math.abs(
          parseInt(
            (d - new Date(parseInt(quizData.lastAttendedTime))) /
              (1000 * 60 * 60),
            10
          )
        );
        if (
          (d.getHours() >= 18 && hoursDiff < d.getHours() - 12) ||
          (d.getHours() < 12 && hoursDiff < d.getHours() + 12)
        ) {
          // Load leaderboard
          if (this.props.userDetails) {
            this.getLeaderboard();
          }
          // Update state
          this.setState({ quizState: "BLOCKED" });

          this.log("Already attempted");
        } else if (d.getHours() >= 18 || d.getHours() < 12) {
          this.setState({ quizState: "INIT" });
        }
        // Update state
        this.setState({ storedData: quizData });
      } else if (d.getHours() >= 18 || d.getHours() < 12) {
        this.setState({ quizState: "INIT" });
      }

      // Get the list of questions
      this.loadQuestions();

      // Add event listener for keydown
      window.addEventListener("keydown", (event) =>
        this.handleKeyDownEvent(event)
      );

      // Load audios
      document.querySelector("#clockTickAudio").load();
      document.querySelector("#correctAnswerAudio").load();
      document.querySelector("#introAudio").load();

      // Start BGM
      document.querySelector("#introAudio").play();

      window.gtag(
        "event",
        "quiz_timezone_" + Intl.DateTimeFormat().resolvedOptions().timeZone
      );

      // Prevent outside hours entry
      if (
        d.getHours() >= 12 &&
        d.getHours() < 18 &&
        window.location.hostname !== "localhost"
      ) {
        this.log("Redirected back");

        if (this.props.userDetails) {
          window.gtag(
            "event",
            "quiz_alert_" + d.getHours() + "_" + this.props.userDetails.name
          );
        }
        window.gtag(
          "event",
          "quiz_alert_" +
            Intl.DateTimeFormat().resolvedOptions().timeZone +
            "_" +
            d.getHours()
        );
        this.props.toggleQuiz(false);
      }
    } else {
      window.gtag("event", "quiz_alert_nonwebview");
      this.log("Non webview entry");
    }
  }

  //
  // Filtered logging
  //
  async log(message) {
    // Append to bucket
    if (
      this.props.userDetails &&
      this.state.logBucket.length > 0 &&
      (this.state.logBucket.length >= 10 || message === "_FLUSH_")
    ) {
      this.rollbar.info(
        new Date().toLocaleString() +
          " - " +
          this.props.userDetails.name +
          " - " +
          JSON.stringify(this.state.logBucket)
      );
      this.setState({ logBucket: [] });
    } else {
      this.setState({ logBucket: this.state.logBucket.concat(message) });
    }
  }

  // Component unmount
  componentWillUnmount() {
    // Clear current interval
    clearInterval(this.state.countDownTimer);
  }

  //
  // On component props update
  //
  async componentDidUpdate(prevProps) {
    // On update user details
    if (
      JSON.stringify(this.props.userDetails) !==
      JSON.stringify(prevProps.userDetails)
    ) {
      this.log("User details changed");

      if (this.state.quizState === "FINISHED") {
        // If quiz is in finished state, save current score
        this.saveScore();
      } else {
        // Simply loade leaderboard
        this.getLeaderboard();
      }
    }
  }

  // Handles all key downs
  handleKeyDownEvent = (event) => {
    if (event.key === "q" || event.key === "Q") {
      // Back button click
      if (this.state.showArchiveOverlay) {
        this.toggleArchiveOverlay(false);
      }
      if (this.state.showLeaderboardOverlay) {
        this.toggleLeaderboardOverlay(false);
      } else if (this.state.quizState !== "ONGOING") {
        this.props.toggleQuiz(false);
      }
    } else if (event.key === "a" || event.key === "A") {
      // Home button click
      this.props.toggleQuiz(false);
    }
  };

  // Load the list of questions
  async loadQuestions() {
    // Get list from API
    let questions = await quizService.getQuestions();

    const imageLinks = [
      "https://storage.katha.today/TLivdHmMtRWcQaTXeBjsftGM?id=346842",
      "https://storage.katha.today/bA4sU7x6idjnEvQXEi5X5E1S?id=346843",
      "https://storage.katha.today/9LDpTxj2sCzaYt48UYaEmEuS?id=346844",
      "https://storage.katha.today/H3oMWDjt7qHDQLBDnVN9tzVD?id=346845",
      "https://storage.katha.today/zDX7cgFKS9ayeFmoog5GSQXt?id=346846",
      "https://storage.katha.today/5rGZ3qhfAwfjdVrcvhTMf5S6?id=346847",
    ];

    // Add image link
    questions.data.forEach((question) => {
      question.image_link =
        imageLinks[Math.floor(Math.random() * imageLinks.length)];
    });

    // Update state
    this.setState({ questions: questions.data });
  }

  // Start the quiz
  startQuiz() {
    this.log("Quiz started");

    window.gtag("event", "event_quiz_attempt");
    // Pause BGM
    document.querySelector("#introAudio").pause();
    // Update state
    this.setState({ quizState: "ONGOING" });
    // Go to first question
    this.nextQuestion();
    // Update local storage
    let quizData = {
      lastAttendedTime: new Date(
        new Date().toLocaleString("en-US", { timeZone: "Asia/Calcutta" })
      ).getTime(),
      previousScore: 0,
      scoreQuestions: [],
    };
    localStorage.setItem("quizData", JSON.stringify(quizData));
  }

  // On going to next question
  nextQuestion() {
    // Update current question index
    let currentQuestionIndex = this.state.currentQuestionIndex;
    // Reset value to 10
    this.setState(
      {
        countDownValue: 10,
        currentQuestionIndex: ++currentQuestionIndex,
        allowAnswerSelect: true,
      },
      async () => {
        // If not end slide
        if (currentQuestionIndex <= this.state.questions.length) {
          // Load the audio for current question and play it
          let questionArrIndex = --currentQuestionIndex;
          const audio = document.querySelector("#quizQuestionAudio");
          const audioSource = document.querySelector("#quizQuestionAudioSrc");
          if (this.state.questions[questionArrIndex].audio_link !== "") {
            audioSource.src = this.state.questions[questionArrIndex].audio_link;
            await audio.load();
            await audio.play();
          }
        } else {
          // Prepare score
          this.calcScore();
        }
      }
    );
  }

  // Onclicking a particular answer
  selectAnswer(answerIndex, event, correctAnswerIndex) {
    if (
      this.state.allowAnswerSelect &&
      this.state.quizSwiper.activeIndex !== undefined
    ) {
      // Pause audios
      document.querySelector("#clockTickAudio").pause();
      document.querySelector("#quizQuestionAudio").pause();

      // Clear current interval
      clearInterval(this.state.countDownTimer);
      // Update state
      this.setState({ isTimerPlaying: false, allowAnswerSelect: false });

      // Pause audio animation
      let audioBarsContainer = document.querySelector(
        "#quiz_audio_bars_" + this.state.quizSwiper.activeIndex
      );
      if (audioBarsContainer !== null) {
        let audioBarEls = audioBarsContainer.querySelectorAll(".bar");
        for (let el of audioBarEls) {
          el.style.animation = "none";
        }
      }

      // Trim white spaces from correct answer index (Human error while data entry)
      correctAnswerIndex = correctAnswerIndex.replace(/\s+/g, "");

      // Feedback
      if ("vibrate" in navigator) {
        navigator.vibrate(50);
      }
      if (answerIndex === correctAnswerIndex) {
        event.target.style.backgroundColor = "#4f772d";
      } else {
        event.target.style.backgroundColor = "#d33f49";
      }

      // Save answer in state
      let answers = this.state.answers;
      answers.push({
        questionIndex: this.state.quizSwiper.activeIndex,
        answerIndex: answerIndex,
        score: this.state.countDownValue,
      });
      this.setState({ answers: answers });

      this.log(
        "Answer selected " +
          this.state.quizSwiper.activeIndex +
          "-" +
          answerIndex +
          "-" +
          correctAnswerIndex +
          "-" +
          this.state.countDownValue
      );

      // Move to next question after a delay
      clearTimeout(this.state.slideNextTimeout);
      let timeout = setTimeout(() => {
        this.state.quizSwiper.slideNext();
      }, 1000);
      this.setState({ slideNextTimeout: timeout });
    }
  }

  // On moving to next question
  restartTimer() {
    // Reset value to 10
    let countDownValue = 10;

    document.querySelector("#clockTickAudio").play();

    // Start new timer
    clearInterval(this.state.countDownTimer);
    let timer = setInterval(() => {
      if (countDownValue > 0) {
        this.setState({ countDownValue: --countDownValue });
      } else {
        // Vibration feedback
        if ("vibrate" in navigator) {
          navigator.vibrate(50);
        }

        // Pause ticking sound
        document.querySelector("#clockTickAudio").pause();

        // Clear timer
        clearInterval(this.state.countDownTimer);
        this.setState({ isTimerPlaying: false, allowAnswerSelect: false });

        // Move to next question
        this.state.quizSwiper.slideNext();
      }
    }, 1000);
    this.setState({ countDownTimer: timer, isTimerPlaying: true });
  }

  // Calculate score
  async calcScore() {
    // Start BGM
    document.querySelector("#introAudio").play();

    let scoreValue = 0;
    let scoreQuestions = [];

    await this.log("Finish - " + JSON.stringify(this.state.answers));

    // Iterate over questions and check if correct or not
    this.state.questions.forEach((question, index) => {
      // Find the correct answer value from index
      let correctAnswerValue = "";
      if (question.correct_answer_index.replace(/\s+/g, "") === "1") {
        correctAnswerValue = question.answer_option_first;
      } else if (question.correct_answer_index.replace(/\s+/g, "") === "2") {
        correctAnswerValue = question.answer_option_second;
      } else if (question.correct_answer_index.replace(/\s+/g, "") === "3") {
        correctAnswerValue = question.answer_option_third;
      } else {
        correctAnswerValue = question.answer_option_fourth;
      }

      // Check if answer has been marked for the question
      let markedAnswer = this.state.answers.find(
        (a) => a.questionIndex === index
      );
      if (markedAnswer) {
        // Find the selected answer value from index
        let selectedAnswerValue = "";
        if (markedAnswer.answerIndex === "1") {
          selectedAnswerValue = question.answer_option_first;
        } else if (markedAnswer.answerIndex === "2") {
          selectedAnswerValue = question.answer_option_second;
        } else if (markedAnswer.answerIndex === "3") {
          selectedAnswerValue = question.answer_option_third;
        } else {
          selectedAnswerValue = question.answer_option_fourth;
        }

        // Check if correct answer
        if (
          markedAnswer.answerIndex ===
          question.correct_answer_index.replace(/\s+/g, "")
        ) {
          scoreValue += markedAnswer.score;
          scoreQuestions.push({
            title: question.title,
            isCorrect: true,
            correctAnswerValue: correctAnswerValue,
            selectedAnswerValue: selectedAnswerValue,
            newsItemUid: question.news_item_uid,
          });
        } else {
          // Worng answer
          scoreQuestions.push({
            title: question.title,
            isCorrect: false,
            correctAnswerValue: correctAnswerValue,
            selectedAnswerValue: selectedAnswerValue,
            newsItemUid: question.news_item_uid,
          });
        }
      } else {
        // No answer marked. So wrong by default
        scoreQuestions.push({
          title: question.title,
          isCorrect: false,
          correctAnswerValue: correctAnswerValue,
          selectedAnswerValue: "_",
          newsItemUid: question.news_item_uid,
        });
      }
    });

    await this.log(
      "Finish - " + scoreValue + " - " + JSON.stringify(this.state.answers)
    );
    setTimeout(async () => {
      await this.log("_FLUSH_");
    }, 1000);

    if (scoreValue === 0) {
      let message2 =
        "0 score debug1 - " +
        JSON.stringify(scoreQuestions) +
        " - " +
        JSON.stringify(this.state.answers);
      if (this.props.userDetails) {
        message2 = this.props.userDetails.name + " - " + message2;
      }
      this.rollbar.info(message2);
    }

    // Update state
    this.setState(
      {
        score: {
          value: scoreValue,
          questions: scoreQuestions,
        },
        quizState: "FINISHED",
      },
      () => {
        // Save score in server
        if (this.props.userDetails && this.state.answers.length > 0) {
          this.saveScore();
        }
      }
    );

    window.gtag("event", "event_quiz_completed");

    // Update local storage
    let quizData = {
      lastAttendedTime: new Date(
        new Date().toLocaleString("en-US", { timeZone: "Asia/Calcutta" })
      ).getTime(),
      previousScore: scoreValue,
      scoreQuestions: scoreQuestions,
    };
    localStorage.setItem("quizData", JSON.stringify(quizData));
  }

  //
  // Save user score in server
  //
  saveScore = async () => {
    // Get IST timestamp
    const d = new Date(
      new Date().toLocaleString("en-US", { timeZone: "Asia/Calcutta" })
    );

    // Block score save during outside hours
    if (d.getHours() >= 18 || d.getHours() < 12) {
      window.gtag("event", "quiz_score_saved_" + d.getHours());
      // For morning sessions, use previous date
      if (d.getHours() < 18) {
        d.setDate(d.getDate() - 1);
      }
      // Prepare payload
      const session = d.getDate() + "_" + (d.getMonth() + 1);
      const data =
        Math.floor(Math.random() * 899 + 100).toString() +
        (this.state.score.value * (d.getMonth() + 1) + d.getDate()).toString() +
        Math.floor(Math.random() * 899 + 100).toString();
      const payload = {
        phone: this.props.userDetails.contact,
        name: this.props.userDetails.name,
        session: session,
        data: data,
        date: d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate(),
        score: "" + this.state.score.value,
      };

      await this.log("Saving score " + this.state.score.value + "-" + data);
      setTimeout(async () => {
        await this.log("_FLUSH_");
      }, 2000);
      if (this.state.score.value === 0) {
        this.rollbar.info(
          "0 score debug2 - " +
            JSON.stringify(this.state.score.questions) +
            " - " +
            JSON.stringify(this.state.answers)
        );
      }

      // Make API call
      await quizService.saveScore(payload);
    }

    // Reload leaderboard
    this.getLeaderboard();
  };

  //
  // Load leaderboard
  //
  getLeaderboard = async () => {
    // Make API call
    const response = await quizService.getLeaderboard(
      "phone=" + this.props.userDetails.contact
    );
    if (response && response.data) {
      this.setState({ leaderboard: response.data });
    }
  };

  // Share message
  challengeFriends() {
    window.gtag("event", "event_quiz_invite_click");
    // Create message
    let shareMsg =
      "ഹായ്, ഞാൻ *കഥ* ആപ്പിൽ ഒരു രസകരമായ ക്വിസ് കളിക്കുകയായിരുന്നു. നിങ്ങൾക്കും ഒരു കൈ നോക്കണമെങ്കിൽ ലിങ്ക് ക്ലിക്ക് ചെയ്യൂ : \n\nhttps://link.katha.today/quiz";
    // Launch whatsapp
    let shareLink =
      "https://api.whatsapp.com/send?text=" + encodeURIComponent(shareMsg);
    window.open(shareLink, "_blank");
  }

  // Show/hide rules
  toggleRules() {
    // Get element
    let rulesEl = document.querySelector(".quiz-rules-list");
    // Toggle height
    if (rulesEl.style.maxHeight !== "0px" && rulesEl.style.maxHeight !== "") {
      rulesEl.style.maxHeight = "0px";
    } else {
      rulesEl.style.maxHeight = rulesEl.scrollHeight + "px";
    }
  }

  // Mute/unmute question audio
  toggleQuestionAudio() {
    const questionAudioMuted = this.state.questionAudioMuted;
    this.setState({ questionAudioMuted: !questionAudioMuted });
  }

  //
  // On clicking know more for a quiz question
  //
  onKnowMoreClick = (question) => {
    // Go to the given news item in feed
    this.props.gotoNewsItem(question.newsItemUid);
    // Close quiz
    this.props.toggleQuiz(false);
  };

  //
  // Show or hide questions archive
  //
  toggleArchiveOverlay = (option) => {
    window.gtag("event", "toggle_archive_" + option);
    this.setState({ showArchiveOverlay: option });
  };

  //
  // Show or hide leaderboard overlay
  //
  toggleLeaderboardOverlay = (option) => {
    window.gtag("event", "toggle_leaderboard_" + option);
    this.setState({ showLeaderboardOverlay: option });
  };

  render() {
    // Text based on selected language
    let textContent =
      this.props.language === "EN"
        ? {
            helloText: "👋 Hello ",
            tabHeader: "Daily Quiz",
            introTitle: "Welcome to Daily Quiz!",
            rulesTitle: "RULES",
            rulesList: [
              "The quiz contains 10 questions related to the events that occurred today.",
              "Each question will be read out once after, which the four options (in which one will be correct) will be shown.",
              "You will get 10 seconds to answer each question. If you fail to answer in the given interval, the answer will be marked as incorrect.",
              "You'll find out how well you did at the end of ten questions. Your score will be determined by the amount of time it takes you to correctly answer a question.",
              "The correct answers to all questions will be available in the archive the following day.",
              "You will only be able to take the quiz once per day. Each day's session begins at 6PM (IST) and ends at 12PM (IST) the following day. Please keep in mind that once you begin the quiz, you will not be able to stop it.",
              "Reach out to +917907741997 if you have any issues or doubts.",
            ],
            startBtn: "Start Quiz",
            scoreMessage: "Your score :",
            endMessage: "Come back tommorow for more.",
            exitBtn: "Back To Feed",
            blockedMessage:
              "You've already attended today's quiz. Come back tomorrow for more. Your score :",
            challengeBtn: "Invite Friends",
            allAnswersBtn: "All Answers",
            yourAnswer: "Your answer : ",
            correctAnswer: "Correct answer : ",
          }
        : {
            helloText: "👋 ഹലോ ",
            tabHeader: "ഡെയ്‌ലി ക്വിസ്",
            introTitle: "ഡെയ്‌ലി ക്വിസിലേക്ക് സ്വാഗതം!",
            rulesTitle: "നിയമങ്ങൾ",
            rulesList: [
              "ഇന്ന് നിങ്ങൾ കഥ ആപ്പിൽ വായിച്ച വാർത്തകളുമായി ബന്ധപ്പെട്ട 10 ചോദ്യങ്ങൾ ആണ് ക്വിസിൽ ഉൾപ്പെടുത്തിയിരിക്കുന്നത്.",
              "ഓരോ ചോദ്യത്തിനും നാല് ഓപ്ഷനുകൾ ഉണ്ടാകും, അതിൽ ശരിയായ ഉത്തരം തിരഞ്ഞെടുക്കുക.",
              "ഓരോ ചോദ്യത്തിനും ഉത്തരം നൽകാൻ നിങ്ങൾക്ക് 10 സെക്കൻഡ് ലഭിക്കും. തന്നിരിക്കുന്ന ഇടവേളയിൽ നിങ്ങൾക്ക് ഉത്തരം നൽകാൻ കഴിഞ്ഞില്ലെങ്കിൽ, അത് തെറ്റുത്തരമായി കണക്കാക്കും.",
              "പത്ത് ചോദ്യങ്ങളുടെ അവസാനം നിങ്ങൾ എത്ര സ്കോർ നേടി എന്നറിയാം. ഒരു ചോദ്യത്തിന് ശരിയായി ഉത്തരം നൽകാൻ നിങ്ങൾ എടുക്കുന്ന സമയത്തിന് അനുസരിച്ചായിരിക്കും നിങ്ങളുടെ സ്കോർ നിർണ്ണയിക്കുക.",
              "എല്ലാ ചോദ്യങ്ങൾക്കുമുള്ള ശരിയായ ഉത്തരങ്ങൾ അടുത്ത ദിവസം ലഭ്യമാകും.",
              "നിങ്ങൾക്ക് ദിവസത്തിൽ ഒരിക്കൽ മാത്രമേ ക്വിസിൽ പങ്കെടുക്കാൻ കഴിയൂ. ഓരോ ദിവസത്തെയും സെഷൻ 6PM (IST)-ന് ആരംഭിച്ച് അടുത്ത ദിവസം 12PM (IST)-ന് അവസാനിക്കും. നിങ്ങൾ ക്വിസ് ആരംഭിച്ചുകഴിഞ്ഞാൽ, നിങ്ങൾക്ക് അത് നിർത്താൻ കഴിയില്ലെന്ന് ദയവായി ഓർക്കുക.",
              "നിങ്ങൾക്ക് എന്തെങ്കിലും പ്രശ്‌നങ്ങളോ സംശയങ്ങളോ ഉണ്ടെങ്കിൽ +917907741997 എന്ന നമ്പറിൽ ബന്ധപ്പെടുക.",
            ],
            startBtn: "ക്വിസ് ആരംഭിക്കുക",
            scoreMessage: "നിങ്ങളുടെ സ്കോർ :",
            endMessage: "വീണ്ടും കൂടുതൽ ചോദ്യങ്ങൾക്കായി നാളെ വരൂ.",
            exitBtn: "വാർത്തകളിലേക്ക് മടങ്ങുക",
            blockedMessage:
              "ഇന്നത്തെ ക്വിസിൽ നിങ്ങൾ ഇതിനകം പങ്കെടുത്തിട്ടുണ്ട്. വീണ്ടും കൂടുതൽ ചോദ്യങ്ങൾക്കായി നാളെ വരൂ. നിങ്ങളുടെ സ്കോർ :",
            challengeBtn: "സുഹൃത്തുക്കളെ ചലഞ്ച് ചെയ്യൂ",
            allAnswersBtn: "എല്ലാ ഉത്തരങ്ങളും",
            yourAnswer: "നിങ്ങളുടെ ഉത്തരം : ",
            correctAnswer: "ശരിയായ ഉത്തരം : ",
            scoreMessage1:
              "നിങ്ങളുടെ നിലവിലെ  കറന്റ് അഫയേഴ്‌സ് വിജ്ഞാനം അപ്‌ഡേറ്റഡ് അല്ലെന്നു കാണുന്നു. പ്രധാനപ്പെട്ട വാർത്തകൾ അറിഞ്ഞിരിക്കാൻ  മറ്റു വാർത്ത സ്രോതസുകൾ തിരഞ്ഞെടുക്കാവുന്നതാണ് . അറുപതോളം ഉറവിടങ്ങളിൽനിന്നുള്ള വാർത്തകൾ ചുരുക്കത്തിൽ അറിയാൻ കഥ ന്യൂസ്‌ ആപ്പ് നല്ലൊരു ഓപ്ഷൻ ആണ്.",
            scoreMessage2:
              "ഇതു മോശമല്ലാത്ത ഒരു സ്കോർ ആണ്. കുറേകൂടി മികച്ച വാർത്ത സ്രോതസ്സുകൾ ഉപയോഗിച്ച് നിങ്ങൾക്ക് സ്കോർ മെച്ചപ്പെടുത്താം.അറുപതോളം സ്രോതസുകളിൽ നിന്നുള്ള  വാർത്തകളും പ്രധാന അറിയിപ്പുകളും മറ്റു തൊഴിലവസരങ്ങളും കേട്ടറിയാൻ കഥ ന്യൂസ്‌ ആപ്പ് ഡൗൺലോഡ് ചെയ്യൂ.",
            scoreMessage3:
              "നിങ്ങളുടെ  സ്കോർ മികച്ചതാണ്. നിങ്ങളുടെ അറിവ് അപ്ഡേറ്റഡാണ്. കൂടുതൽ ക്വിസ് കളിക്കുന്നതിനും വാർത്തകൾ ചുരുക്കത്തിലും എളുപ്പത്തിലും  കേട്ടറിയാനും കഥ ന്യൂസ് ആപ്പ് ഡൗൺലോഡ് ചെയ്യൂ.",
          };

    return (
      <>
        <div className="absolute top-0 h-screen w-screen text-left overflow-hidden z-10 bg-lbs dark:bg-dbs text-ltp dark:text-dtp">
          {/* Audio element for quiz question */}
          <audio
            id="quizQuestionAudio"
            onEnded={() => {
              // On audio end, restart timer
              this.restartTimer();
            }}
            muted={this.state.questionAudioMuted}
          >
            <source id="quizQuestionAudioSrc" src="" />
          </audio>

          {/* Audio element for clock tick */}
          <audio id="clockTickAudio" loop>
            <source src={clockTickAudio} />
          </audio>

          {/* Audio element for correct answer alert */}
          <audio id="correctAnswerAudio">
            <source src={correctAnswerAudio} />
          </audio>

          {/* Audio element for BGM */}
          <audio id="introAudio" loop>
            <source src={introAudio} />
          </audio>

          {(this.state.quizState === "INIT" ||
            this.state.quizState === "BLOCKED") && (
            <>
              <div
                className="h-[8vh] flex items-center border-b border-lbr dark:border-dbr sticky top-0 px-4 bg-lbp dark:bg-dbp z-20"
                onClick={() => {
                  this.props.toggleQuiz(false);
                }}
              >
                <span className="text-accent">
                  <svg
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <line x1="19" y1="12" x2="5" y2="12"></line>
                    <polyline points="12 19 5 12 12 5"></polyline>
                  </svg>
                </span>
                <span className="font-bold grow text-center">
                  {textContent.tabHeader}
                </span>
                <span className="opacity-0">
                  <svg
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <line x1="5" y1="12" x2="19" y2="12"></line>
                    <polyline points="12 5 19 12 12 19"></polyline>
                  </svg>
                </span>
              </div>

              <div className={"overflow-auto h-[92vh]"}>
                {/* Intro image */}
                <div className="flex justify-center">
                  <img className="w-2/3 mt-4 mb-8" src={introImg} alt="" />
                </div>

                <div className="font-bold text-lg text-center">
                  {textContent.introTitle}
                </div>

                {/* Start button */}
                {this.state.quizState === "INIT" && (
                  <>
                    <div
                      className="bg-accent font-bold p-4 rounded-full m-4 text-center text-dtp"
                      onClick={() => {
                        this.startQuiz();
                      }}
                    >
                      {textContent.startBtn}
                    </div>
                    <div
                      className="border border-lbr dark:border-dbr p-4 rounded m-4"
                      onClick={() => {
                        this.toggleRules();
                      }}
                    >
                      <div className="text-accent flex items-center">
                        <span className="grow">{textContent.rulesTitle}</span>
                        <span>
                          <FiMaximize2 />
                        </span>
                      </div>
                      <ul className="text-lts dark:text-dts text-sm list-disc pl-4 quiz-rules-list max-h-0 transition-all overflow-y-hidden">
                        <div className="h-2"></div>
                        {textContent.rulesList.map((rule, index) => {
                          return <li key={index}>{rule}</li>;
                        })}
                      </ul>
                    </div>
                  </>
                )}

                {/* Blocked message */}
                {this.state.quizState === "BLOCKED" && (
                  <div className="flex flex-col items-center px-4">
                    <div className="text-lts dark:text-dts text-sm mt-4 text-center">
                      {textContent.blockedMessage}
                    </div>

                    <div className="bg-lbp dark:bg-dbp text-accent font-bold text-6xl mt-2 rounded py-4 px-8">
                      {this.state.storedData.previousScore}
                    </div>

                    <div className="bg-lbp dark:bg-dbp rounded mt-4 w-full border border-lbr dark:border-dbr">
                      <div className="font-bold pt-4 text-center">
                        {this.props.language === "EN"
                          ? "Leaderboard"
                          : "ലീഡർബോർഡ്"}
                      </div>
                      {!this.props.userDetails && (
                        <div
                          className="p-4"
                          onClick={() => {
                            this.props.toggleInfoModal(
                              true,
                              "USER_DETAILS_INPUT"
                            );
                          }}
                        >
                          <div className="text-center text-lts dark:text-dts">
                            {this.props.language === "EN"
                              ? "Enter your details to start competing with other players and win prizes."
                              : "മറ്റ് കളിക്കാരുമായി മത്സരിച്ച് സമ്മാനങ്ങൾ നേടുന്നതിന് നിങ്ങളുടെ വിശദാംശങ്ങൾ നൽകുക."}
                          </div>
                          <div className="mt-4 text-center bg-accent text-dtp font-bold w-full rounded-full p-4">
                            {this.props.language === "EN"
                              ? "Register"
                              : "രജിസ്റ്റർ ചെയ്യുക"}
                          </div>
                        </div>
                      )}
                      {this.props.userDetails && this.state.leaderboard && (
                        <div className="p-4 space-y-4">
                          {this.state.leaderboard.top_10_week.length > 0 && (
                            <table className="table-auto w-full bg-lbs dark:bg-dbs rounded-sm text-sm text-center">
                              <tbody className="divide-y divide-lbp dark:divide-dbp">
                                <tr>
                                  <td
                                    className="p-2 text-lts dark:text-dts"
                                    colSpan={3}
                                  >
                                    {this.props.language === "EN"
                                      ? "Weekly :"
                                      : "ഈ ആഴ്ച :"}
                                  </td>
                                </tr>
                                {this.state.leaderboard.top_10_week
                                  .slice(0, 5)
                                  .map((user, index) => (
                                    <tr key={"quiz-leaderboard-entry-" + index}>
                                      <td className="p-2 text-lts dark:text-dts opacity-50">
                                        #{index + 1}
                                      </td>
                                      <td className="p-2 text-ltp dark:text-dtp max-w-[40vw] whitespace-nowrap overflow-hidden text-ellipsis">
                                        {user.name}
                                      </td>
                                      <td className="p-2 text-accent">
                                        {user.score}
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          )}
                          {this.state.leaderboard.top_10_month.length > 0 && (
                            <table className="table-auto w-full bg-lbs dark:bg-dbs rounded-sm text-sm text-center">
                              <tbody className="divide-y divide-lbp dark:divide-dbp">
                                <tr>
                                  <td
                                    className="p-2 text-lts dark:text-dts"
                                    colSpan={3}
                                  >
                                    {this.props.language === "EN"
                                      ? "Monthly :"
                                      : "ഈ മാസം :"}
                                  </td>
                                </tr>
                                {this.state.leaderboard.top_10_month
                                  .slice(0, 5)
                                  .map((user, index) => (
                                    <tr key={"quiz-leaderboard-entry-" + index}>
                                      <td className="p-2 text-lts dark:text-dts opacity-50">
                                        #{index + 1}
                                      </td>
                                      <td className="p-2 text-ltp dark:text-dtp max-w-[40vw] whitespace-nowrap overflow-hidden text-ellipsis">
                                        {user.name}
                                      </td>
                                      <td className="p-2 text-accent">
                                        {user.score}
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          )}
                          <div
                            className="flex text-accent justify-center items-center"
                            onClick={() => this.toggleLeaderboardOverlay(true)}
                          >
                            <FiList />
                            <span className="ml-2">
                              {this.props.language === "EN"
                                ? "Know More"
                                : "കൂടുതൽ അറിയാം"}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>

                    <div
                      className="m-4 text-center bg-accent text-dtp font-bold w-full rounded-full p-4"
                      onClick={() => {
                        this.challengeFriends();
                      }}
                    >
                      {textContent.challengeBtn}
                    </div>

                    <div
                      className="px-4 mb-8 font-bold text-accent flex items-center justify-center"
                      onClick={() => this.toggleArchiveOverlay(true)}
                    >
                      <FiArchive />
                      <span className="ml-2">
                        {this.props.language === "EN"
                          ? "Questions Archive"
                          : "പഴയ ചോദ്യങ്ങൾ"}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}

          {/* Quiz slides */}
          {(this.state.quizState === "ONGOING" ||
            this.state.quizState === "FINISHED") && (
            <div className="absolute top-0 h-screen w-screen z-30">
              <Swiper
                onSlideChange={() => this.nextQuestion()}
                onSwiper={(swiper) => this.setState({ quizSwiper: swiper })}
                className="bg-lbs dark:bg-dbs h-screen"
                virtual={{
                  addSlidesAfter: 3,
                  addSlidesBefore: 0,
                  cache: true,
                }}
                speed={250}
                resistance={false}
                resistanceRatio={0.1}
                allowTouchMove={false}
              >
                {this.state.questions.map((question, index) => (
                  <SwiperSlide key={index} virtualIndex={index}>
                    <div className="h-screen overflow-auto">
                      <div className="flex items-center p-4 justify-end">
                        <div
                          className="rounded-full py-2 px-4 flex items-center bg-accent text-dtp"
                          onClick={() => {
                            this.toggleQuestionAudio();
                          }}
                        >
                          {!this.state.questionAudioMuted && (
                            <FiVolume2 size={"1.25rem"} />
                          )}
                          {this.state.questionAudioMuted && (
                            <FiVolumeX size={"1.25rem"} />
                          )}
                        </div>
                      </div>

                      <div className="bg-lbp dark:bg-dbp rounded flex items-center justify-center mt-0 mx-4 p-4 flex-col">
                        <span className="h-12 w-12 bg-lbp dark:bg-dbp font-bold rounded-full -mt-10 border border-lbr dark:border-dbr flex items-center justify-center">
                          {/* Value if timer is playing  */}
                          {(this.state.isTimerPlaying ||
                            this.state.countDownValue < 10) && (
                            <div className="text-accent">
                              {this.state.countDownValue}
                            </div>
                          )}
                          {/* Audio indicator if audio playing  */}
                          {!this.state.isTimerPlaying &&
                            this.state.countDownValue === 10 && (
                              <div
                                className="h-12 w-12 flex items-center justify-center"
                                id={"quiz_audio_bars_" + index}
                              >
                                <div className="quiz-audio-bars">
                                  <span className="bar bg-accent"></span>
                                  <span className="bar bg-accent"></span>
                                  <span className="bar bg-accent"></span>
                                  <span className="bar bg-accent"></span>
                                </div>
                              </div>
                            )}
                        </span>

                        <img
                          className="w-1/2 mt-4"
                          alt=""
                          src={question.image_link}
                        />
                      </div>

                      <div className="text-center font-bold break-words m-4">
                        {this.state.currentQuestionIndex}. {question.title}
                      </div>

                      <div className="m-4 space-y-4">
                        <div
                          className="break-words rounded-full bg-accent text-dtp font-bold text-center p-4"
                          id={"quiz_item_option_" + index + "_1"}
                          onClick={(event) => {
                            this.selectAnswer(
                              "1",
                              event,
                              question.correct_answer_index
                            );
                          }}
                        >
                          {question.answer_option_first}
                        </div>
                        <div
                          className="break-words rounded-full bg-accent text-dtp font-bold text-center p-4"
                          id={"quiz_item_option_" + index + "_2"}
                          onClick={(event) => {
                            this.selectAnswer(
                              "2",
                              event,
                              question.correct_answer_index
                            );
                          }}
                        >
                          {question.answer_option_second}
                        </div>
                        <div
                          className="break-words rounded-full bg-accent text-dtp font-bold text-center p-4"
                          id={"quiz_item_option_" + index + "_3"}
                          onClick={(event) => {
                            this.selectAnswer(
                              "3",
                              event,
                              question.correct_answer_index
                            );
                          }}
                        >
                          {question.answer_option_third}
                        </div>
                        <div
                          className="break-words rounded-full bg-accent text-dtp font-bold text-center p-4"
                          id={"quiz_item_option_" + index + "_4"}
                          onClick={(event) => {
                            this.selectAnswer(
                              "4",
                              event,
                              question.correct_answer_index
                            );
                          }}
                        >
                          {question.answer_option_fourth}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
                <SwiperSlide>
                  <div
                    className="h-[8vh] flex items-center border-b border-lbr dark:border-dbr sticky top-0 px-4 bg-lbp dark:bg-dbp z-20"
                    onClick={() => {
                      this.props.toggleQuiz(false);
                    }}
                  >
                    <span className="text-accent">
                      <svg
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <line x1="19" y1="12" x2="5" y2="12"></line>
                        <polyline points="12 19 5 12 12 5"></polyline>
                      </svg>
                    </span>
                    <span className="font-bold grow text-center">
                      {textContent.tabHeader}
                    </span>
                    <span className="opacity-0">
                      <svg
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                        <polyline points="12 5 19 12 12 19"></polyline>
                      </svg>
                    </span>
                  </div>

                  <div className="flex flex-col items-center p-4 overflow-auto h-[92vh]">
                    <div>{textContent.scoreMessage}</div>
                    <div className="bg-lbp dark:bg-dbp text-accent font-bold text-6xl mt-2 rounded py-4 px-8">
                      {this.state.score.value}
                    </div>

                    <div className="bg-lbp dark:bg-dbp rounded mt-4 w-full border border-lbr dark:border-dbr">
                      <div className="font-bold pt-4 text-center">
                        {this.props.language === "EN"
                          ? "Leaderboard"
                          : "ലീഡർബോർഡ്"}
                      </div>
                      {!this.props.userDetails && (
                        <div
                          className="p-4"
                          onClick={() => {
                            this.props.toggleInfoModal(
                              true,
                              "USER_DETAILS_INPUT"
                            );
                          }}
                        >
                          <div className="text-center text-lts dark:text-dts">
                            {this.props.language === "EN"
                              ? "Enter your details to start competing with other players and win prizes."
                              : "മറ്റ് കളിക്കാരുമായി മത്സരിച്ച് സമ്മാനങ്ങൾ നേടുന്നതിന് നിങ്ങളുടെ വിശദാംശങ്ങൾ നൽകുക."}
                          </div>
                          <div className="mt-4 text-center bg-accent text-dtp font-bold w-full rounded-full p-4">
                            {this.props.language === "EN"
                              ? "Register"
                              : "രജിസ്റ്റർ ചെയ്യുക"}
                          </div>
                        </div>
                      )}
                      {this.props.userDetails && this.state.leaderboard && (
                        <div className="p-4 space-y-4">
                          {this.state.leaderboard.top_10_week.length > 0 && (
                            <table className="table-auto w-full bg-lbs dark:bg-dbs rounded-sm text-sm text-center">
                              <tbody className="divide-y divide-lbp dark:divide-dbp">
                                <tr>
                                  <td
                                    className="p-2 text-lts dark:text-dts"
                                    colSpan={3}
                                  >
                                    {this.props.language === "EN"
                                      ? "Weekly :"
                                      : "ഈ ആഴ്ച :"}
                                  </td>
                                </tr>
                                {this.state.leaderboard.top_10_week
                                  .slice(0, 5)
                                  .map((user, index) => (
                                    <tr key={"quiz-leaderboard-entry-" + index}>
                                      <td className="p-2 text-lts dark:text-dts opacity-50">
                                        #{index + 1}
                                      </td>
                                      <td className="p-2 text-ltp dark:text-dtp max-w-[40vw] whitespace-nowrap overflow-hidden text-ellipsis">
                                        {user.name}
                                      </td>
                                      <td className="p-2 text-accent">
                                        {user.score}
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          )}
                          {this.state.leaderboard.top_10_month.length > 0 && (
                            <table className="table-auto w-full bg-lbs dark:bg-dbs rounded-sm text-sm text-center">
                              <tbody className="divide-y divide-lbp dark:divide-dbp">
                                <tr>
                                  <td
                                    className="p-2 text-lts dark:text-dts"
                                    colSpan={3}
                                  >
                                    {this.props.language === "EN"
                                      ? "Monthly :"
                                      : "ഈ മാസം :"}
                                  </td>
                                </tr>
                                {this.state.leaderboard.top_10_month
                                  .slice(0, 5)
                                  .map((user, index) => (
                                    <tr key={"quiz-leaderboard-entry-" + index}>
                                      <td className="p-2 text-lts dark:text-dts opacity-50">
                                        #{index + 1}
                                      </td>
                                      <td className="p-2 text-ltp dark:text-dtp max-w-[40vw] whitespace-nowrap overflow-hidden text-ellipsis">
                                        {user.name}
                                      </td>
                                      <td className="p-2 text-accent">
                                        {user.score}
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          )}
                          <div
                            className="flex text-accent justify-center items-center"
                            onClick={() => this.toggleLeaderboardOverlay(true)}
                          >
                            <FiList />
                            <span className="ml-2">
                              {this.props.language === "EN"
                                ? "Know More"
                                : "കൂടുതൽ അറിയാം"}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>

                    <div
                      className="mx-4 mt-4 text-center bg-accent text-dtp font-bold w-full rounded-full p-4"
                      onClick={() => {
                        this.challengeFriends();
                      }}
                    >
                      {textContent.challengeBtn}
                    </div>

                    <div className="text-lts dark:text-dts mt-4 text-center">
                      {textContent.endMessage}
                    </div>

                    <div
                      className="px-4 my-4 font-bold text-accent flex items-center justify-center"
                      onClick={() => this.toggleArchiveOverlay(true)}
                    >
                      <FiArchive />
                      <span className="ml-2">
                        {this.props.language === "EN"
                          ? "Questions Archive"
                          : "പഴയ ചോദ്യങ്ങൾ"}
                      </span>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          )}

          {/* In case of nonwebview, show download message */}
          {!this.props.isAndroidWebView &&
            window.location.hostname !== "localhost" && (
              <div className="flex flex-col items-center p-4 h-full justify-center">
                <span className="mb-4">
                  Please download the Katha app to play the quiz.
                </span>
                <span
                  onClick={() => {
                    window.open(
                      "https://play.google.com/store/apps/details?id=today.katha&referrer=utm_source%3Dapp_in_chrome"
                    );
                  }}
                  className="flex rounded-full bg-accent text-dtp font-bold p-4 justify-center items-center w-full"
                >
                  <FiExternalLink />
                  <span className="ml-2">Go to Playstore</span>
                </span>
              </div>
            )}
        </div>

        {this.state.showArchiveOverlay && (
          <Archive
            language={this.props.language}
            toggleArchiveOverlay={this.toggleArchiveOverlay}
            onKnowMoreClick={this.onKnowMoreClick}
          />
        )}

        {this.state.showLeaderboardOverlay && (
          <Leaderboard
            language={this.props.language}
            toggleLeaderboardOverlay={this.toggleLeaderboardOverlay}
            leaderboard={this.state.leaderboard}
            userDetails={this.props.userDetails}
            toggleInfoModal={this.props.toggleInfoModal}
          />
        )}
      </>
    );
  }
}

export default Quiz;
