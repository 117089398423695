import React, {Component} from "react";
import localStorage from '../../../services/localStorage'

class CategoryInterestCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            World           :   false,
            Business        :   false,
            Entertainment   :   false,
            Tech            :   false,
            Sports          :   false,
            Gulf            :   false,
            Health          :   false,
        }
    }

    // Unmount 
    async componentWillUnmount() {
        // Update user pref 
        for(let key in this.state) {
            if(this.state[key]) {
                await this.updateUserPref(key);
            }
        }
    }

    // Triggered on changing a category checkbox 
    onCategoryChange = (categoryName) => {

        // Get the new state
        let checked = !this.state[categoryName]

        // Set the state of the selected category 
        this.setState( { [categoryName] : checked } );
        
        // Analytics 
        if(!checked) {
            window.gtag('event', `event_init_categorydeselect_${categoryName}`);
        } else {
            window.gtag('event', `event_init_categoryselect_${categoryName}`);
        }
        
    }

    updateUserPref = async (newsCategory) => {
        const userPreferences = JSON.parse(localStorage.getItem('userPreferences') || '{}');
        userPreferences[newsCategory] = userPreferences[newsCategory] || {};
        userPreferences[newsCategory]['count'] = (userPreferences[newsCategory]['count'] || 0) + 1;
        userPreferences[newsCategory]['score'] = (userPreferences[newsCategory]['score'] || 1) + (2 / Math.pow(2, userPreferences[newsCategory]['count']));
        localStorage.setItem('userPreferences', JSON.stringify(userPreferences));
    };

    // Renders individual blocks for each category
    renderCategoryBlock = (categoryNameML, categoryCode, categoryNameEN) => {
        return (
            <div className={'rounded-full border-2 text-sm border-accent text-accent px-4 py-2 mt-2 mx-1 flex items-center h-full' + (this.state[categoryCode] ? " bg-accent text-dtp" : "")} onClick={() => this.onCategoryChange(categoryCode)}>
                <span>
                    {this.props.language==='EN' ? categoryNameEN : categoryNameML}
                </span>
                {this.state[categoryCode] && <span className="ml-2">
                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round"><polyline points="20 6 9 17 4 12"></polyline></svg>
                </span>}
            </div>
        );
    };

    // Check if at least one category is selected
    isCategorySelected() {
        // Check if at least one is selected
        for(let key in this.state) {
            if(this.state[key]) {
                return true;
            }
        }
        return false;
    }

    render() {

        // Text based on selected language
        let textContent = this.props.language==='EN' ? {
            header : 'Interested Categories',
            desc : 'Please select categories to make your experience better.',
        } : {
            header : 'താൽപ്പര്യമുള്ള വിഭാഗങ്ങൾ',
            desc : 'നിങ്ങളുടെ വായനനുഭവം മികച്ചതാക്കാൻ താല്പര്യമുള്ള വിഭാഗങ്ങൾ തിരഞ്ഞെടുക്കൂ.',
        }

        return (
            <div className='h-full border border-lbr dark:border-dbr flex flex-col rounded bg-lbp dark:bg-dbp'>
                <div className="grow flex items-center justify-center">
                    <div className="px-4">
                        <div className="text-lg text-accent font-bold mb-2">
                            {textContent.header}
                        </div>
                        <div className="text-lts dark:text-dts text-sm mb-2">
                            {textContent.desc}
                        </div>
                        <div className="flex flex-wrap justify-center">
                            {this.renderCategoryBlock("ലോകം", "World", "World")}
                            {this.renderCategoryBlock("ബിസിനസ്സ്", "Business", "Business")}
                            {this.renderCategoryBlock("വിനോദം", "Entertainment", "Entertainment")}
                            {this.renderCategoryBlock("ടെക്നോളജി", "Tech", "Technology")}
                            {this.renderCategoryBlock("കായികം", "Sports", "Sports")}
                            {this.renderCategoryBlock("ഗൾഫ്", "Gulf", "Gulf")}
                            {this.renderCategoryBlock("ആരോഗ്യം", "Health", "Health")}
                            {this.renderCategoryBlock("ജോലികൾ", "Jobs", "Jobs")}
                            {this.renderCategoryBlock("കഥകൾ", "Stories", "Stories")}
                            {this.renderCategoryBlock("വില വിവരങ്ങൾ ", "Prices", "Prices")}
                            {this.renderCategoryBlock("മോട്ടിവേഷൻ", "Motivational", "Motivational")}
                            {this.renderCategoryBlock("ന്യൂസ്‌ ക്വിസ്", "Quiz", "Quiz")}
                        </div>
                    </div>
                </div>
                <div className="flex justify-center text-accent pb-4 animate-bounce" onClick={()=>{this.props.secondarySwiper.slideNext()}}>
                    <span>
                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round"><polyline points="17 11 12 6 7 11"></polyline><polyline points="17 18 12 13 7 18"></polyline></svg>
                    </span>
                </div>
            </div>
        );
    }
}

export default CategoryInterestCard;