import React, { useEffect, useState } from "react";
import { wordList } from "../../../config/word-of-the-day";

const WordOfTheDayCard = (props) => {
  // Currently shown word
  const [activeWord, setActiveWord] = useState(null);

  useEffect(() => {
    // List of gradients
    const backgroundList = [
      {
        gradient: "from-[#4286f4] to-[#373B44]",
        endTextColor: "text-[#373B44]",
      },
      {
        gradient: "from-[#FF0099] to-[#493240]",
        endTextColor: "text-[#493240]",
      },
      {
        gradient: "from-[#8E2DE2] to-[#4A00E0]",
        endTextColor: "text-[#4A00E0]",
      },
      {
        gradient: "from-[#c31432] to-[#240b36]",
        endTextColor: "text-[#240b36]",
      },
    ];

    // Get word for current date
    let wordIndex = new Date().getDate() - 1;
    let activeWord = wordList[wordIndex];

    // Set random background
    activeWord.theme =
      backgroundList[Math.floor(Math.random() * backgroundList.length)];

    // Update state
    setActiveWord(activeWord);
  }, []);

  // Share word
  const shareWord = () => {
    window.gtag("event", "share_wotd");
    // Create message
    let shareMsg = "ഇന്നത്തെ വാക്ക് : *" + activeWord.word + "* \n\n";
    shareMsg += `അർത്ഥം : _` + activeWord.meaning + `_\n\n`;
    shareMsg += `ഉദാഹരണം : _` + activeWord.example + `_\n\n`;
    shareMsg +=
      "കൂടുതൽ പദങ്ങൾക്കും അർത്ഥങ്ങൾക്കുമായി *കഥ ആപ്പ്* ഡൗൺലോഡ് ചെയ്യാം:\n";
    shareMsg += "https://link.katha.today/download";
    // Start sharing
    let imageLink = "https://storage.katha.today/wotd_" + activeWord.word;
    window.shareScreenshot(imageLink, shareMsg);
  };

  return (
    <>
      {activeWord && (
        <div
          className={
            "h-full flex flex-col rounded overflow-hidden  bg-gradient-to-b " +
            activeWord.theme.gradient
          }
        >
          <span className="text-dtp font-bold text-center p-4 border-b border-lbr dark:border-dbr">
            {props.language === "EN" ? "Word Of The Day!" : "ഇന്നത്തെ വാക്ക്!"}
          </span>
          <div className="grow flex flex-col justify-center text-dtp px-4">
            <div className="text-4xl font-bold mb-2">{activeWord.word}</div>
            <div className="mb-2">
              <span className="text-dts mr-2">
                {props.language === "EN" ? "Meaning" : "അർത്ഥം"} :
              </span>
              {activeWord.meaning}
            </div>
            <div>
              <span className="text-dts mr-2">
                {props.language === "EN" ? "Example" : "ഉദാഹരണം"} :
              </span>
              {activeWord.example}
            </div>
          </div>
          <div
            className={
              "bg-lbp p-4 m-4 font-bold rounded-full flex justify-center " +
              activeWord.theme.endTextColor
            }
            onClick={() => shareWord()}
          >
            <span className="mr-2">
              <svg
                viewBox="0 0 24 24"
                width="24"
                height="24"
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <circle cx="18" cy="5" r="3"></circle>
                <circle cx="6" cy="12" r="3"></circle>
                <circle cx="18" cy="19" r="3"></circle>
                <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line>
                <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line>
              </svg>
            </span>
            <span>{props.language === "EN" ? "Share" : "ഷെയർ"}</span>
          </div>
        </div>
      )}
    </>
  );
};

export default WordOfTheDayCard;
