import axios from 'axios';
import {API_BASE_URL} from '../config/environment';

export const api = axios.create({
    baseURL: API_BASE_URL,
    timeout: 300000
});

export const clientConfig = async () => {
    return {
        headers: {
            'X-KATHA-CORE-CLIENT-KEY': 'apikey'
        }
    };
};

export default {
    api
};