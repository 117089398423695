import { api, clientConfig } from "./api";

const questionsUrl = "/quiz";
const archiveUrl = "/quiz/archive";
const saveScoreUrl = "/quiz_scores/save_quiz_score";
const leaderboardUrl = "/quiz_scores/quiz_leaderboard";

const executeGet = async (url, params) => {
  let urlWithParams = url + (params ? "?" + params : "");
  try {
    const response = await api.get(urlWithParams, await clientConfig());
    if (response && !!response.data) {
      return response.data;
    } else {
      return [];
    }
  } catch (err) {
    return [];
  }
};

const executePost = async (url, params, payload) => {
  let urlWithParams = url + (params ? "?" + params : "");
  try {
    const response = await api.post(
      urlWithParams,
      payload,
      await clientConfig()
    );
    if (response && !!response.data) {
      return response.data;
    } else {
      return [];
    }
  } catch (err) {
    return [];
  }
};

export const getQuestions = async () => {
  return executeGet(questionsUrl);
};

export const getArchive = async () => {
  return executeGet(archiveUrl);
};

export const saveScore = async (payload) => {
  return executePost(saveScoreUrl, null, payload);
};

export const getLeaderboard = async (params) => {
  return executeGet(leaderboardUrl, params);
};

export default {
  getQuestions,
  getArchive,
  saveScore,
  getLeaderboard,
};
