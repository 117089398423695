import { api, clientConfig } from "./api";

const listUrl = "/items";
const latestAvailableUrl = "/items/latest_available";
const glancesUrl = "/items/glances";
const itemUrl = "/items/item";
const pinsUrl = "/items/pins";
const realTimeContentUrl = "/items/real_time_content";
const countInteractionUrl = "/items/count_interaction";
const jobsUrl = "/items/jobs";
const dailyPricesUrl = "/items/daily_prices";
const weatherUrl = "/items/weather";
const positivesUrl = "/items/positives";
const latestAvailablePositivesUrl = "/items/latest_available_positives";
const districtItemsUrl = "/items/district_items";
const districtItemUrl = "/items/district_item";

export const list = async () => {
  try {
    const response = await api.get(listUrl, await clientConfig());
    if (response && !!response.data && !!response.data.data) {
    } else {
      return [];
    }
    return {
      items: response.data.data,
      checksum: response.data.checksum,
    };
  } catch (err) {
    return [];
  }
};

export const latestAvailable = async (checksum) => {
  try {
    const url = latestAvailableUrl + "?checksum=" + checksum;
    const response = await api.get(url, await clientConfig());
    if (response && !!response.data) {
    } else {
      return [];
    }
    return response.data.data;
  } catch (err) {
    return [];
  }
};

export const glances = async () => {
  try {
    const response = await api.get(glancesUrl, await clientConfig());
    if (response && !!response.data && !!response.data.data) {
    } else {
      return [];
    }
    return response.data.data.data;
  } catch (err) {
    return [];
  }
};

export const item = async (uid) => {
  try {
    const url = itemUrl + "?uid=" + uid;
    const response = await api.get(url, await clientConfig());
    if (response && !!response.data) {
    } else {
      return [];
    }
    return response.data.data;
  } catch (err) {
    return [];
  }
};

export const pins = async () => {
  try {
    const response = await api.get(pinsUrl, await clientConfig());
    if (response && !!response.data) {
    } else {
      return [];
    }

    return {
      data: response.data.data.data,
      checksum: response.data.data.checksum,
    };
  } catch (err) {
    return [];
  }
};

export const realTimeContent = async () => {
  try {
    const response = await api.get(realTimeContentUrl, await clientConfig());
    if (response && !!response.data) {
    } else {
      return [];
    }
    return response.data.data;
  } catch (err) {
    return [];
  }
};

export const countInteraction = async (itemId, op) => {
  try {
    const url = countInteractionUrl + "?item_id=" + itemId + "&op=" + op;
    await api.get(url, await clientConfig());
  } catch (err) {}
};

export const jobs = async () => {
  try {
    const response = await api.get(jobsUrl, await clientConfig());
    if (response && !!response.data) {
    } else {
      return [];
    }

    return {
      data: response.data.data,
    };
  } catch (err) {
    return [];
  }
};

export const dailyPrices = async () => {
  try {
    const response = await api.get(dailyPricesUrl, await clientConfig());
    if (response && !!response.data) {
    } else {
      return [];
    }

    return {
      data: response.data.data,
    };
  } catch (err) {
    return [];
  }
};

export const weather = async () => {
  try {
    const response = await api.get(weatherUrl, await clientConfig());
    if (response && !!response.data) {
    } else {
      return [];
    }

    return {
      data: response.data.data,
    };
  } catch (err) {
    return [];
  }
};

export const positives = async () => {
  try {
    const response = await api.get(positivesUrl, await clientConfig());
    return response.data;
  } catch (err) {
    return [];
  }
};

export const latestAvailablePositives = async (checksum) => {
  try {
    const url = latestAvailablePositivesUrl + "?checksum=" + checksum;
    const response = await api.get(url, await clientConfig());
    return response.data.data;
  } catch (err) {
    return [];
  }
};

//
// News items in a given district
//
export const districtItems = async (districtCode) => {
  try {
    const url = districtItemsUrl + "?district_code=" + districtCode;
    const response = await api.get(url, await clientConfig());
    return response.data.data.items;
  } catch (err) {
    return [];
  }
};

//
// District news item by uid
//
export const districtItem = async (uid) => {
  try {
    const url = districtItemUrl + "?uid=" + uid;
    const response = await api.get(url, await clientConfig());
    return response.data.data;
  } catch (err) {
    return [];
  }
};

export default {
  list,
  latestAvailable,
  glances,
  item,
  pins,
  realTimeContent,
  countInteraction,
  jobs,
  dailyPrices,
  weather,
  positives,
  latestAvailablePositives,
  districtItems,
  districtItem,
};
