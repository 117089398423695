import React, {Component} from "react";
import localStorage from "../../../services/localStorage";

// Images 
import shareCardImg from '../../../assets/share_card_img.svg';

class ShareUsCard extends Component {
    shareKatha = () => {
        let shareMsg = "വെറും *7 സെക്കൻഡിൽ* വാർത്തകൾ കേട്ടറിയാം.\n";
        shareMsg += "ഇനി തത്സമയ വാർത്തകൾ വിരൽത്തുമ്പിൽ.\n\n";
        shareMsg += "*കഥ ആപ്പ്* ഡൗൺലോഡ് ചെയ്യൂ: https://app.katha.today/";

        let shareLink = 'https://api.whatsapp.com/send?text=' + encodeURIComponent(shareMsg);

        window.open(shareLink, "_blank");
    };

    async componentDidMount() {
        // ttl of 10 days
        await localStorage.setItem('shareUsCardShown', true, 10*24*60*60*1000);
        window.gtag('event', 'event_share_us_card_shown');
    }

    render() {
        const {
            item
        } = this.props;

        let textContent = this.props.language==='EN' ? {
            mainText : 'Support us by sharing Katha app with your friends & family.',
            shareBtn : 'Share Katha',
        } : {
            mainText : 'നിങ്ങൾക്ക് കഥ ആപ്പ് ഇഷ്ടമായെങ്കിൽ പ്രിയപ്പെട്ടവരുമായി ഷെയർ ചെയ്തു ഞങ്ങളെ സപ്പോർട്ട് ചെയ്യൂ!',
            shareBtn : 'ഷെയർ ചെയ്യാം',
        }

        return (
            <div className='h-full border border-lbr dark:border-dbr flex flex-col rounded bg-lbp dark:bg-dbp'>
                <div className="grow flex items-center justify-center">
                    <div className="px-4">
                        <img className="w-3/4 mx-auto" src={shareCardImg} alt=''/>
                        <div className="text-ltp dark:text-dtp mt-4">
                            {textContent.mainText}
                        </div>
                        <div className="bg-accent p-4 font-bold text-dtp mt-4 flex items-center justify-center rounded-full"
                            onClick={async () => {
                                window.gtag('event', 'event_share_us_card_share');
                                this.shareKatha();
                            }}
                        >
                            <span className="mr-2">
                                <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" ><circle cx="18" cy="5" r="3"></circle><circle cx="6" cy="12" r="3"></circle><circle cx="18" cy="19" r="3"></circle><line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line><line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line></svg>
                            </span>
                            {textContent.shareBtn}
                        </div>
                    </div>
                </div>
                <div className="flex justify-center text-accent pb-4 animate-bounce" onClick={()=>{this.props.secondarySwiper.slideNext()}}>
                    <span>
                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round"><polyline points="17 11 12 6 7 11"></polyline><polyline points="17 18 12 13 7 18"></polyline></svg>
                    </span>
                </div>
            </div>
        );
    }
}

export default ShareUsCard;