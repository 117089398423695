import React, { Component } from "react";
import {
  RiHeadphoneFill,
  RiHeadphoneLine,
  RiSuitcaseFill,
  RiSuitcaseLine,
} from "react-icons/ri";

class BottomNav extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // Create class name for links based on current view
  genLinkClass(viewIndex) {
    let className = "grow flex flex-col items-center justify-center text-2xl";
    // If index matches with props, set active
    if (viewIndex === this.props.primarySwiperActiveIndex) {
      className += " text-accent";
    } else {
      className += " text-lts dark:text-dts";
    }
    return className;
  }

  // Swipe to clicked link
  onLinkClick(viewIndex) {
    if (viewIndex !== this.props.primarySwiperActiveIndex) {
      this.props.onPrimarySwiperChange(
        viewIndex,
        this.props.primarySwiperActiveIndex
      );
    } else if (viewIndex === 1) {
      window.gtag("event", "feed_move_to_top");
      // Simulate back btn click
      this.props.handleKeyDownEvent("Z");
    }
  }

  render() {
    return (
      <div className="h-[8vh] flex border-t border-lbr dark:border-dbr bg-lbp dark:bg-dbp">
        <span
          className={this.genLinkClass(1)}
          onClick={() => {
            this.onLinkClick(1);
          }}
        >
          <span className="relative flex flex-col items-center justify-center">
            {this.props.unreadNewsCount > 0 && (
              <span className="absolute -top-1 bg-orange text-dtp rounded-full left-6 text-sm font-bold px-1">
                {this.props.unreadNewsCount}
              </span>
            )}

            <span>
              <svg
                width="24"
                height="24"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M23.04 3.52002H8.64003C7.57965 3.52002 6.72003 4.37963 6.72003 5.44002V21.44C6.72003 22.5004 7.57965 23.36 8.64003 23.36H23.04C24.1004 23.36 24.96 22.5004 24.96 21.44V5.44002C24.96 4.37963 24.1004 3.52002 23.04 3.52002Z"
                  fill={
                    this.props.primarySwiperActiveIndex === 1
                      ? "currentColor"
                      : "none"
                  }
                  stroke="currentColor"
                  strokeWidth="2.5"
                />
                <path
                  d="M7 28C7 27.4477 7.40295 27 7.9 27H24.1C24.5971 27 25 27.4477 25 28C25 28.5523 24.5971 29 24.1 29H7.9C7.40295 29 7 28.5523 7 28Z"
                  fill="currentColor"
                  stroke="currentColor"
                  strokeWidth="2.1"
                />
              </svg>
            </span>

            <div className="text-xs">
              {this.props.language === "EN" ? "News" : "വാർത്ത"}
            </div>
          </span>
        </span>

        <span
          className={this.genLinkClass(2)}
          onClick={() => {
            this.onLinkClick(2);
          }}
        >
          <span>
            {this.props.primarySwiperActiveIndex === 2 ? (
              <RiHeadphoneFill />
            ) : (
              <RiHeadphoneLine />
            )}
          </span>
          <div className="text-xs">
            {this.props.language === "EN" ? "Positive" : "പോസിറ്റീവ്"}
          </div>
        </span>

        {/* <span className={this.genLinkClass(3)} onClick={() => {this.onLinkClick(3)}}>
                    <span>
                        {this.props.primarySwiperActiveIndex===3 ? <RiSuitcaseFill/> : <RiSuitcaseLine/>}
                    </span>
                    <div className="text-xs">
                        {this.props.language==='EN' ? 'Jobs' : 'തൊഴിൽ'}
                    </div>
                </span> */}

        {!this.props.firstSession && (
          <span
            className={this.genLinkClass(4)}
            onClick={() => {
              this.onLinkClick(4);
            }}
          >
            <span>
              <svg
                width="24"
                height="24"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.3333 4H4V13.3333H13.3333V4Z"
                  fill={
                    this.props.primarySwiperActiveIndex === 4
                      ? "currentColor"
                      : "none"
                  }
                  stroke="currentColor"
                  strokeWidth="2.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M27.9994 4H18.666V13.3333H27.9994V4Z"
                  fill={
                    this.props.primarySwiperActiveIndex === 4
                      ? "currentColor"
                      : "none"
                  }
                  stroke="currentColor"
                  strokeWidth="2.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M27.9994 18.668H18.666V28.0013H27.9994V18.668Z"
                  fill={
                    this.props.primarySwiperActiveIndex === 4
                      ? "currentColor"
                      : "none"
                  }
                  stroke="currentColor"
                  strokeWidth="2.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13.3333 18.668H4V28.0013H13.3333V18.668Z"
                  fill={
                    this.props.primarySwiperActiveIndex === 4
                      ? "currentColor"
                      : "none"
                  }
                  stroke="currentColor"
                  strokeWidth="2.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <div className="text-xs">
              {this.props.language === "EN" ? "Extras" : "കൂടുതൽ"}
            </div>
          </span>
        )}
      </div>
    );
  }
}

export default BottomNav;
