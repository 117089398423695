import React, { useEffect, useState } from "react";
import { FiArrowLeft, FiEdit2, FiHelpCircle } from "react-fi";
import quizService from "../../../services/quizService";

const Archive = (props) => {
  // List of questions
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    // Initial data load
    fetchData();
  }, []);

  //
  // Fetch data from server
  //
  const fetchData = async () => {
    // Get list from API
    let questions = await quizService.getArchive();
    questions.data.questions.forEach((question) => {
      // Find correct answer value based on index
      let correctAnswerIndex = question.correct_answer_index.replace(
        /\s+/g,
        ""
      );
      if (correctAnswerIndex === "1") {
        question.correctAnswerValue = question.answer_option_first;
      } else if (correctAnswerIndex === "2") {
        question.correctAnswerValue = question.answer_option_second;
      } else if (correctAnswerIndex === "3") {
        question.correctAnswerValue = question.answer_option_third;
      } else {
        question.correctAnswerValue = question.answer_option_fourth;
      }
      // Update variable name of news item UID
      question.newsItemUid = question.news_item_uid.replace(/\s+/g, "");
    });
    setQuestions(questions.data.questions);
  };

  return (
    <div className="bg-lbs dark:bg-dbs text-ltp dark:text-dtp absolute top-0 w-screen h-screen text-left z-10">
      <div
        className="h-[8vh] flex items-center border-b border-lbr dark:border-dbr sticky top-0 px-4 text-accent bg-lbp dark:bg-dbp"
        onClick={() => {
          props.toggleArchiveOverlay(false);
        }}
      >
        <span>
          <FiArrowLeft />
        </span>
        <span className="text-ltp dark:text-dtp font-bold grow text-center">
          {props.language === "EN" ? "Questions Archive" : "പഴയ ചോദ്യങ്ങൾ"}
        </span>
        <span className="opacity-0">
          <FiArrowLeft />
        </span>
      </div>

      <div className="h-[92vh] overflow-auto">
        <div className="text-sm text-lts dark:text-dts p-4 text-center">
          {props.language === "EN"
            ? "Click on a question to know more."
            : "കൂടുതൽ അറിയാൻ ഒരു ചോദ്യത്തിൽ ക്ലിക്ക് ചെയ്യുക."}
        </div>
        <div className="flex flex-col divide-y divide-lbr dark:divide-dbr">
          {questions.map((question) => (
            <div
              key={question.id}
              className="p-4 space-y-2"
              onClick={() => {
                if (question.newsItemUid) {
                  props.onKnowMoreClick(question);
                }
              }}
            >
              <div className="flex items-center">
                <span className="mr-4 text-lts dark:text-dts">
                  <FiHelpCircle />
                </span>
                <span>{question.title}</span>
              </div>
              <div className="flex items-center">
                <span className="mr-4 text-lts dark:text-dts">
                  <FiEdit2 />
                </span>
                <span className="text-accent">
                  {question.correctAnswerValue}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Archive;
