import React, { useEffect, useState } from "react";
import localStorage from "../../../services/localStorage";

const DistrictSelectionCard = (props) => {

    // Selected district 
    const [selectedDistrict, setSelectedDistrict] = useState(null);

    // List of all districts 
    const districtList = [
        {
            nameML : 'തിരുവനന്തപുരം',
            nameEN : 'Trivandrum',
            code : 'KL01'
        },
        {
            nameML : 'കൊല്ലം',
            nameEN : 'Kollam',
            code : 'KL02'
        },
        {
            nameML : 'പത്തനംതിട്ട',
            nameEN : 'Pathanamthitta',
            code : 'KL03'
        },
        {
            nameML : 'ആലപ്പുഴ',
            nameEN : 'Alappuzha',
            code : 'KL04'
        },
        {
            nameML : 'കോട്ടയം',
            nameEN : 'Kottayam',
            code : 'KL05'
        },
        {
            nameML : 'ഇടുക്കി',
            nameEN : 'Idukki',
            code : 'KL06'
        },
        {
            nameML : 'എറണാകുളം',
            nameEN : 'Ernakulam',
            code : 'KL07'
        },
        {
            nameML : 'തൃശ്ശൂർ',
            nameEN : 'Thrissur',
            code : 'KL08'
        },
        {
            nameML : 'പാലക്കാട്',
            nameEN : 'Palakkad',
            code : 'KL09'
        },
        {
            nameML : 'മലപ്പുറം',
            nameEN : 'Malappuram',
            code : 'KL10'
        },
        {
            nameML : 'കോഴിക്കോട്',
            nameEN : 'Kozhikode',
            code : 'KL11'
        },
        {
            nameML : 'വയനാട്',
            nameEN : 'Wayanad',
            code : 'KL12'
        },
        {
            nameML : 'കണ്ണൂർ',
            nameEN : 'Kannur',
            code : 'KL13'
        },
        {
            nameML : 'കാസർഗോഡ്',
            nameEN : 'Kasaragod',
            code : 'KL14'
        },
    ];

    useEffect(() => {
        // Check if district is already selected 
        const selectedDistrict = localStorage.getItem('selectedDistrict');
        if(selectedDistrict) {
            setSelectedDistrict(selectedDistrict)
        }
    }, []);

    // Renders individual blocks for each district
    const renderDistrictBlock = (district, index) => {
        return (
            <React.Fragment key={index}>
                <div className={'rounded-full border-2 text-sm border-accent text-accent px-4 py-2 mt-2 mx-1 flex items-center h-full' + (selectedDistrict===district.code ? " bg-accent text-dtp" : "")} onClick={() => onDistrictSelect(district)}>
                    <span>
                        {props.language==='EN' ? district.nameEN : district.nameML}
                    </span>
                    {(selectedDistrict===district.code) && <span className="ml-2">
                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round"><polyline points="20 6 9 17 4 12"></polyline></svg>
                    </span>}
                </div>
            </React.Fragment>
        );
    };

    // On selecting a district
    const onDistrictSelect = (district) => {
        if(district.code!==selectedDistrict) {
            // Update state 
            setSelectedDistrict(district.code);
            // Update local storage 
            localStorage.setItem('selectedDistrict', district.code);
            // Update state 
            props.updateGlobalStateValue('selectedDistrict', district.code)
            // Analytics 
            window.gtag('event', `district_selection_card_select_${district.code}`);
            // Animate secondary swiper 
            let elem = document.querySelector("#secondarySwiper").children[0];
            setTimeout(function() {
                elem.style.transition = "transform 0.5s ease";
                elem.style.transform = "translateY(-30vh)"
            }, 1000);
            setTimeout(function() {
                elem.style.transition = "transform 0.25s ease";
                elem.style.transform = "translateY(0vh)"
            }, 1750);
        }
    }

    return (
        <div className='h-full border border-lbr dark:border-dbr flex flex-col rounded bg-lbp dark:bg-dbp'>
            <div className="grow flex items-center justify-center">
                <div className="px-4">
                    <div className="text-lg text-accent font-bold mb-2">
                        ജില്ല തിരഞ്ഞെടുക്കുക
                    </div>
                    <div className="text-lts dark:text-dts text-sm mb-2">
                        തിരഞ്ഞെടുക്കുന്ന ജില്ലയിലെ വാർത്തകൾ തത്സമയം കഥ ആപ്പിൽ ലഭ്യമാകും.
                    </div>
                    <div className="flex flex-wrap justify-center">
                        {districtList.map((district, index)=>(
                            renderDistrictBlock(district, index)
                        ))}
                    </div>
                </div>
            </div>
            <div className="flex justify-center text-accent pb-2 animate-bounce" onClick={()=>{this.props.secondarySwiper.slideNext()}}>
                <span>
                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round"><polyline points="17 11 12 6 7 11"></polyline><polyline points="17 18 12 13 7 18"></polyline></svg>
                </span>
            </div>
            <div className="text-lts dark:text-dts text-sm mb-4 px-4" onClick={()=>{props.secondarySwiper.slideNext()}}>
                വാർത്തകളിലേക്ക് കടക്കാനായി മുകളിലോട്ട് സ്വൈപ്പ് ചെയ്യുക.
            </div>
        </div>
    );
};

export default DistrictSelectionCard;