//
// List of all words
//
export const wordList = [
  {
    word: "പകയന്‍",
    meaning: "ശത്രു",
    example: "അവൻ എന്റെ ഒരു പഴയ പകയൻ ആയിരുന്നു.",
  },
  {
    word: "പകലവന്‍",
    meaning: "സൂര്യന്‍",
    example: "പകലവൻ ഉദിച്ച് ഉയരുവാൻ നേരമാകുന്നു.",
  },
  { word: "പകിടി", meaning: "വഞ്ചന", example: "അന്യരോട് പകിടി കാട്ടരുത്." },
  {
    word: "പക്ഷജന്‍",
    meaning: "ചന്ദ്രന്‍",
    example: "രാത്രിയിൽ പക്ഷജൻ അതിസുന്ദരമാണ്.",
  },
  {
    word: "പക്ഷത",
    meaning: "സ്നേഹം",
    example: "പക്ഷതത്തോടെ എല്ലാവരോടും പെരുമാറുവാൻ ശീലിക്കണം.",
  },
  {
    word: "പക്ഷ്മം",
    meaning: "ചിറക്",
    example: "കിളികൾ തങ്ങളുടെ പക്ഷ്മം വിടർത്തി പറന്നു.",
  },
  {
    word: "പങ്കം",
    meaning: "പാപം",
    example: "പങ്കം ചെയ്യാതെ നടക്കുന്നതാണ് ഉത്തമം.",
  },
  {
    word: "പഞ്ചഷഷ്ടി",
    meaning: "അറുപത്തിയഞ്ച്",
    example: "അവന്റെ മുത്തച്ഛന്റെ പഞ്ചഷഷ്ടി വയസ്സ് ആണ് ഇന്ന്.",
  },
  {
    word: "പടലി",
    meaning: "വൃക്ഷം",
    example: "അവന്റെ വീടിന്റെ മുറ്റത്ത് നിറയെ പടലികളാണ്.",
  },
  {
    word: "പാടലി",
    meaning: "കള്ള്",
    example: "അപ്പത്തിൽ ഒഴിക്കുവാൻ അല്പം പാടലി മേടിക്കണം.",
  },
  {
    word: "പടഹം",
    meaning: "പെരുമ്പറ",
    example: "രാജാവ് വരുന്നതിന് മുൻപുള്ള പടഹം മുഴങ്ങി.",
  },
  {
    word: "പടവാപം",
    meaning: "കൂടാരം",
    example: "സർക്കസുകാർ നിരവധി പടവാപങ്ങൾ പണിയുന്നുണ്ട്.",
  },
  {
    word: "പണ്യം",
    meaning: "വില",
    example: "ആ അലങ്കാരവസ്തുവിന്റെ പണ്യം എത്രയാണ്?",
  },
  {
    word: "മച്ചകം",
    meaning: "മേല്‍ത്തട്ടുള്ള മുറി",
    example: "പാത്രങ്ങളെല്ലാം മച്ചകത്തേക്ക് വച്ചോളൂ.",
  },
  {
    word: "മജ്ലിസ്",
    meaning: "സഭ",
    example: "സുൽത്താന്റെ മജ്ലിസ് തുടങ്ങുവാൻ പോകുന്നതേ ഉള്ളു.",
  },
  {
    word: "മഞ്ചകം",
    meaning: "കട്ടില്‍",
    example: "വീട്ടിലേക്ക് ഒരു മഞ്ചകം വാങ്ങണം.",
  },
  {
    word: "മട്ടിയം",
    meaning: "മുഖസ്തുതി, പ്രശംസ",
    example: "മട്ടിയം പറയുന്നവരെ സൂക്ഷിക്കണം.",
  },
  {
    word: "മണികം",
    meaning: "രത്നം",
    example: "വിലപിടിപ്പുള്ളൊരു മണികമാണ് അവൾ അണിഞ്ഞത്.",
  },
  {
    word: "മണ്ഡം",
    meaning: "തവള",
    example: "കുളത്തിൽ നിറയെ മണ്ഡങ്ങൾ നിറഞ്ഞിരിക്കുന്നു.",
  },
  {
    word: "മണ്ണാന്‍",
    meaning: "ചിലന്തി",
    example: "വീട്ടിനകത്ത് നിറയെ മണ്ണാന്റെ വലകളാണ്.",
  },
  { word: "മത്തേഭം", meaning: "മദയാന", example: "ആ മത്തേഭം അപകടകാരിയാണ്." },
  {
    word: "മദനം",
    meaning: "ഉഴുന്ന്",
    example: "വീട്ടിലേക്ക് അല്പം മദനം മേടിക്കണം.",
  },
  { word: "മനവി", meaning: "ഭാര്യ", example: "അവന്റെ മനവിയാണ് അത്." },
  {
    word: "മന്തവ്യം",
    meaning: "വിചാരം",
    example: "എന്ത് മന്തവ്യത്തോടെയാണ് നീ ഇങ്ങനെ നിൽക്കുന്നത്?",
  },
  {
    word: "മന്തി",
    meaning: "വണ്ട്",
    example: "ഒരു മന്തി അവന്റെ തലക്ക് ചുറ്റും പറക്കുന്നു.",
  },
  {
    word: "മന്ദരാഗം",
    meaning: "മങ്ങിയ നിറം",
    example: "മന്ദരാഗത്തോടെയുള്ള ഒരു പൂവാണ് അത്.",
  },
  {
    word: "മയൂകം",
    meaning: "മയിൽ",
    example: "വീട്ടുമുറ്റത്ത് ഒരു മയൂകം വന്നിരിക്കുന്നു.",
  },
  { word: "മയൂഖം", meaning: "ഭംഗി", example: "എത്ര മയൂഖമുള്ള മുഖം." },
  {
    word: "മയ്യം",
    meaning: "മൃതദേഹം",
    example: "അദ്ദേഹത്തിന്റെ മയ്യം എപ്പോൾ കൊണ്ടുവരും.",
  },
  {
    word: "മരിചം",
    meaning: "കുരുമുളക്",
    example: "അല്പം മരിചം വിൽക്കുവാനുണ്ട്.",
  },
  {
    word: "മാരുതം",
    meaning: "തുമ്പിക്കൈ",
    example: "ആന തന്റെ മാരുതം കൊണ്ട് പനംപട്ട എടുത്തു.",
  },
];
export default wordList;
