import React, { Component } from "react";
import localStorage from "../../../services/localStorage";
import {
  itemCategories,
  getSecondaryCategoryDisplayName,
} from "../../../config/item-categories";
import Toggle from "react-toggle";
import { FiAlertTriangle } from "react-fi";

class InfoModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      secondaryCategoryDisinterest: {
        list: [],
        message: "",
      },
    };
  }

  componentDidMount() {
    setTimeout(() => {
      document.querySelector(".info-modal").style.transform = "translateY(0)";
    }, 0);
  }

  componentDidUpdate(prevProps) {
    if (this.props.conf.contentFlag !== prevProps.conf.contentFlag) {
      if (this.props.conf.contentFlag === "NEWS_ITEM_DISINTEREST") {
        this.setState({
          secondaryCategoryDisinterest: {
            list: [],
            message: "",
          },
        });
      }
    }
  }

  // Toggle theme
  toggleTheme(className) {
    window.gtag("event", `event_theme_select` + className);
    if (className === "dark") {
      // Add the class name to body
      document.body.classList.add("dark");
      window.setTheme("dark");
    } else {
      // Remove existing classes
      document.body.classList.remove("dark");
      window.setTheme("light");
    }
    // Update local storage
    localStorage.setItem("color_theme", className);
    // Close modal
    this.props.toggleInfoModal(false);
    // Update in app.js
    this.props.toggleTheme(className);
  }

  // Toggle language
  toggleLang(lang) {
    window.gtag("event", `event_lang_select` + lang);
    // Update local storage
    localStorage.setItem("lang", lang);
    // Close modal
    this.props.toggleInfoModal(false);
    // Update in app.js
    this.props.toggleLang(lang);
  }

  // On clicking read more button
  onReadMoreClick(type) {
    // Find current news item from list
    let currentItem = this.props.newsItems.find(
      (item) => item.uid === window.global__viewportUid
    );
    if (currentItem && !!currentItem.link) {
      // Pause audio
      this.props.setPlayPauseState("pause");

      this.props.androidOpenURL(currentItem);
      this.props.updateUserPref(currentItem, "readmore");

      window.gtag("event", "read_more_by_uid_" + currentItem.uid);
      window.logFirebaseEvent("read_more_by_uid_" + currentItem.uid);
      if (currentItem.district) {
        window.gtag("event", "district_read_more_" + currentItem.district);
        window.logFirebaseEvent("district_read_more_" + currentItem.district);
      } else {
        window.gtag("event", "event_readmore_click");
        window.logFirebaseEvent("feed_read_more");
      }
      if (currentItem.source) {
        window.gtag("event", "read_more_by_source_" + currentItem.source);
        window.logFirebaseEvent("read_more_by_source_" + currentItem.source);
      }
    } else {
      // Pause audio
      this.props.setPlayPauseState("pause");
      // Fallback to website
      window.androidOpenURL("https://katha.today");
    }

    // Close modal
    this.props.toggleInfoModal(false);
  }

  // On clicking share button
  onGenericShareClick(type) {
    if (type === "job") {
      if (this.props.activeJobItem && this.props.activeJobItem.link) {
        window.gtag(
          "event",
          "event_job_share_" + this.props.activeJobItem.source
        );
        let shareMsg = "*" + this.props.activeJobItem.title + "*\n\n";
        if (this.props.activeJobItem.salary !== "") {
          shareMsg += "ശമ്പളം: *₹" + this.props.activeJobItem.salary + "*\n\n";
        }
        shareMsg +=
          "ഈ ജോലിക്കായി അപേക്ഷിക്കാനും ആകർഷകമായ മറ്റ് തൊഴിലവസരങ്ങൾ അറിയാനും *കഥ ആപ്പ്* ഡൗൺലോഡ് ചെയ്യുക:\nhttps://link.katha.today/jobs";
        // Start sharing
        window.shareGeneric(shareMsg);
      }
    } else {
      // Find current news item from list
      let currentItem = this.props.newsItems.find(
        (item) => item.uid === window.global__viewportUid
      );
      if (currentItem) {
        if (currentItem.district) {
          window.gtag("event", "district_genericshare_" + currentItem.district);
          window.logFirebaseEvent(
            "district_genericshare_" + currentItem.district
          );
        } else {
          window.gtag("event", "event_generic_share");
          window.logFirebaseEvent("feed_generic_share");
        }
        // Start sharing
        this.props.startGenericSharing(currentItem);
        // Update user preferences
        this.props.updateUserPref(currentItem, "share");
      }
    }
    // Close modal
    this.props.toggleInfoModal(false);
  }

  //
  // Save in bookmarks
  //
  bookmarkItem(type) {
    // Find current news item from list
    let currentItem = this.props.newsItems.find(
      (item) => item.uid === window.global__viewportUid
    );
    if (currentItem) {
      const bookmarkEntry = {
        uid: currentItem.uid,
        title: currentItem.title,
        image_link: currentItem.image_link,
      };
      // Get existing list
      let bookmarks = JSON.parse(localStorage.getItem("bookmarks"));
      if (bookmarks === null) {
        bookmarks = [bookmarkEntry];
      } else {
        // Avoid duplicates
        if (!bookmarks.some((i) => i.uid === bookmarkEntry.uid)) {
          bookmarks.push(bookmarkEntry);
        }
      }
      // Save back
      localStorage.setItem("bookmarks", JSON.stringify(bookmarks));
      // Show toast message
      this.props.updateGlobalStateValue("toastMessage", {
        flag: "BOOKMARK_SUCCESS",
        content:
          this.state.language === "EN"
            ? "Bookmark saved successfully"
            : "Bookmark saved successfully",
      });
      window.gtag("event", "news_bookmark");
    }
    // Close modal
    this.props.toggleInfoModal(false);
  }

  onWhatsAppShareClick(type) {
    // Find current news item from list
    let currentItem = this.props.newsItems.find(
      (item) => item.uid === window.global__viewportUid
    );
    if (!!currentItem) {
      this.props.updateUserPref(currentItem, "share");
      this.props.startSharing(currentItem);
    }
    // Close modal
    this.props.toggleInfoModal(false);
  }

  // On clicking video share button
  onVideoShareClick(type) {
    // Find current news item from list
    let currentItem = this.props.newsItems.find(
      (item) => item.uid === window.global__viewportUid
    );
    if (currentItem && !!currentItem.video_link) {
      this.props.updateUserPref(currentItem, "share");
      // Pause audio
      this.props.setPlayPauseState("pause");
      // Start sharing
      let shareMsg = this.props.createShareMessage(currentItem);
      window.shareVideo(currentItem.video_link, shareMsg);
      window.gtag("event", "event_share_video");
      window.logFirebaseEvent("feed_video_share");
    }
    // Close modal
    this.props.toggleInfoModal(false);
  }

  // On clicking share news feedback
  onShareFeedbackClick(type) {
    if (type === "job") {
      if (this.props.activeJobItem && this.props.activeJobItem.link) {
        window.gtag("event", "event_job_feedback_click");
        // Generate message
        let feedbackMsg =
          "നമസ്ക്കാരം കഥ, \n\nഈ ജോലിയെ കുറിച്ചുള്ള എന്റെ അഭിപ്രായം ഇവിടെ രേഖപ്പെടുത്തുന്നു. \n\n" +
          this.props.activeJobItem.title +
          "\n\n" +
          this.props.activeJobItem.link;
        // Open whatsapp
        window.open(
          "http://wa.me/917907741997?text=" + encodeURIComponent(feedbackMsg)
        );
      }
    } else {
      window.gtag("event", "event_news_feedback_click");
      // Find current news item from list
      let currentItem = this.props.newsItems.find(
        (item) => item.uid === window.global__viewportUid
      );
      // Pause audio
      this.props.setPlayPauseState("pause");
      if (currentItem && !!currentItem.short_url) {
        // Generate message
        let feedbackMsg =
          "നമസ്ക്കാരം കഥ, \n\nഈ വാർത്തയെ കുറിച്ചുള്ള എന്റെ അഭിപ്രായം ഇവിടെ രേഖപ്പെടുത്തുന്നു. \n\n" +
          currentItem.short_url;
        // Open whatsapp
        window.open(
          "http://wa.me/917907741997?text=" + encodeURIComponent(feedbackMsg)
        );
      }
    }

    // Close modal
    this.props.toggleInfoModal(false);
  }

  //
  // Find similar secondary categories for news item
  //
  getSimilarSecondaryCategories = () => {
    let categoryList = [];
    // Find current news item from list
    let currentItem = this.props.newsItems.find(
      (item) => item.uid === window.global__viewportUid
    );
    // Check for secondary categories
    if (currentItem && currentItem.secondary_categories) {
      // Iterate over categories
      currentItem.secondary_categories.forEach((category) => {
        if (category.split(" / ").length > 1) {
          // Split the category and get correct name
          let categoryName = category.split(" / ")[1];
          // Generate display name
          let displayName = getSecondaryCategoryDisplayName(
            categoryName,
            this.props.language
          );
          // Find parent category
          let parentCategory = itemCategories.find((i) =>
            i.secondaryCategories.some((j) => j === categoryName)
          );
          if (parentCategory) {
            categoryList.push({
              parentCategory: parentCategory.categoryName,
              categoryName: categoryName,
              displayName: displayName,
            });
            // Find similar categories
            parentCategory.secondaryCategories.forEach((similarCategory) => {
              // Avoid duplicate entries
              if (
                !categoryList.some((i) => i.categoryName === similarCategory)
              ) {
                // Add to list
                let displayName = getSecondaryCategoryDisplayName(
                  similarCategory,
                  this.props.language
                );
                categoryList.push({
                  parentCategory: parentCategory.categoryName,
                  categoryName: similarCategory,
                  displayName: displayName,
                });
              }
            });
          }
        }
      });
    }
    return categoryList;
  };

  //
  // Remove or add a category to disinterest list
  //
  changeSecondaryCategoryDisinterest = async (category, checked) => {
    let secondaryCategoryDisinterest = JSON.parse(
      JSON.stringify(this.state.secondaryCategoryDisinterest)
    );
    if (!checked) {
      secondaryCategoryDisinterest.list.push(
        category.parentCategory + " / " + category.categoryName
      );
      if (secondaryCategoryDisinterest.message === "") {
        if (this.props.language === "EN") {
          secondaryCategoryDisinterest.message =
            "You will recieve less news from these categories : " +
            category.displayName;
        } else {
          secondaryCategoryDisinterest.message =
            "നിങ്ങൾക്ക് അത്ര താൽപര്യമില്ലാത്ത വിഭാഗങ്ങൾ : " +
            category.displayName;
        }
      } else {
        secondaryCategoryDisinterest.message += ", " + category.displayName;
      }
    } else {
      secondaryCategoryDisinterest.list =
        secondaryCategoryDisinterest.list.filter(
          (i) => i !== category.parentCategory + " / " + category.categoryName
        );
      if (secondaryCategoryDisinterest.list.length === 0) {
        secondaryCategoryDisinterest.message = "";
      } else {
        secondaryCategoryDisinterest.message =
          secondaryCategoryDisinterest.message.replace(
            category.displayName,
            ""
          );
      }
    }
    this.setState({
      secondaryCategoryDisinterest: secondaryCategoryDisinterest,
    });
  };

  //
  // Mark disinterest for secondary categories
  //
  saveSecondaryCategoryDisinterest = async () => {
    // Slide to next item
    this.props.secondarySwiper.slideNext();

    if (this.state.secondaryCategoryDisinterest.list.length > 0) {
      // Get current user pref from local storage
      let userPreferences = JSON.parse(
        localStorage.getItem("userPreferences") || "{}"
      );
      // Iterate over disinterested categories
      this.state.secondaryCategoryDisinterest.list.forEach((category) => {
        window.gtag("event", "event_not_interested_click_" + category);
        // Update selected category score to 0
        userPreferences[category] = userPreferences[category] || {};
        userPreferences[category]["count"] = 0;
        userPreferences[category]["score"] = 1;
        // Unsubscribe from topic
        let formattedTopic = category.replaceAll(" ", "_").replaceAll("/", "_");
        window.unsubscribeTopic(formattedTopic);
      });
      // Update storage
      localStorage.setItem("userPreferences", JSON.stringify(userPreferences));
    }

    // Close modal
    this.props.toggleInfoModal(false);
  };

  // Save user details
  saveUserDetails = (event) => {
    window.gtag("event", "save_user_details");
    // Stop the form from submitting and refreshing the page
    event.preventDefault();
    // Get values from form inputs
    const userDetails = {
      name: event.target.name.value,
      contact: event.target.contact.value,
    };
    // Update local storage
    localStorage.setItem("user-details", JSON.stringify(userDetails));
    // Update global state
    this.props.updateGlobalStateValue("userDetails", userDetails);
    // Close modal
    this.props.toggleInfoModal(false);
  };

  render() {
    let textContent =
      this.props.language === "EN"
        ? {
            themeSettingHeader: "Theme",
            themeSettingDesc: "Select your preferred color theme.",
            themeSettingLightMode: "Light Mode",
            themeSettingDarkMode: "Dark Mode",
            langSettingHeader: "Language",
            langSettingDesc: "Select language for settings",
            ctaBtnOk: "Okay",
            ctaBtnContinue: "Continue",
            ctaBtnThanks: "Thanks",
            appUpdateTitle: "NEW UPDATE AVAILABLE",
            appUpdateDesc:
              "You are using an old version of the app. For new features and experience, update the app now.",
            appUpdateBtn: "Update Now",
            featureLockedMsg:
              "This is a premium Feature. Unlock Katha Premium to enjoy this.",
            featureLockedBtn: "Know More",
            unDesc: "Please enter your name to be shown in leaderboard.",
            unInputPlaceholder: "Enter username here",
            unInvalidAlert:
              "Invalid characters (Only letters, numbers and underscore are allowed).",
            saveBtn: "Save",
          }
        : {
            themeSettingHeader: "കളർ തീം (ഡാർക്ക് മോഡ്)",
            themeSettingDesc: "നിങ്ങളുടെ അഭിരുചിക്കനുസരിച്ച് തീം മാറ്റുക.",
            themeSettingLightMode: "ലൈറ്റ് മോഡ്",
            themeSettingDarkMode: "ഡാർക്ക് മോഡ്",
            langSettingHeader: "ഭാഷ",
            langSettingDesc: "സെറ്റിംഗ്സ് പേജിനായുള്ള ഭാഷ തിരഞ്ഞെടുക്കുക",
            ctaBtnOk: "ശരി",
            ctaBtnContinue: "തുടരാം",
            ctaBtnThanks: "നന്ദി",
            appUpdateTitle: "പുതിയ ഫീച്ചറുകൾ ലഭ്യമാണ്",
            appUpdateDesc:
              "നിങ്ങളുടെ ആപ്പ് പഴയ വേർഷൻ ആണ്. മികവുറ്റ അനുഭവത്തിനായും ഏറ്റവും പുതിയ ഫീച്ചറുകളും ലഭിക്കാൻ വേണ്ടി ഇപ്പോൾ തന്നെ അപ്ഡേറ്റ് ചെയ്യുക.",
            appUpdateBtn: "അപ്ഡേറ്റ് ചെയ്യുക",
            featureLockedMsg:
              "ഇതൊരു പ്രീമിയം ഫീച്ചറാണ്. ഈ ഫീച്ചർ അനുഭവിച്ചറിയാൻ കഥ പ്രീമിയം അൺലോക്ക് ചെയ്യുക.",
            featureLockedBtn: "കൂടുതലറിയാം",
            unDesc: "ലീഡർബോർഡിൽ കാണിക്കുന്നതിനു വേണ്ടി നിങ്ങളുടെ പേര് നൽകുക.",
            unInputPlaceholder: "പേര് ഇവിടെ നൽകുക",
            unInvalidAlert:
              "Invalid characters (Only letters, numbers and underscore are allowed).",
            saveBtn: "സേവ് ചെയ്യുക",
          };

    return (
      <div className="absolute h-screen z-30 w-screen top-0 flex flex-col bg-[#000000cc]">
        <div
          className="grow"
          onClick={() => {
            this.props.toggleInfoModal(false);
          }}
        ></div>
        <div className="info-modal text-ltp dark:text-dtp translate-y-full transition-transform bg-lbp dark:bg-dbp rounded-t border-t border-lbr dark:border-dbr relative">
          <span
            className="bg-lbs dark:bg-dbs p-2 rounded-t border border-lbr dark:border-dbr absolute -translate-y-full right-4 text-lts dark:text-dts"
            onClick={() => {
              this.props.toggleInfoModal(false);
            }}
          >
            <svg
              viewBox="0 0 24 24"
              width="24"
              height="24"
              stroke="currentColor"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </span>

          {/* Theme setting  */}
          {this.props.conf.contentFlag === "theme_setting" && (
            <div className="p-4">
              <div className="text-lg font-bold mb-2">
                {textContent.themeSettingHeader}
              </div>
              <div className="text-lts dark:text-dts mb-4">
                {textContent.themeSettingDesc}
              </div>
              <div className="flex items-center justify-center">
                <span
                  className="bg-lbs text-ltp px-4 py-2 font-bold rounded-full flex items-center"
                  onClick={() => this.toggleTheme("light")}
                >
                  {textContent.themeSettingLightMode}
                  {this.props.colorTheme === "light" && (
                    <span className="text-accent ml-2">
                      <svg
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                        <polyline points="22 4 12 14.01 9 11.01"></polyline>
                      </svg>
                    </span>
                  )}
                </span>
                <span
                  className="bg-dbs text-dtp px-4 py-2 font-bold rounded-full flex items-center ml-4"
                  onClick={() => this.toggleTheme("dark")}
                >
                  {textContent.themeSettingDarkMode}
                  {this.props.colorTheme === "dark" && (
                    <span className="text-accent ml-2">
                      <svg
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                        <polyline points="22 4 12 14.01 9 11.01"></polyline>
                      </svg>
                    </span>
                  )}
                </span>
              </div>
            </div>
          )}

          {/* Language setting  */}
          {this.props.conf.contentFlag === "lang_setting" && (
            <div className="p-4">
              <div className="text-lg font-bold mb-2">
                {textContent.langSettingHeader}
              </div>
              <div className="text-lts dark:text-dts mb-4">
                {textContent.langSettingDesc}
              </div>
              <div className="flex items-center justify-center">
                <span
                  className="bg-lbs dark:bg-dbs px-4 py-2 font-bold rounded-full flex items-center"
                  onClick={() => this.toggleLang("ML")}
                >
                  മലയാളം
                  {this.props.language === "ML" && (
                    <span className="text-accent ml-2">
                      <svg
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                        <polyline points="22 4 12 14.01 9 11.01"></polyline>
                      </svg>
                    </span>
                  )}
                </span>
                <span
                  className="bg-lbs dark:bg-dbs px-4 py-2 font-bold rounded-full flex items-center ml-4"
                  onClick={() => this.toggleLang("EN")}
                >
                  English
                  {this.props.language === "EN" && (
                    <span className="text-accent ml-2">
                      <svg
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                        <polyline points="22 4 12 14.01 9 11.01"></polyline>
                      </svg>
                    </span>
                  )}
                </span>
              </div>
            </div>
          )}

          {/* App update alert */}
          {this.props.conf.contentFlag === "app_update_alert" && (
            <div className="p-4">
              <div className="text-lg font-bold mb-2">
                {textContent.appUpdateTitle}
              </div>
              <div className="text-lts dark:text-dts mb-4">
                {textContent.appUpdateDesc}
              </div>
              <div
                className="p-4 rounded-full bg-accent text-dtp font-bold"
                onClick={() => {
                  this.props.toggleInfoModal(false);
                  window.open(
                    "https://play.google.com/store/apps/details?id=today.katha&referrer=utm_source%3Dapp_update"
                  );
                }}
              >
                {textContent.appUpdateBtn}
              </div>
            </div>
          )}

          {/* More options for news items */}
          {this.props.conf.contentFlag === "news_more_options" && (
            <div className="p-4">
              <div
                className="flex items-center text-accent pb-4 border-b border-lbr dark:border-dbr"
                onClick={() => {
                  this.onReadMoreClick("news");
                }}
              >
                <span className="mr-2">
                  <svg
                    viewBox="0 0 24 24"
                    width="16"
                    height="16"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                    <polyline points="15 3 21 3 21 9"></polyline>
                    <line x1="10" y1="14" x2="21" y2="3"></line>
                  </svg>
                </span>
                <span>
                  {this.props.language === "EN"
                    ? "Read More"
                    : "പൂർണ്ണരൂപം വായിച്ചറിയാം"}
                </span>
              </div>
              <div
                className="flex items-center text-accent py-4 border-b border-lbr dark:border-dbr"
                onClick={() => {
                  this.onGenericShareClick("news");
                }}
              >
                <span className="mr-2">
                  <svg
                    viewBox="0 0 24 24"
                    width="16"
                    height="16"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <circle cx="18" cy="5" r="3"></circle>
                    <circle cx="6" cy="12" r="3"></circle>
                    <circle cx="18" cy="19" r="3"></circle>
                    <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line>
                    <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line>
                  </svg>
                </span>
                <span>
                  {this.props.language === "EN" ? "Share to" : "ഷെയർ ചെയ്യാം"}
                </span>
              </div>
              <div
                className="flex items-center text-accent py-4 border-b border-lbr dark:border-dbr"
                onClick={() => {
                  this.bookmarkItem("news");
                }}
              >
                <span className="mr-2">
                  <svg
                    viewBox="0 0 24 24"
                    width="16"
                    height="16"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path>
                  </svg>
                </span>
                <span>
                  {this.props.language === "EN"
                    ? "Bookmark"
                    : "ബുക്ക്മാർക്ക് ചെയ്യാം"}
                </span>
              </div>
              {this.getSimilarSecondaryCategories().length > 0 && (
                <div
                  className="flex items-center text-accent py-4 border-b border-lbr dark:border-dbr"
                  onClick={() => {
                    this.props.toggleInfoModal(true, "NEWS_ITEM_DISINTEREST");
                  }}
                >
                  <span className="mr-2">
                    <svg
                      viewBox="0 0 24 24"
                      width="16"
                      height="16"
                      stroke="currentColor"
                      strokeWidth="2"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <circle cx="12" cy="12" r="10"></circle>
                      <line x1="4.93" y1="4.93" x2="19.07" y2="19.07"></line>
                    </svg>
                  </span>
                  <span>
                    {this.props.language === "EN"
                      ? "Not interested"
                      : "ഇത്തരം വാർത്തകളിൽ താൽപര്യമില്ല"}
                  </span>
                </div>
              )}
              <div
                className="flex items-center text-accent pt-4"
                onClick={() => {
                  this.onShareFeedbackClick("news");
                }}
              >
                <span className="mr-2">
                  <svg
                    viewBox="0 0 24 24"
                    width="16"
                    height="16"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                  </svg>
                </span>
                <span>
                  {this.props.language === "EN"
                    ? "Send Feedback"
                    : "നിർദ്ദേശങ്ങൾ രേഖപ്പെടുത്താം"}
                </span>
              </div>
            </div>
          )}

          {/* More options for job items */}
          {this.props.conf.contentFlag === "job_more_options" && (
            <div className="p-4">
              <div
                className="flex items-center text-accent pb-4 border-b border-lbr dark:border-dbr"
                onClick={() => {
                  this.onGenericShareClick("job");
                }}
              >
                <span className="mr-2">
                  <svg
                    viewBox="0 0 24 24"
                    width="16"
                    height="16"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <circle cx="18" cy="5" r="3"></circle>
                    <circle cx="6" cy="12" r="3"></circle>
                    <circle cx="18" cy="19" r="3"></circle>
                    <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line>
                    <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line>
                  </svg>
                </span>
                <span>
                  {this.props.language === "EN" ? "Share to" : "ഷെയർ ചെയ്യാം"}
                </span>
              </div>
              <div
                className="flex items-center text-accent pt-4"
                onClick={() => {
                  this.onShareFeedbackClick("job");
                }}
              >
                <span className="mr-2">
                  <svg
                    viewBox="0 0 24 24"
                    width="16"
                    height="16"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                  </svg>
                </span>
                <span>
                  {this.props.language === "EN"
                    ? "Send Feedback"
                    : "നിർദ്ദേശങ്ങൾ രേഖപ്പെടുത്താം"}
                </span>
              </div>
            </div>
          )}

          {/* Notification setting  */}
          {this.props.conf.contentFlag === "disable_battery_opt" && (
            <div className="p-4">
              <div className="text-lg font-bold mb-2">
                {this.props.language === "EN"
                  ? "Notifications"
                  : "നോട്ടിഫിക്കേഷൻ"}
              </div>
              <div className="text-lts dark:text-dts mb-4">
                {this.props.language === "EN"
                  ? "Please disable battery optimization for Katha to recieve notifications properly."
                  : "കൂടുതൽ കൃത്യതയോടെ നോട്ടിഫിക്കേഷൻ ലഭിക്കാനായി ഫോണിൻ്റെ ബാറ്ററി ഒപ്റ്റിമൈസേഷൻ ഓഫ് ചെയ്യുക."}
              </div>
              <div
                className="p-4 rounded-full bg-accent text-dtp font-bold"
                onClick={() => {
                  this.props.toggleInfoModal(false);
                  window.gtag("event", "event_modal_battery_opt_accept");
                  window.triggerBatteryOptimization();
                }}
              >
                {this.props.language === "EN" ? "Okay" : "ശരി"}
              </div>
            </div>
          )}

          {/* More options for district news items */}
          {this.props.conf.contentFlag === "district_news_more_options" && (
            <div className="p-4">
              <div
                className="flex items-center text-accent pb-4 border-b border-lbr dark:border-dbr"
                onClick={() => {
                  this.onReadMoreClick("news");
                }}
              >
                <span className="mr-2">
                  <svg
                    viewBox="0 0 24 24"
                    width="16"
                    height="16"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                    <polyline points="15 3 21 3 21 9"></polyline>
                    <line x1="10" y1="14" x2="21" y2="3"></line>
                  </svg>
                </span>
                <span>
                  {this.props.language === "EN"
                    ? "Read More"
                    : "പൂർണ്ണരൂപം വായിച്ചറിയാം"}
                </span>
              </div>
              <div
                className="flex items-center text-accent py-4 border-b border-lbr dark:border-dbr"
                onClick={() => {
                  this.onWhatsAppShareClick("district_news");
                }}
              >
                <span className="mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    enableBackground="new 0 0 24 24"
                    height="16"
                    viewBox="0 0 24 24"
                    width="16"
                    fill="currentColor"
                  >
                    <g>
                      <rect fill="none" height="24" width="24" y="0" />
                      <rect fill="none" height="24" width="24" y="0" />
                    </g>
                    <g>
                      <g>
                        <g>
                          <path d="M19.05,4.91C17.18,3.03,14.69,2,12.04,2c-5.46,0-9.91,4.45-9.91,9.91c0,1.75,0.46,3.45,1.32,4.95L2.05,22l5.25-1.38 c1.45,0.79,3.08,1.21,4.74,1.21h0c0,0,0,0,0,0c5.46,0,9.91-4.45,9.91-9.91C21.95,9.27,20.92,6.78,19.05,4.91z M12.04,20.15 L12.04,20.15c-1.48,0-2.93-0.4-4.2-1.15l-0.3-0.18l-3.12,0.82l0.83-3.04l-0.2-0.31c-0.82-1.31-1.26-2.83-1.26-4.38 c0-4.54,3.7-8.24,8.24-8.24c2.2,0,4.27,0.86,5.82,2.42c1.56,1.56,2.41,3.63,2.41,5.83C20.28,16.46,16.58,20.15,12.04,20.15z M16.56,13.99c-0.25-0.12-1.47-0.72-1.69-0.81c-0.23-0.08-0.39-0.12-0.56,0.12c-0.17,0.25-0.64,0.81-0.78,0.97 c-0.14,0.17-0.29,0.19-0.54,0.06c-0.25-0.12-1.05-0.39-1.99-1.23c-0.74-0.66-1.23-1.47-1.38-1.72c-0.14-0.25-0.02-0.38,0.11-0.51 c0.11-0.11,0.25-0.29,0.37-0.43c0.12-0.14,0.17-0.25,0.25-0.41c0.08-0.17,0.04-0.31-0.02-0.43c-0.06-0.12-0.56-1.34-0.76-1.84 c-0.2-0.48-0.41-0.42-0.56-0.43C8.86,7.33,8.7,7.33,8.53,7.33c-0.17,0-0.43,0.06-0.66,0.31C7.65,7.89,7.01,8.49,7.01,9.71 c0,1.22,0.89,2.4,1.01,2.56c0.12,0.17,1.75,2.67,4.23,3.74c0.59,0.26,1.05,0.41,1.41,0.52c0.59,0.19,1.13,0.16,1.56,0.1 c0.48-0.07,1.47-0.6,1.67-1.18c0.21-0.58,0.21-1.07,0.14-1.18S16.81,14.11,16.56,13.99z" />
                        </g>
                      </g>
                    </g>
                  </svg>
                </span>
                <span>
                  {this.props.language === "EN"
                    ? "Share on WhatsApp"
                    : "വാട്സാപ്പിൽ ഷെയർ ചെയ്യാം"}
                </span>
              </div>
              <div
                className="flex items-center text-accent py-4 border-b border-lbr dark:border-dbr"
                onClick={() => {
                  this.onGenericShareClick("district_news");
                }}
              >
                <span className="mr-2">
                  <svg
                    viewBox="0 0 24 24"
                    width="16"
                    height="16"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <circle cx="18" cy="5" r="3"></circle>
                    <circle cx="6" cy="12" r="3"></circle>
                    <circle cx="18" cy="19" r="3"></circle>
                    <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line>
                    <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line>
                  </svg>
                </span>
                <span>
                  {this.props.language === "EN" ? "Share to" : "ഷെയർ ചെയ്യാം"}
                </span>
              </div>
              <div
                className="flex items-center text-accent py-4 border-b border-lbr dark:border-dbr"
                onClick={() => {
                  this.props.toggleInfoModal(false);
                  this.props.toggleDistrictSelection(true);
                }}
              >
                <span className="mr-2">
                  <svg
                    viewBox="0 0 24 24"
                    width="16"
                    height="16"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <circle cx="12" cy="12" r="3"></circle>
                    <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                  </svg>
                </span>
                <span>
                  {this.props.language === "EN"
                    ? "Change District"
                    : "ജില്ല തിരഞ്ഞെടുക്കാം"}
                </span>
              </div>
              <div
                className="flex items-center text-accent pt-4"
                onClick={() => {
                  this.onShareFeedbackClick("news");
                }}
              >
                <span className="mr-2">
                  <svg
                    viewBox="0 0 24 24"
                    width="16"
                    height="16"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                  </svg>
                </span>
                <span>
                  {this.props.language === "EN"
                    ? "Send Feedback"
                    : "നിർദ്ദേശങ്ങൾ രേഖപ്പെടുത്താം"}
                </span>
              </div>
            </div>
          )}

          {/* For marking disinterest in categories */}
          {this.props.conf.contentFlag === "NEWS_ITEM_DISINTEREST" && (
            <div className="p-4">
              <div className="font-bold text-lg mb-2">
                {this.props.language === "EN"
                  ? "Improve Feed"
                  : "വാർത്താ ഫീഡ് മെച്ചപ്പെടുത്താം"}
              </div>
              <div className="text-lts dark:text-dts mb-2">
                {this.props.language === "EN"
                  ? "Please deselect the categories that you are not interested in."
                  : "നിങ്ങൾക്ക് താൽപര്യമില്ലാത്ത തരം വാർത്തകൾ കുറയ്ക്കാം."}
              </div>
              <div className="border border-lbr dark:border-dbr rounded p-4 w-full space-y-4">
                {this.getSimilarSecondaryCategories().map((category) => (
                  <div key={category.categoryName} className="flex">
                    <div className="grow text-left">{category.displayName}</div>
                    <Toggle
                      id={"NID_" + category.categoryName}
                      icons={false}
                      checked={
                        !this.state.secondaryCategoryDisinterest.list.includes(
                          category.parentCategory +
                            " / " +
                            category.categoryName
                        )
                      }
                      onChange={(event) => {
                        this.changeSecondaryCategoryDisinterest(
                          category,
                          event.target.checked
                        );
                      }}
                    />
                  </div>
                ))}
                {this.state.secondaryCategoryDisinterest.message !== "" && (
                  <div className="text-lts dark:text-dts text-sm border-t border-lbr dark:border-dbr pt-2">
                    {this.state.secondaryCategoryDisinterest.message}
                  </div>
                )}
              </div>
              <div
                className="bg-accent text-dtp w-full mt-4 rounded-full p-4 font-bold"
                onClick={() => {
                  this.saveSecondaryCategoryDisinterest();
                }}
              >
                {this.props.language === "EN" ? "Save" : "തുടരാം"}
              </div>
              <div
                className="mt-4 text-accent font-bold flex items-center"
                onClick={() => {
                  this.props.toggleInfoModal(false);
                  this.props.toggleCategoryInterest(true);
                }}
              >
                <span className="mr-2">
                  <svg
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <circle cx="12" cy="12" r="3"></circle>
                    <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                  </svg>
                </span>
                <span className="text-left">
                  {this.props.language === "EN"
                    ? "Manage Categories"
                    : "മറ്റ് താൽപര്യമുള്ള വിഭാഗങ്ങളിൽ ഭേദഗതി വരുത്താം"}
                </span>
              </div>
            </div>
          )}

          {/* Quiz info  */}
          {this.props.conf.contentFlag === "QUIZ_COUNTDOWN" && (
            <div className="p-4">
              <div className="text-lg font-bold mb-2">
                {this.props.language === "EN" ? "Daily Quiz" : "ഡെയ്‌ലി ക്വിസ്"}
              </div>
              <div className="text-lts dark:text-dts mb-4">
                {this.props.language === "EN"
                  ? "The session for today has ended. The quiz is available every day from 6PM until 12PM the following day. Please return in the evening for the following session."
                  : "ഇന്നത്തെ സെഷൻ അവസാനിച്ചു. ക്വിസ് എല്ലാ ദിവസവും വൈകുന്നേരം 6 മണി മുതൽ അടുത്ത ദിവസം 12 മണി വരെ ലഭ്യമാണ്. അടുത്ത സെഷനിൽ പങ്കെടുക്കാൻ  ദയവായി വൈകുന്നേരം മടങ്ങി വരുക."}
              </div>
              <div
                className="p-4 rounded-full bg-accent text-dtp font-bold"
                onClick={() => {
                  this.props.toggleInfoModal(false);
                }}
              >
                {this.props.language === "EN" ? "Okay" : "ശരി"}
              </div>
            </div>
          )}

          {/* User details input */}
          {this.props.conf.contentFlag === "USER_DETAILS_INPUT" && (
            <div className="p-4">
              <div className="text-ltp dark:text-dtp mb-4">
                {this.props.language === "EN"
                  ? "Please enter the following details."
                  : "താഴെ ആവശ്യപ്പെട്ടിരിക്കുന്ന വിവരങ്ങൾ നൽകുക."}
              </div>
              <form onSubmit={this.saveUserDetails}>
                <div className="flex flex-col mb-4">
                  <div className="rounded bg-lbs dark:bg-dbs flex flex-col">
                    <input
                      type="text"
                      name="name"
                      required
                      placeholder="Enter your name..."
                      defaultValue={
                        this.props.userDetails
                          ? this.props.userDetails.name
                          : ""
                      }
                      className="rounded bg-lbs dark:bg-dbs p-4 outline-none"
                    />
                  </div>
                  <span className="text-lts dark:text-dts text-xs mt-2">
                    {this.props.language === "EN"
                      ? "This will be used to display your position on the leaderboard."
                      : "ലീഡർ ബോർഡിൽ താങ്കളുടെ നില കാണിക്കാൻ ഈ വിവരങ്ങൾ ഉപയോഗിക്കും."}
                  </span>
                </div>
                <div className="flex flex-col mb-4">
                  <div className="rounded bg-lbs dark:bg-dbs flex flex-col">
                    <input
                      type="tel"
                      name="contact"
                      required
                      placeholder="Enter your contact number..."
                      defaultValue={
                        this.props.userDetails
                          ? this.props.userDetails.contact
                          : ""
                      }
                      className="rounded bg-lbs dark:bg-dbs p-4 outline-none"
                    />
                  </div>
                  <span className="text-lts dark:text-dts text-xs mt-2">
                    {this.props.language === "EN"
                      ? "This will be used to contact you if you have won the prize. Please ensure that it is a number that can be reached via WhatsApp or phone call."
                      : "താങ്കൾ ഈ മത്സരത്തിൽ ജയിച്ചാൽ താങ്കളെ ബന്ധപ്പെടാൻ ഈ വിവരങ്ങളാണ് ഉപയോഗിക്കുക."}
                  </span>
                </div>
                <button
                  className="p-4 rounded-full bg-accent text-dtp font-bold w-full"
                  type="submit"
                >
                  {this.props.language === "EN" ? "Save" : "Save"}
                </button>
              </form>
              <div className="text-red text-xs mt-4 flex items-center text-left">
                <FiAlertTriangle />
                <span className="ml-2">
                  {this.props.language === "EN"
                    ? "Note : Changing your number will reset all your data."
                    : "നിങ്ങളുടെ ഫോൺ നമ്പർ മാറ്റുന്നത് നിങ്ങളുടെ സ്കോർ പുനഃസജ്ജമാക്കും."}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default InfoModal;
