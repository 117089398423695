import React, {Component} from "react";

// Assets 
import endCardImg from '../../../assets/end_card_img.svg';

class EndCard extends Component {
    render() {
        const {
            item
        } = this.props;

        return (
            <div className='h-full border border-lbr dark:border-dbr flex flex-col rounded bg-lbp dark:bg-dbp'>
                <div className="grow flex items-center justify-center">
                    <div className="px-4">
                        <img className="w-3/4 mx-auto" src={endCardImg} alt=''/>
                        <div className="text-ltp dark:text-dtp mt-4">
                            {item.endCardMessage && <>
                                {item.endCardMessage}
                            </>}
                            {!item.endCardMessage && <>
                                {this.getEndCardText()} {this.props.language==='EN' ? 'To continue reading, swipe up.' : 'കൂടുതൽ വായിക്കാൻ മുകളിലോട്ട് സ്വൈപ്പ് ചെയ്യുക.'}
                            </>}
                        </div>
                    </div>
                </div>
                <div className="flex justify-center text-accent pb-4 animate-bounce" onClick={()=>{this.props.secondarySwiper.slideNext()}}>
                    <span>
                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round"><polyline points="17 11 12 6 7 11"></polyline><polyline points="17 18 12 13 7 18"></polyline></svg>
                    </span>
                </div>
            </div>
        );
    }

    getEndCardText() {
        if(this.props.language==='ML') {
            // Get text based on selected category 
            switch (this.props.category) {
                case 'KL01':
                    return 'തിരുവനന്തപുരം കാറ്റഗറിയിലെ എല്ലാ വാർത്തകളും അറിഞ്ഞു കഴിഞ്ഞിരിക്കുന്നു.'
                case 'KL02':
                    return 'കൊല്ലം കാറ്റഗറിയിലെ എല്ലാ വാർത്തകളും അറിഞ്ഞു കഴിഞ്ഞിരിക്കുന്നു.'
                case 'KL03':
                    return 'പത്തനംതിട്ട കാറ്റഗറിയിലെ എല്ലാ വാർത്തകളും അറിഞ്ഞു കഴിഞ്ഞിരിക്കുന്നു.'
                case 'KL04':
                    return 'ആലപ്പുഴ കാറ്റഗറിയിലെ എല്ലാ വാർത്തകളും അറിഞ്ഞു കഴിഞ്ഞിരിക്കുന്നു.'
                case 'KL05':
                    return 'കോട്ടയം കാറ്റഗറിയിലെ എല്ലാ വാർത്തകളും അറിഞ്ഞു കഴിഞ്ഞിരിക്കുന്നു.'
                case 'KL06':
                    return 'ഇടുക്കി കാറ്റഗറിയിലെ എല്ലാ വാർത്തകളും അറിഞ്ഞു കഴിഞ്ഞിരിക്കുന്നു.'
                case 'KL07':
                    return 'എറണാകുളം കാറ്റഗറിയിലെ എല്ലാ വാർത്തകളും അറിഞ്ഞു കഴിഞ്ഞിരിക്കുന്നു.'
                case 'KL08':
                    return 'തൃശ്ശൂർ കാറ്റഗറിയിലെ എല്ലാ വാർത്തകളും അറിഞ്ഞു കഴിഞ്ഞിരിക്കുന്നു.'
                case 'KL09':
                    return 'പാലക്കാട് കാറ്റഗറിയിലെ എല്ലാ വാർത്തകളും അറിഞ്ഞു കഴിഞ്ഞിരിക്കുന്നു.'
                case 'KL10':
                    return 'മലപ്പുറം കാറ്റഗറിയിലെ എല്ലാ വാർത്തകളും അറിഞ്ഞു കഴിഞ്ഞിരിക്കുന്നു.'
                case 'KL11':
                    return 'കോഴിക്കോട് കാറ്റഗറിയിലെ എല്ലാ വാർത്തകളും അറിഞ്ഞു കഴിഞ്ഞിരിക്കുന്നു.'
                case 'KL12':
                    return 'വയനാട് കാറ്റഗറിയിലെ എല്ലാ വാർത്തകളും അറിഞ്ഞു കഴിഞ്ഞിരിക്കുന്നു.'
                case 'KL13':
                    return 'കണ്ണൂർ കാറ്റഗറിയിലെ എല്ലാ വാർത്തകളും അറിഞ്ഞു കഴിഞ്ഞിരിക്കുന്നു.'
                case 'KL14':
                    return 'കാസർഗോഡ് കാറ്റഗറിയിലെ എല്ലാ വാർത്തകളും അറിഞ്ഞു കഴിഞ്ഞിരിക്കുന്നു.'
                case 'Kerala':
                    return 'കേരള കാറ്റഗറിയിലെ എല്ലാ വാർത്തകളും അറിഞ്ഞു കഴിഞ്ഞിരിക്കുന്നു.'
                case 'National':
                    return 'ദേശീയ പ്രസക്തിയുള്ള എല്ലാ വാർത്തകളും അറിഞ്ഞു കഴിഞ്ഞിരിക്കുന്നു.'
                case 'World':
                    return 'അന്താരാഷ്ട്ര തലത്തിലുള്ള എല്ലാ വാർത്തകളും അറിഞ്ഞു കഴിഞ്ഞിരിക്കുന്നു.'
                case 'Positive':
                    return 'എല്ലാ നല്ല വാർത്തകളും അറിഞ്ഞു കഴിഞ്ഞിരിക്കുന്നു.'
                case 'Viral':
                    return 'കൗതുകപരമായ എല്ലാ വാർത്തകളും അറിഞ്ഞു കഴിഞ്ഞിരിക്കുന്നു.'
                case 'Gulf':
                    return 'എല്ലാ ഗൾഫ് വാർത്തകളും അറിഞ്ഞു കഴിഞ്ഞിരിക്കുന്നു.'
                case 'Business':
                    return 'വ്യാപാര സംബന്ധമായ എല്ലാ വാർത്തകളും അറിഞ്ഞു കഴിഞ്ഞിരിക്കുന്നു.'
                case 'Entertainment':
                    return 'എല്ലാ വിനോദ വാർത്തകളും അറിഞ്ഞു കഴിഞ്ഞിരിക്കുന്നു.'
                case 'Tech':
                    return 'എല്ലാ ടെക്  വാർത്തകളും അറിഞ്ഞു കഴിഞ്ഞിരിക്കുന്നു.'
                case 'Sports':
                    return 'എല്ലാ കായിക വാർത്തകളും അറിഞ്ഞു കഴിഞ്ഞിരിക്കുന്നു.'
                default:
                    return 'ഈ കാറ്റഗറിയിൽ ഉള്ള എല്ലാ കഥകളും വായിച്ചു കഴിഞ്ഞു.'
            }
        } else {
            // Get text based on selected category 
            switch (this.props.category) {
                case 'KL01':
                    return 'You have covered all the news from your district.'
                case 'KL02':
                    return 'You have covered all the news from your district.'
                case 'KL03':
                    return 'You have covered all the news from your district.'
                case 'KL04':
                    return 'You have covered all the news from your district.'
                case 'KL05':
                    return 'You have covered all the news from your district.'
                case 'KL06':
                    return 'You have covered all the news from your district.'
                case 'KL07':
                    return 'You have covered all the news from your district.'
                case 'KL08':
                    return 'You have covered all the news from your district.'
                case 'KL09':
                    return 'You have covered all the news from your district.'
                case 'KL10':
                    return 'You have covered all the news from your district.'
                case 'KL11':
                    return 'You have covered all the news from your district.'
                case 'KL12':
                    return 'You have covered all the news from your district.'
                case 'KL13':
                    return 'You have covered all the news from your district.'
                case 'KL14':
                    return 'You have covered all the news from your district.'
                case 'National':
                    return 'You have covered all the national news.'
                case 'World':
                    return 'You have covered all the international news.'
                case 'Positive':
                    return 'You have covered all the news in the positive category.'
                case 'Viral':
                    return 'You have covered all the news in the viral category.'
                case 'Gulf':
                    return 'You have covered all the news in the gulf category.'
                case 'Business':
                    return 'You have covered all the news in the business category.'
                case 'Entertainment':
                    return 'You have covered all the news in the entertainment category.'
                case 'Tech':
                    return 'You have covered all the news in the technology category.'
                case 'Sports':
                    return 'You have covered all the news in the sports category.'
                default:
                    return 'You have covered all the news in the selected category.'
            }
        }
    }
}

export default EndCard;