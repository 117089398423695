import React, { useEffect, useState } from "react";
import { FiArrowLeft, FiEdit, FiEdit2, FiHelpCircle, FiUser } from "react-fi";
import quizService from "../../../services/quizService";

const Leaderboard = (props) => {
  // Find current UTC month
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ][new Date().getUTCMonth()];

  return (
    <div className="bg-lbs dark:bg-dbs text-ltp dark:text-dtp absolute top-0 w-screen h-screen text-left z-10">
      <div
        className="h-[8vh] flex items-center border-b border-lbr dark:border-dbr sticky top-0 px-4 text-accent bg-lbp dark:bg-dbp"
        onClick={() => {
          props.toggleLeaderboardOverlay(false);
        }}
      >
        <span>
          <FiArrowLeft />
        </span>
        <span className="text-ltp dark:text-dtp font-bold grow text-center">
          {props.language === "EN" ? "Leaderboard" : "ലീഡർബോർഡ്"}
        </span>
        <span className="opacity-0">
          <FiArrowLeft />
        </span>
      </div>

      <div className="h-[92vh] overflow-auto">
        <div
          className="my-4 px-4 flex items-center w-full"
          onClick={() => props.toggleInfoModal(true, "USER_DETAILS_INPUT")}
        >
          <span className="text-lts dark:text-dts mr-2">
            <FiUser />
          </span>
          <span className="flex flex-col grow">
            <span>{props.userDetails.name}</span>
            <span className="text-sm text-lts dark:text-dts">
              {props.userDetails.contact}
            </span>
          </span>
          <span className="text-accent">
            <FiEdit />
          </span>
        </div>

        {props.leaderboard.top_10_week.length > 0 && (
          <div className="px-4 my-4">
            <div className="bg-lbp dark:bg-dbp rounded w-full py-4 border border-lbr dark:border-dbr">
              <div className="font-bold px-4 pb-4 flex justify-center items-center">
                <span>{props.language === "EN" ? "Weekly" : "ഈ ആഴ്ച"}</span>
                <span className="text-lts dark:text-dts ml-2 font-normal">
                  (Sun-Sat)
                </span>
              </div>
              <div className="px-4">
                <table className="table-auto w-full bg-lbs dark:bg-dbs rounded-sm text-sm text-center overflow-hidden">
                  <tbody className="divide-y divide-lbp dark:divide-dbp">
                    {props.leaderboard.top_10_week.map((user, index) => (
                      <tr
                        key={"quiz-leaderboard-entry-week-" + index}
                        className={
                          user.phone === props.userDetails.contact
                            ? "bg-accent"
                            : ""
                        }
                      >
                        <td
                          className={
                            "p-2 " +
                            (user.phone === props.userDetails.contact
                              ? "text-dtp"
                              : "text-ltp dark:text-dtp opacity-50")
                          }
                        >
                          #{index + 1}
                        </td>
                        <td
                          className={
                            "p-2 max-w-[40vw] whitespace-nowrap overflow-hidden text-ellipsis " +
                            (user.phone === props.userDetails.contact
                              ? "text-dtp"
                              : "text-ltp dark:text-dtp")
                          }
                        >
                          {user.name}
                        </td>
                        <td
                          className={
                            "p-2 " +
                            (user.phone === props.userDetails.contact
                              ? "text-dtp"
                              : "text-accent")
                          }
                        >
                          {user.score}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="mt-4 flex justify-center items-center">
                <span className="mr-1 text-lts dark:text-dts">
                  {props.language === "EN"
                    ? "Your score :"
                    : "നിങ്ങളുടെ സ്കോർ :"}
                </span>
                <span className="text-accent">
                  {props.leaderboard.week.score}
                </span>
                {props.leaderboard.rank.week !== 0 && (
                  <span className="ml-1 text-lts dark:text-dts opacity-50">
                    (#{props.leaderboard.rank.week})
                  </span>
                )}
              </div>
              <div className="mt-4 text-center border-t border-lbr dark:border-dbr pt-4">
                <span className="text-lts dark:text-dts">
                  {props.language === "EN"
                    ? "Last week's winner :"
                    : "കഴിഞ്ഞ ആഴ്ചയിലെ വിജയി :"}
                </span>
                <br />
                <span className="text-accent">
                  🏅 {props.leaderboard.top_1_previous_week.name}
                </span>
              </div>
            </div>
          </div>
        )}

        {props.leaderboard.top_10_month.length > 0 && (
          <div className="px-4 my-4">
            <div className="bg-lbp dark:bg-dbp rounded w-full py-4 border border-lbr dark:border-dbr">
              <div className="font-bold px-4 pb-4 flex justify-center items-center">
                <span>{props.language === "EN" ? "Monthly" : "ഈ മാസം"}</span>
                <span className="text-lts dark:text-dts ml-2 font-normal">
                  ({month})
                </span>
              </div>
              <div className="px-4">
                <table className="table-auto w-full bg-lbs dark:bg-dbs rounded-sm text-sm text-center overflow-hidden">
                  <tbody className="divide-y divide-lbp dark:divide-dbp">
                    {props.leaderboard.top_10_month.map((user, index) => (
                      <tr
                        key={"quiz-leaderboard-entry-month-" + index}
                        className={
                          user.phone === props.userDetails.contact
                            ? "bg-accent"
                            : ""
                        }
                      >
                        <td
                          className={
                            "p-2 " +
                            (user.phone === props.userDetails.contact
                              ? "text-dtp"
                              : "text-ltp dark:text-dtp opacity-50")
                          }
                        >
                          #{index + 1}
                        </td>
                        <td
                          className={
                            "p-2 max-w-[40vw] whitespace-nowrap overflow-hidden text-ellipsis " +
                            (user.phone === props.userDetails.contact
                              ? "text-dtp"
                              : "text-ltp dark:text-dtp")
                          }
                        >
                          {user.name}
                        </td>
                        <td
                          className={
                            "p-2 " +
                            (user.phone === props.userDetails.contact
                              ? "text-dtp"
                              : "text-accent")
                          }
                        >
                          {user.score}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="mt-4 flex justify-center items-center">
                <span className="mr-1 text-lts dark:text-dts">
                  {props.language === "EN"
                    ? "Your score :"
                    : "നിങ്ങളുടെ സ്കോർ :"}
                </span>
                <span className="text-accent">
                  {props.leaderboard.month.score}
                </span>
                {props.leaderboard.rank.month !== 0 && (
                  <span className="ml-1 text-lts dark:text-dts opacity-50">
                    (#{props.leaderboard.rank.month})
                  </span>
                )}
              </div>
              <div className="mt-4 text-center border-t border-lbr dark:border-dbr pt-4">
                <span className="text-lts dark:text-dts">
                  {props.language === "EN"
                    ? "Last month's winners :"
                    : "കഴിഞ്ഞ മാസത്തെ വിജയികൾ :"}
                </span>
                <br />
                <span className="text-accent">
                  🥇 {props.leaderboard.top_3_previous_month[0].name}, 🥈{" "}
                  {props.leaderboard.top_3_previous_month[1].name}, 🥉{" "}
                  {props.leaderboard.top_3_previous_month[2].name},
                </span>
              </div>
            </div>
          </div>
        )}

        <div className="p-4 rounded m-4 bg-gradient-to-tl from-[#9a7a3b] to-[#ddb800] text-dtp">
          <div className="font-bold mb-2">
            {props.language === "EN"
              ? "Competition Prizes"
              : "മത്സര സമ്മാനങ്ങൾ"}
          </div>
          <ul className="text-sm list-disc pl-4">
            <li>
              {props.language === "EN"
                ? "You can now win prizes by playing quiz."
                : "ക്വിസ് കളിച്ച് നിങ്ങൾക്ക് ഇപ്പോൾ സമ്മാനങ്ങൾ നേടാം."}
            </li>
            <li>
              {props.language === "EN"
                ? "Top performer of each week will get an Amazon gift card worth Rs.100. You can win the weekly prize only once per month."
                : "എല്ലാ ആഴ്ചയിലും ഒന്നാം സ്ഥാനത്ത് എത്തുന്നവർക്ക് 100 രൂപ വിലമതിക്കുന്ന ആമസോൺ ഗിഫ്റ്റ് കാർഡ് സമ്മാനമായി ലഭിക്കും. ഒരു മാസത്തിൽ ഒരു തവണ മാത്രമേ ഒരാൾക്ക് ആഴ്ചയിലെ വിജയി ആകാൻ സാധിക്കു."}
            </li>
            <li>
              {props.language === "EN"
                ? "Top three performers of each month will get an even better prize. 1st - Rs.500, 2nd - Rs.400, 3rd - Rs.300. All will be in the form of Amazon gift cards."
                : "എല്ലാ മാസവും ആദ്യ മൂന്ന് സ്ഥാനങ്ങൾ നേടുന്നവർക്കും സമ്മാനങ്ങൾ (ആമസോൺ ഗിഫ്റ്റ് കാർഡ്). ഒന്നാം സമ്മാനം - 500 രൂപ, രണ്ടാം സ്ഥാനം - 400 രൂപ, മൂന്നാം സ്ഥാനം - 300 രൂപ."}
            </li>
          </ul>
        </div>

        <div className="m-4 text-center text-sm text-lts dark:text-dts">
          {props.language === "EN"
            ? "Reach out to +917907741997 if you have any issues or doubts."
            : "നിങ്ങൾക്ക് എന്തെങ്കിലും പ്രശ്‌നങ്ങളോ സംശയങ്ങളോ ഉണ്ടെങ്കിൽ +917907741997 എന്ന നമ്പറിൽ ബന്ധപ്പെടുക."}
        </div>
      </div>
    </div>
  );
};

export default Leaderboard;
