import React, {useEffect, useState} from "react";

const Toast = (props) => {

    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (!!props.message) {

            window.gtag('event', 'toast_show_' + props.message.flag);

            // Show toast 
            setVisible(true);
            
            // Calculate hide interval for diff messages 
            let hideDelay = 0;
            switch (props.message.flag) {
                case 'FEED_REFRESH_LOOP':
                    hideDelay = 4000;
                    break;
                default:
                    hideDelay = 2000;
                    break;
            }

            // Hide after delay 
            setTimeout(function () {
                setVisible(false);
                props.updateToastMessage(null);
            }, hideDelay);

        }
    }, [props.message]);

    //
    // Feed refresh
    //
    const backToFeed = () => {
        window.gtag('event', 'toast_click_feed_refresh');
        // Close toast 
        setVisible(false);
        props.updateToastMessage(null);
        // Close overlays 
        props.updateGlobalStateValue('showJobsOverlay', false);
        // Refesh feed 
        props.handleCategorySelection(null, '', '');
    }

    return (
        <>
            <div  className={"fixed z-30 transition-all duration-500" + (visible ? ' bottom-0' : ' bottom-[-8vh]')}>
                {props.message && <>

                    {props.message.flag==='unread_count_reset' && <div className="text-dtp h-[8vh] w-screen flex items-center justify-center bg-gradient-to-b from-[#2ab1ba] to-[#0396ff]">
                        {props.message.content}
                    </div>}

                    {props.message.flag==='BOOKMARK_SUCCESS' && <div className="text-dtp h-[8vh] w-screen flex items-center justify-center bg-gradient-to-b from-[#2ab1ba] to-[#0396ff]">
                        {props.message.content}
                    </div>}

                    {props.message.flag==='FEED_REFRESH_LOOP' && <div className="text-dtp h-[8vh] w-screen flex items-center justify-center bg-gradient-to-b from-[#2ab1ba] to-[#0396ff] px-4 text-sm" onClick={()=>backToFeed()}>
                        <span className="mr-2">
                            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round"><polyline points="23 4 23 10 17 10"></polyline><polyline points="1 20 1 14 7 14"></polyline><path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path></svg>
                        </span>
                        <span>
                            {props.message.content}
                        </span>
                    </div>}

                </>}
            </div>
        </>
    );
};

export default Toast;