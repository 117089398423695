//
// List of all item categories with respective secondary categories
//
export const itemCategories = [{
        categoryName: 'National',
        secondaryCategories: ['National Politics', 'National Crime']
    },
    {
        categoryName: 'Kerala',
        secondaryCategories: ['Kerala Crime', 'Kerala Politics'],
    },
    {
        categoryName: 'Sports',
        secondaryCategories: ['Cricket', 'Football'],
    },
    {
        categoryName: 'Business',
        secondaryCategories: ['Startups', 'Stock Market', 'Indian Economy', 'World Economy', 'Personal Finance', 'Crypto'],
    },
    {
        categoryName: 'Gulf',
        secondaryCategories: ['UAE', 'Saudi', 'Oman', 'Kuwait', 'Qatar'],
    },
    {
        categoryName: 'World',
        secondaryCategories: ['World Politics', 'World Economy'],
    },
    {
        categoryName: 'Tech',
        secondaryCategories: ['Electronics', 'Automobile', 'Science'],
    },
    {
        categoryName: 'Entertainment',
        secondaryCategories: ['Mollywood', 'Bollywood', 'Music', 'Movies', 'Masala'],
    }
]

//
// Generate display name for secondary category
//
export const getSecondaryCategoryDisplayName = (categoryName, language) => {
    if (language === 'ML') {
        switch (categoryName) {
            case "National Politics":
                return "ഇന്ത്യൻ രാഷ്ട്രീയം";
            case "National Crime":
                return "ഇന്ത്യൻ ക്രൈം";
            case "Kerala Crime":
                return "കേരള ക്രൈം";
            case "Kerala Politics":
                return "കേരള രാഷ്ട്രീയം";
            case "Cricket":
                return "ക്രിക്കറ്റ്‌";
            case "Football":
                return "ഫുട്ബോൾ";
            case "Olympics":
                return "ഒളിമ്പിക്സ്";
            case "Startups":
                return "സ്റ്റാർട്ടപ്പ്‌";
            case "Stock Market":
                return "ഓഹരി വിപണി";
            case "Indian Economy":
                return "ഇന്ത്യൻ ഇക്കോണമി";
            case "World Economy":
                return "ലോക ഇക്കോണമി";
            case "Personal Finance":
                return "വ്യക്തിഗത ഫിനാൻസ്";
            case "Crypto":
                return "ക്രിപ്റ്റോ";
            case "UAE":
                return "യു.എ.ഇ.";
            case "Saudi":
                return "സൗദി";
            case "Oman":
                return "ഒമാൻ";
            case "Kuwait":
                return "കുവൈറ്റ്‌";
            case "Pravasi":
                return "പ്രവാസി";
            case "Qatar":
                return "ഖത്തർ";
            case "World Politics":
                return "ലോക രാഷ്ട്രീയം";
            case "Electronics":
                return "ഇലക്ട്രോണിക്";
            case "Automobile":
                return "വാഹനം";
            case "Science":
                return "ശാസ്ത്രം";
            case "Mollywood":
                return "മോളിവുഡ്";
            case "Bollywood":
                return "ബോളിവുഡ്";
            case "Music":
                return "മ്യൂസിക്";
            case "Movies":
                return "സിനിമ";
            case "Masala":
                return "സിനി-വിശേഷം";
            default:
                return categoryName;
        }
    } else {
        return categoryName;
    }
}

//
// Generate unique secondary category names
//
export const getUniqueSecondaryCategories = () => {
    let uniqueCategories = [];
    // Iterate over main categories 
    itemCategories.forEach(category => {
        // Iterate over secondary categories 
        category.secondaryCategories.forEach(secondaryCategory => {
            // Avoid duplicates 
            if(!uniqueCategories.some(i=>i===secondaryCategory)) {
                uniqueCategories.push(secondaryCategory);
            }
        });
    });
    return uniqueCategories;
}

export default {
    itemCategories,
    getSecondaryCategoryDisplayName,
    getUniqueSecondaryCategories
};