import React, { Component } from "react";
import localStorage from "../../../services/localStorage";

class PosterCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLiked: false, // Whether liked or not
    };
  }

  componentDidMount() {
    // Check saved flags
    let postersData = JSON.parse(localStorage.getItem("posters-data"));
    if (!postersData) {
      postersData = {
        liked: [],
      };
      localStorage.setItem("posters-data", JSON.stringify(postersData));
    } else {
      // Check if liked or not
      if (postersData.liked.some((i) => i === this.props.item.uid)) {
        this.setState({ isLiked: true });
      }
    }
  }

  //
  // Share card on whatsapp
  //
  shareCard() {
    window.gtag("event", "event_poster_share");
    window.gtag("event", "event_poster_share_" + this.props.item.uid);
    //   Create share message
    let shareMsg = "*" + this.props.item.title + "*\n\n";
    shareMsg +=
      "കൂടുതൽ ട്രെൻഡിങ് വാർത്തകൾക്കും ചിത്രങ്ങൾക്കുമായി *കഥ ആപ്പ്* ഡൗൺലോഡ് ചെയ്യാം:\n";
    shareMsg += "https://link.katha.today/download";
    // Start sharing
    window.shareScreenshot(this.props.item.image_link, shareMsg);
  }

  //
  // Like the poster
  //
  onLikeClick() {
    // Update flag in local storage
    let postersData = JSON.parse(localStorage.getItem("posters-data"));
    if (postersData.liked.some((i) => i === this.props.item.uid)) {
      postersData.liked = postersData.liked.filter(
        (i) => i !== this.props.item.uid
      );
      this.setState({ isLiked: false });
    } else {
      postersData.liked.push(this.props.item.uid);
      this.setState({ isLiked: true });
      window.gtag("event", "poster_like");
      window.gtag("event", "poster_like_" + this.props.item.uid);
    }
    localStorage.setItem("posters-data", JSON.stringify(postersData));
  }

  render() {
    const { item } = this.props;

    // Text based on selected language
    let textContent =
      this.props.language === "EN"
        ? {
            shareBtn: "Share",
          }
        : {
            shareBtn: "ഷെയർ",
          };

    return (
      <div className="h-full border border-lbr dark:border-dbr flex flex-col rounded bg-lbp dark:bg-dbp overflow-hidden relative">
        <div className="text-center p-2 text-accent">Trendshots</div>
        <div className="bg-dbs grow relative">
          <img
            className="object-contain z-0 object-center h-full w-full"
            src={item.image_link}
          />
        </div>
        <div className="bg-lbp dark:bg-dbp w-full p-4">
          <div className="text-ltp dark:text-dtp font-bold text-lg mb-4">
            {item.title}
          </div>
          <div className="flex">
            <div
              className={
                "grow p-4 font-bold text-dtp flex items-center justify-center rounded-full " +
                (this.state.isLiked ? "bg-green" : "bg-accent")
              }
              onClick={() => this.onLikeClick()}
            >
              <svg
                viewBox="0 0 24 24"
                width="24"
                height="24"
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3"></path>
              </svg>
              <div style={{ paddingLeft: ".5rem" }}>
                {this.props.language === "EN" ? "Like" : "ലൈക്ക്"}
              </div>
            </div>
            <div
              className="grow ml-4 bg-accent p-4 font-bold text-dtp flex items-center justify-center rounded-full"
              onClick={() => this.shareCard()}
            >
              <svg
                viewBox="0 0 24 24"
                width="24"
                height="24"
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <circle cx="18" cy="5" r="3"></circle>
                <circle cx="6" cy="12" r="3"></circle>
                <circle cx="18" cy="19" r="3"></circle>
                <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line>
                <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line>
              </svg>
              <div style={{ paddingLeft: ".5rem" }}>{textContent.shareBtn}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PosterCard;
