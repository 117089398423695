const EXPIRY_PREFIX = 'EXPIRY_';

const getExpiryPrefix = (key) => EXPIRY_PREFIX + key;

export const setItem = (key, value, expiryMS=Infinity) => {
    localStorage.setItem(key, value);
    localStorage.setItem(getExpiryPrefix(key), Date.now() + expiryMS);
};

export const getItem = (key) => {
    const expiryTime = localStorage.getItem(getExpiryPrefix(key));
    if (expiryTime && !isNaN(expiryTime) && expiryTime > Date.now()) {
        return localStorage.getItem(key);
    } else if (expiryTime) {
        removeItem(key);
    }

    return null;
};

export const removeItem = (key) => {
    localStorage.removeItem(key);
    localStorage.removeItem(getExpiryPrefix(key));
};

export const updateExpiry = (key, expiryMS) => {
    localStorage.setItem(getExpiryPrefix(key), Date.now() + expiryMS);
};

export default { setItem, getItem, removeItem, updateExpiry };