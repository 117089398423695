import React, { Component, Suspense } from "react";

import "./App.css";
import itemsService from "./services/itemsService";
import localStorage from "./services/localStorage";
import SwiperCore, { Virtual } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { FiLoader, FiRefreshCw } from "react-icons/fi";
import { Provider, ErrorBoundary } from "@rollbar/react";
import {
  MIN_REQ_VERSION_NUMBER,
  MIN_REQ_VERSION_ENABLE,
} from "./config/environment";
import { getUniqueSecondaryCategories } from "./config/item-categories";

// Components
import Loading from "./components/Loading";
import TopNav from "./components/Widgets/TopNav";
import BottomNav from "./components/Widgets/BottomNav";
import InfoModal from "./components/Widgets/InfoModal";
import Feed from "./components/Feed";
import DistrictSelection from "./components/DistrictSelection";
import Toast from "./components/Widgets/Toast";
import Quiz from "./components/Quiz";
const PositiveTab = React.lazy(() => import("./components/PositiveTab"));
const ExtrasTab = React.lazy(() => import("./components/ExtrasTab"));
const Categories = React.lazy(() => import("./components/Categories"));
const Settings = React.lazy(() => import("./components/Settings"));
const JobsTab = React.lazy(() => import("./components/JobsTab"));
const CategoryInterestSelection = React.lazy(() =>
  import("./components/CategoryInterestSelection")
);
const Overlays = React.lazy(() => import("./components/Overlays"));

// Install Virtual module
SwiperCore.use([Virtual]);

let newsReadTimer = new Date();

let sessionLevel0Count = Math.floor(Math.random() * 5 + 16);
let sessionLevel1Count = Math.floor(Math.random() * 3 + 7);

// Flag to indicate first session or not
let firstSession = false;

// Rollbar project config
const rollbarConfig = {
  accessToken: "11799bcf2e134e4dbe76f6fe7c1d888c",
  environment: "prod",
};

function App() {
  return (
    <Router>
      <Route path="/" exact component={Home} />
    </Router>
  );
}

class Home extends Component {
  constructor(props) {
    super(props);

    // Find language
    let language = localStorage.getItem("lang");
    if (language == null) {
      language = "ML";
    }

    // Find color theme
    let colorTheme = localStorage.getItem("color_theme");
    if (colorTheme === null) {
      colorTheme = "light";
      localStorage.setItem("color_theme", "light");
    } else if (colorTheme === "dark") {
      // Add the class name to body
      document.body.classList.add("dark");
    } else if (colorTheme === "light-theme") {
      colorTheme = "light";
      localStorage.setItem("color_theme", "light");
    } else if (colorTheme === "dark-theme") {
      colorTheme = "dark";
      localStorage.setItem("color_theme", "dark");
    }

    this.state = {
      newsItems: [],
      districtItems: [], //  List of news items in selected district
      loading: true,
      // viewportUid: null,
      category: null,
      categoryNameML: null,
      categoryNameEN: null,
      uniqueCategories: {},
      selectedDistrict: "",
      audioMuted: false,
      timerPlaying: false,
      hasUIDInPath: false,
      videoShareInProgress: false,
      automaticMode: false,
      appFirstOpen: true,
      unreadNewsCount: 0,
      showCategoryInterestSelection: false, //  Whether to show category interest selection or not
      showDistrictSelection: false, //  Whether to show district selection or not
      showSettingsPage: false, //  Whether to show settings or not
      categoryInterestConfig: {}, //  Category interest i.e. whether to show a particular category or not
      isCategoryInterestUpdated: false, //  Flag to keep track whether category interest was changed or not
      infoModalConf: {}, //  Configuration for info modal
      abTestCategory: "", //  AB testing state. Whether category 'A' or 'B' based on GTAG id
      disableNav: false, //  Whether navigation is disabled or not
      primarySwiper: {}, //  Main swiper instance
      secondarySwiper: {}, //  Secondary/virtual swiper instance
      secondarySwiperInitSlide: 0, //  Initial slide count for secondary swiper
      showTopBar: true, //  Whether to show topbar or not
      language: language, //  Language for secondary texts
      showQuiz: false, //  Show quiz overlay or not
      pinChains: {},
      isAndroidWebView: true, //  Whether android or not
      nonWVCounter: 1, //  Counter for redirection
      devModeEnabled: false, //  Whether developer mode is enabled or not
      colorTheme: colorTheme, //  Whether dark or light
      appInBackground: false,
      activeJobItem: {}, //  Selected jobs tab item for info modal ref
      positiveTabAudioPlaying: false, //  Whether audio is playing in positive tab or not
      guideStatus: {
        readmoreTooltip: true,
        playTooltip: true,
        swipeLeft: true,
      }, //  Whether each guide has been show or not
      isFeedAudioLoading: false, //  Whether audio for feed is getting loaded or not
      currentSessionLevel: 0,
      toastMessage: null,
      toastConfetti: false,
      activeOverlay: "", //  Active overlay
      subscribedTopics: [], //  List of subscribed topics
      activePositiveItem: null, //  Currently selected positive item
      isJobSearchOpen: false, //  Whether job search overlay is open or not
      topTenHighlights: [], // Highlights
      screenViewHeight: 0, // View height of the screen
    };
  }

  androidOpenURL = async (item) => {
    if (item.link.match(/\?./)) {
      window.androidOpenURL(item.link + "&utm_source=katha-app");
    } else {
      window.androidOpenURL(item.link + "?utm_source=katha-app");
    }
  };

  jumpToTop = async () => {
    // Update id in state
    window.global__viewportUid = this.state.newsItems[0].uid;

    newsReadTimer = new Date();

    // Update view
    this.nextUpdates(0, -1);
    this.setCurrentAudio(this.state.newsItems[0]);
    // Move to top
    this.slideToIndex(0);
  };

  // Jump to a particular news item
  jumpToItem = async (uid) => {
    // Move back to feed
    this.onPrimarySwiperChange(1, 0);
    newsReadTimer = new Date();
    // Get the index of item from list
    let index = this.state.newsItems.findIndex((item) => item.uid === uid);
    if (index >= 0) {
      // Update state and audio
      window.global__viewportUid = uid;
      // this.setState({viewportUid: uid});
      await this.setCurrentAudio(this.state.newsItems[index]);
      // Move to item
      this.slideToIndex(index);
    }
  };

  // Jump to a item by index
  slideToIndex(index) {
    if (this.state.secondarySwiper.activeIndex !== undefined) {
      this.state.secondarySwiper.slideTo(index);
    }
  }

  async loadNewsItems(withLoading = true, hasUidInPath = false) {
    // Get the existing news items (previously loaded) from local storage
    let localStorageNewsItems = JSON.parse(
      localStorage.getItem("newsItems") || "[]"
    );

    // Get the category interest selection from local storage
    let categoryInterestConfig = JSON.parse(
      localStorage.getItem("category_interest")
    );
    // Handle first time configuration of category interest
    if (categoryInterestConfig == null) {
      // Initialize
      categoryInterestConfig = {};
    }
    // Generate list of customizable categories
    const customizableCategories = [
      "ALL",
      "World",
      "Business",
      "Entertainment",
      "Tech",
      "Sports",
      "Gulf",
      "Health",
    ].concat(
      getUniqueSecondaryCategories().filter(
        (i) =>
          ![
            "National Crime",
            "Kerala Crime",
            "Indian Economy",
            "World Economy",
            "Personal Finance",
            "UAE",
            "Saudi",
            "Oman",
            "Kuwait",
            "Qatar",
            "Music",
            "Movies",
            "Masala",
          ].includes(i)
      )
    );
    // Check if all fields are there or not
    // For handling new category additions
    for (let category of customizableCategories) {
      // If field not found, set to default
      if (categoryInterestConfig[category] === undefined) {
        categoryInterestConfig[category] = true;
      }
    }
    // Update storage
    localStorage.setItem(
      "category_interest",
      JSON.stringify(categoryInterestConfig)
    );

    localStorage.removeItem("feed-setting");

    this.setState({
      infoContent: null,
      loading: withLoading && true,
      categoryInterestConfig: categoryInterestConfig,
    });

    // Check if news items are available
    let fetchFromServer = true;
    let checksum = localStorage.getItem("items_checksum");
    if (!!checksum) {
      let latestAvailable = await itemsService.latestAvailable(checksum);
      if (!latestAvailable) {
        fetchFromServer = false;
      }
    }
    let latestNewsItems = [];
    if (fetchFromServer) {
      // Get latest news items from API
      const data = await itemsService.list();
      latestNewsItems = data.items;
      localStorage.setItem("items_checksum", data.checksum);
    } else {
      latestNewsItems = JSON.parse(JSON.stringify(localStorageNewsItems));
    }

    // Get Active Pins
    const pinsData = await itemsService.pins();
    if (!!pinsData.data) {
      this.setState({ pinUids: pinsData.data.map((x) => x.uid) });
    }

    // Itearate over latest items and check if they are already present in the previous set
    // If so update the values for the item and save it back in local storage
    // If not add the item as itself
    let result = [];
    latestNewsItems.forEach((newsItem) => {
      // Find index i.e. return -1 if the item is not present
      let localStorageNewsItemIndex = localStorageNewsItems.findIndex(
        (x) => x.uid === newsItem.uid
      );
      if (localStorageNewsItemIndex >= 0) {
        let oldItem = localStorageNewsItems[localStorageNewsItemIndex];
        newsItem = { ...oldItem, ...newsItem };
      }
      result.push(newsItem);
    });

    // List of item IDs to be filtered based on category interest
    let itemsToBeRemoved = [];
    // Iterate through news items and filter the ones having non enabled categories
    result.forEach((item) => {
      if (item.categories) {
        // Iterate over categories and see if at least one is enabled
        for (let category of item.categories) {
          if (this.state.categoryInterestConfig[category] === false) {
            itemsToBeRemoved.push(item.uid);
            break;
          }
        }
      }
      if (item.secondary_categories) {
        // Iterate over categories and see if at least one is enabled
        for (let category of item.secondary_categories) {
          if (
            category.split(" / ").length > 1 &&
            this.state.categoryInterestConfig[category.split(" / ")[1]] ===
              false
          ) {
            itemsToBeRemoved.push(item.uid);
            break;
          }
        }
      }
    });
    // Iterate over and filter
    itemsToBeRemoved.forEach((item) => {
      result = result.filter((i) => i.uid !== item);
    });

    // Create a mapping of the categories with the number of news items in each category
    let uniqueCategories = await this.uniqueCategories(result);

    // Calculate the day diff i.e. the number of days from first session
    let userCreateDate = localStorage.getItem("userCreateDate");
    let daysDiff = 0;
    if (userCreateDate !== null) {
      daysDiff = Math.abs(
        parseInt(
          (new Date() - new Date(parseInt(userCreateDate))) /
            (1000 * 60 * 60 * 24),
          10
        )
      );
    }

    // Filter out posters for first 24 hours
    if (daysDiff === 0) {
      result = result.filter(
        (i) => i.categories && !i.categories.includes("Poster")
      );
    }

    // Sort the items
    result = await this.sort(result);

    // Shuffle as per Active Pins
    result = await this.pinShuffle(result);

    // Update local storage
    await localStorage.setItem("newsItems", JSON.stringify(result));

    // Filter out hidden items and stories older than 24 hours
    let dateYesterday = new Date(new Date().setDate(new Date().getDate() - 1));
    result = result.filter(
      (i) =>
        i.categories &&
        !i.categories.includes("HFF") &&
        (!i.categories.includes("Stories") ||
          new Date(i.enabled_at) > dateYesterday)
    );

    // Find the trending items
    let trendingItems = this.generateTrendingNews(result);

    // Add Unread Completion Card
    if (this.state.currentSessionLevel === 0) {
      let unreadCompletionItem = {
        uid: "unread_completion",
        item_type: "unread_completion_card",
        audio_link: "https://storage.katha.today/wBfCLWoJXXUtnHeQVzCv5i8R",
        title: "Unread Completion",
        total_unread_count: sessionLevel0Count + sessionLevel1Count,
      };
      result.splice(
        sessionLevel0Count + sessionLevel1Count - 2,
        0,
        unreadCompletionItem
      );
    }

    // Check whether first timer or not
    if (firstSession && this.state.guideStatus.playTooltip) {
      // Reorder trending items
      // The 10 items at starting should be the trending ones
      trendingItems.forEach((item) => {
        result = result.filter((i) => i.uid !== item.uid);
      });
      result = trendingItems.concat(result);

      // Add category interest item at pos 10
      let categoryInterestItem = {
        uid: "category_interest_card",
        item_type: "category_interest_card",
        audio_link:
          "https://storage.katha.today/aEuy5kygexrLJ3jbE9Y2SzpA?id=211993",
        title: "ഇഷ്ടപെട്ട വാർത്തകൾ തിരഞ്ഞെടുക്കാം.",
      };
      result.splice(10, 0, categoryInterestItem);

      // Hide top nav
      setTimeout(() => {
        this.setState({ showTopBar: false });
      }, 500);
    } else {
      // Add an intro card
      // It shows 5 news items from trending
      let introItem = {
        uid: "intro_card",
        item_type: "intro_card",
        audio_link: "",
        intro_trending_items: trendingItems,
      };
      result.splice(0, 0, introItem);
    }

    // Check whether the review card was shown or not
    // let reviewUsCardShown = await localStorage.getItem("reviewUsCardShown");
    // let reviewUsCardAddedToFeed = false;
    // // If not, add the item at pos 15
    // if (
    //   !reviewUsCardShown ||
    //   reviewUsCardShown === "false" ||
    //   reviewUsCardShown === 0
    // ) {
    //   let reviewUsItem = {
    //     uid: "review_card",
    //     item_type: "review_us_card",
    //     audio_link:
    //       "https://storage.katha.today/KNb4wMwYdHYTSMGfh42DFsf8?id=210637",
    //     title: "കഥ ആപ്പ് 5 ⭐ റേറ്റ് ചെയ്യാം.",
    //   };
    //   result.splice(15, 0, reviewUsItem);
    //   reviewUsCardAddedToFeed = true;
    // }

    // Check whether the share card was shown or not
    // let shareUsCardShown = await localStorage.getItem("shareUsCardShown");
    // // If not, check the time from first usage. If it exceeds 3 days, add the card as 10th item in the list
    // if (
    //   (!shareUsCardShown ||
    //     shareUsCardShown === "false" ||
    //     shareUsCardShown === 0) &&
    //   !!userCreateDate &&
    //   !reviewUsCardAddedToFeed
    // ) {
    //   if (daysDiff >= 3) {
    //     let shareUsItem = {
    //       uid: "share_card",
    //       item_type: "share_us_card",
    //       audio_link: "https://storage.katha.today/QAjXPzzajTvQWB3qnihyCf4G",
    //       title: "കഥ ആപ്പ് ഷെയർ ചെയ്യാം.",
    //     };
    //     result.splice(10, 0, shareUsItem);
    //   }
    // }

    // Add the quiz entry card at 4th pos after 6PM to midnight (not available for first 24 hours)
    // const d = new Date(
    //   new Date().toLocaleString("en-US", { timeZone: "Asia/Calcutta" })
    // );
    // if (daysDiff > 0 && d.getHours() >= 18) {
    //   // Check if quiz has been already attended
    //   const quizData = JSON.parse(localStorage.getItem("quizData"));
    //   // Enable quiz if time is greater than 6:30 PM
    //   if (quizData !== null) {
    //     // Calculate hours difference
    //     let hoursDiff = Math.abs(
    //       parseInt(
    //         (new Date() - new Date(parseInt(quizData.lastAttendedTime))) /
    //           (1000 * 60 * 60),
    //         10
    //       )
    //     );
    //     // Check hour diff on basis of current time
    //     if (d.getHours() >= 18 && hoursDiff > d.getHours() - 12) {
    //       result.splice(4, 0, {
    //         uid: "quiz_entry_card",
    //         item_type: "quiz_entry_card",
    //         audio_link:
    //           "https://storage.katha.today/F6XCcZBzHHZBmNvoFkxZjsF1?id=209983",
    //         title: "ഇന്നത്തെ ക്വിസിൽ പങ്കെടുക്കാം!",
    //       });
    //     }
    //   } else {
    //     result.splice(4, 0, {
    //       uid: "quiz_entry_card",
    //       item_type: "quiz_entry_card",
    //       audio_link:
    //         "https://storage.katha.today/F6XCcZBzHHZBmNvoFkxZjsF1?id=209983",
    //       title: "ഇന്നത്തെ ക്വിസിൽ പങ്കെടുക്കാം!",
    //     });
    //   }
    // }

    // if (daysDiff > 0) {
    //   // Show unizone card at 12th pos
    //   result.splice(12, 0, {
    //     uid: "seasonal_card",
    //     item_type: "seasonal_card",
    //     audio_link: "",
    //     title: "വാട്സ്ആപ്പ് സ്റ്റാറ്റസിലൂടെ പരസ്യങ്ങൾ ഷെയർ ചെയ്യൂ, പണം നേടൂ.",
    //   });
    // }

    // Update states and start loading
    this.setState(
      {
        newsItems: result,
        uniqueCategories: uniqueCategories,
        category: null,
      },
      () => {
        // Move to top
        this.jumpToTop();
        // Stop loader
        if (!hasUidInPath) {
          this.setState({ loading: false });
        }
      }
    );
  }

  // Find the trending items
  generateTrendingNews(allItems) {
    let newsItems = JSON.parse(JSON.stringify(allItems));
    // Filter news from last 12 hours and non-special, non-games, non-stories and non-posters
    newsItems = newsItems.filter(
      (i) =>
        Math.abs(new Date() - new Date(i.enabled_at)) / 36e5 <= 12 &&
        !i.categories.includes("Special") &&
        !i.categories.includes("Games") &&
        !i.categories.includes("Poster") &&
        !i.categories.includes("Stories")
    );
    // Sort by interaction score
    newsItems.sort((a, b) => b.interaction_score - a.interaction_score);
    // Filter upto 10 items
    if (newsItems.length > 10) {
      newsItems = newsItems.slice(0, 10);
    }
    // Sort by enabled time
    newsItems.sort((a, b) =>
      new Date(a.enabled_at) > new Date(b.enabled_at) ? -1 : 1
    );
    // Return
    return newsItems;
  }

  async pinShuffle(items) {
    const activePinUids = this.state.pinUids;
    if (!activePinUids) {
      return items;
    }

    // filter active pin items
    activePinUids.forEach((activePinUid) => {
      // let pinnedItems = items.filter(x => x.pin_chain_id === activePinUid);
      // pinnedItems = pinnedItems.concat(pinnedItems);
    });

    let pinnedItems = [];
    let nonPinnedItems = [];
    let _pinChains = {};
    items.forEach((item) => {
      if (activePinUids.includes(item.pin_chain_uid)) {
        if (!item["read"]) {
          pinnedItems.push(item);
          if (!_pinChains[item.pin_chain_uid]) {
            _pinChains[item.pin_chain_uid] = {};
          }
          _pinChains[item.pin_chain_uid][item.uid] = false;
        }
      } else {
        nonPinnedItems.push(item);
      }
    });

    this.setState({
      pinChains: _pinChains,
    });

    pinnedItems.sort((a, b) =>
      a.pin_chain_position > b.pin_chain_position ? 1 : -1
    );
    pinnedItems.forEach((pinnedItem) => {
      nonPinnedItems.splice(pinnedItem.pin_chain_position - 1, 0, pinnedItem);
    });

    return nonPinnedItems;
  }

  // Sort the news items based on timestamp
  async sort(localStorageNewsItems) {
    // List of non read items
    let resultNonRead = [];
    // List of already read items
    let resultRead = [];
    // To be shown in category page
    let unreadNewsCount = 0;
    let dateYesterday = new Date(new Date().setDate(new Date().getDate() - 1));
    // Iterate over the items and divide them
    localStorageNewsItems.forEach((item) => {
      // If the item is a poster (full card image), update the item type to 'poster_card'
      if (!!item.categories && item.categories.includes("Poster")) {
        item.item_type = "poster_card";
      }

      if (item.read) {
        resultRead.push(item);
      } else {
        resultNonRead.push(item);
        if (new Date(item.enabled_at) > dateYesterday) {
          if (firstSession) {
            unreadNewsCount += 1;
          } else {
            if (unreadNewsCount < sessionLevel0Count) {
              unreadNewsCount += 1;
            }
          }
        }
      }
    });

    // Update state of unread news count
    if (this.state.unreadNewsCount !== unreadNewsCount) {
      this.setState({ unreadNewsCount: unreadNewsCount });
    }

    // Sort based on timestamp field
    // resultNonRead.sort((a, b) => new Date(a.enabled_at) > new Date(b.enabled_at) ? -1 : 1);

    // Sort based on User Preferences
    const userPreferences = JSON.parse(
      (await localStorage.getItem("userPreferences")) || "{}"
    );
    if (Object.keys(userPreferences).length > 0) {
      resultNonRead.forEach((item) => {
        let _score = 0;
        item.categories.forEach((category) => {
          if (!!userPreferences[category]) {
            _score += userPreferences[category]["score"] - 1;
          }
        });

        if (!!item.secondary_categories) {
          item.secondary_categories.forEach((secondaryCategory) => {
            if (!!userPreferences[secondaryCategory]) {
              _score += userPreferences[secondaryCategory]["score"] - 1;
            }
          });
        }
        // const milliseconds = Math.abs(dateTwoObj - dateOneObj);
        // const hours = milliseconds / 36e5;
        // if (hours > 24) {
        //     setTimestamp('few days ago');
        // }
        let freshnessScore = 0.1;
        let hoursAgo = Math.abs(new Date() - new Date(item.enabled_at)) / 36e5;
        if (hoursAgo <= 24) {
          freshnessScore = 1 - (0.9 * hoursAgo) / 24;
        }
        item["score"] =
          (_score + 1) * freshnessScore + item.interaction_score / 500;
      });

      resultNonRead.sort((a, b) => (a["score"] > b["score"] ? -1 : 1));
    }

    // Move 25 unread highlights to top
    let unreadHighlights = [];
    resultNonRead.forEach((item) => {
      if (unreadHighlights.length < 25 && item.Highlight) {
        unreadHighlights.push(item);
      }
    });
    unreadHighlights.forEach((item) => {
      resultNonRead = resultNonRead.filter((i) => i.uid !== item.uid);
    });
    resultNonRead = unreadHighlights.concat(resultNonRead);

    // Append the non-read items and read items. Non read ones comes first
    return resultNonRead.concat(resultRead);
  }

  // Check if the URL parameters have a UID
  // Happens while opening the app to view a particular news
  async hasUIDInPath() {
    const query = new URLSearchParams(window.location.search);
    const uid = query.get("uid");
    return !!uid;
  }

  // Handles the item to be shown if the URL contains a UID
  async setCurrentIndexForUID(passedUid) {
    // Get the UID from parameters
    const query = new URLSearchParams(window.location.search);
    const uid = passedUid || query.get("uid");
    const tab = query.get("tab");

    // UID to alert app update
    if (!!uid && uid === "appUpdate") {
      this.setState({ loading: false });
      this.toggleInfoModal(true, "app_update_alert");
      return;
    }

    if (!!uid && uid === "apps") {
      this.setState({ loading: false });
      return;
    }

    if (!!tab) {
      if (tab === "positive") {
        // Go to positive tab
        setTimeout(async () => {
          this.setState({ loading: false });
          this.onPrimarySwiperChange(2, 1);
        }, 1000);
      } else if (tab === "jobs") {
        // Go to jobs tab
        setTimeout(async () => {
          this.setState({ loading: false });
          this.onPrimarySwiperChange(3, 1);
        }, 1000);
      } else if (tab === "proverbs") {
        // Go to extras tab
        setTimeout(async () => {
          this.setState({ loading: false });
          this.onPrimarySwiperChange(4, 1);
          this.toggleOverlay("PROVERBS");
        }, 1000);
      } else if (tab === "govt_schemes") {
        // Go to extras tab
        setTimeout(async () => {
          this.setState({ loading: false });
          this.onPrimarySwiperChange(4, 1);
          this.toggleOverlay("GOVT_SCHEMES");
        }, 1000);
      } else if (tab === "word_of_the_day") {
        // Go to extras tab
        setTimeout(async () => {
          this.setState({ loading: false });
          this.onPrimarySwiperChange(4, 1);
          this.toggleOverlay("WORD_OF_THE_DAY");
        }, 1000);
      }
      return;
    }

    // Get the news items from state
    let newsItems = this.state.newsItems;
    if (!!newsItems) {
      if (uid === "uid0") {
        // Go to category page
        setTimeout(async () => {
          window.gtag("event", "event_category_nav_from_notification");
          this.setState({ loading: false });
          this.onPrimarySwiperChange(0, 1);
        }, 1000);
      } else if (uid === "uid1") {
        // Go to main feed page
        setTimeout(async () => {
          window.gtag("event", "event_main_feed_nav_from_notification");
          this.setState({ loading: false });
          this.onPrimarySwiperChange(1, 0);
        }, 1000);
      } else if (uid === "uid2") {
        // Go to positive tab
        setTimeout(async () => {
          window.gtag("event", "event_positive_feed_nav_from_notification");
          this.setState({ loading: false });
          this.onPrimarySwiperChange(2, 1);
        }, 1000);
      } else if (uid === "uid3") {
        // Go to jobs tab
        setTimeout(async () => {
          window.gtag("event", "event_job_feed_nav_from_notification");
          this.setState({ loading: false });
          this.onPrimarySwiperChange(3, 1);
        }, 1000);
      } else if (uid === "uid4") {
        // Go to extras page
        setTimeout(async () => {
          window.gtag("event", "event_extras_nav_from_notification");
          this.setState({ loading: false });
          this.onPrimarySwiperChange(4, 1);
        }, 1000);
      } else if (uid === "uidQuiz") {
        // Show quiz
        setTimeout(async () => {
          this.setState({ loading: false });
          this.toggleQuiz(true);
          window.gtag("event", "event_quiz_nav_from_notification");
        }, 1000);
      } else if (uid === "top10") {
        setTimeout(async () => {
          this.setState({ loading: false });
          this.toggleOverlay("STORIES_TOP10");
        }, 1000);
      } else if (uid === "PREDICTION_CONTEST") {
        setTimeout(async () => {
          this.setState({ loading: false });
          this.toggleOverlay("PREDICTION_CONTEST");
        }, 1000);
      } else if (uid === "RUD") {
        setTimeout(async () => {
          this.setState({ loading: false });
          localStorage.removeItem("user-details");
        }, 1000);
      } else if (uid === "unizone") {
        setTimeout(async () => {
          this.setState({ loading: false });
          this.toggleOverlay("STORIES_UNIZONE");
        }, 1000);
      } else if (uid.includes("district_")) {
        // Split url param to get item uid
        const itemUid = uid.split("_")[1];

        // Filter from exisiting list
        const index = newsItems.findIndex((x) => x.uid === itemUid);
        if (index >= 0) {
          newsItems.splice(index, 1);
        }

        // Get the item from server and push to top
        const uidItem = await itemsService.districtItem(itemUid);
        if (uidItem && uidItem.uid) {
          newsItems.splice(1, 0, uidItem);
        }

        // Update state
        this.setState({
          newsItems: newsItems,
          loading: false,
          secondarySwiperInitSlide: 1,
        });
      } else {
        // This is if the app was launched to view a particular news
        // Check if the item is already in the feed
        const index = newsItems.findIndex((x) => x.uid === uid);
        if (index >= 0) {
          // If so remove it from original location
          newsItems.splice(index, 1);
        }

        // Get the news details from server and push to top
        let uidItem = await itemsService.item(uid);
        if (uidItem && uidItem.uid) {
          if (uidItem.categories.includes("Poster")) {
            uidItem.item_type = "poster_card";
          }
          newsItems.splice(1, 0, uidItem);
        }

        // Update state
        this.setState({
          newsItems: newsItems,
          loading: false,
          secondarySwiperInitSlide: 1,
        });

        // Update user preference
        this.updateUserPref(uidItem, "notification_click");
      }
    }
  }

  // Updates analytics for a particular category click
  async subscribeTopic(category, ignoreAnalyticsEvent = false) {
    if (!category) {
      return;
    }
    let _category = category.replaceAll(" ", "_").replaceAll("/", "_");
    window.subscribeTopic(_category);

    if (!ignoreAnalyticsEvent) {
      window.gtag("event", `event_topic_subscription_${_category}`);
    }
  }

  // After component mount
  async componentDidMount() {
    // For batch state updates
    let stateUpdates = {};

    // Check if there is a UID in URL params
    // const hasUIDInPath = await this.hasUIDInPath();
    // stateUpdates.hasUIDInPath = hasUIDInPath;

    // Check if the user create date (the timestamp on the first use) exist
    // If not set current time
    // const userCreateDate = localStorage.getItem("userCreateDate");
    // if (!userCreateDate) {
    //   localStorage.setItem("userCreateDate", new Date().getTime());
    //   // Flag as first session
    //   firstSession = true;
    // } else {
    //   stateUpdates.guideStatus = {
    //     readmoreTooltip: false,
    //     playTooltip: false,
    //     swipeLeft: false,
    //   };
    // }

    // Set AB test category
    // await this.setABTestCategory();

    // Initial load of news items
    // await this.loadNewsItems(true, hasUIDInPath);

    // If UID is present, handle it (can be 8-news or a specific item)
    // if (hasUIDInPath) {
    //   this.setCurrentIndexForUID();
    //   if (!!this.state.appFirstOpen) {
    //     stateUpdates.appFirstOpen = false;
    //   }
    // } else {
    //   if (!this.state.appFirstOpen) {
    //     stateUpdates.appFirstOpen = true;
    //   }
    // }

    // Get the selected district and update state
    // const selectedDistrict = localStorage.getItem("selectedDistrict");
    // if (
    //   !!selectedDistrict &&
    //   this.state.selectedDistrict !== selectedDistrict
    // ) {
    //   stateUpdates.selectedDistrict = selectedDistrict;
    // }

    // Disable tracking for team members
    // const notracking = localStorage.getItem("notracking");
    // if (!!notracking && (notracking === "true" || notracking === 1)) {
    //   window.doNotTrack = true;
    // }

    // Check if developer mode is enabled or not
    const devModeEnabled = localStorage.getItem("devModeEnabled");
    if (!!devModeEnabled && this.state.devModeEnabled !== devModeEnabled) {
      stateUpdates.devModeEnabled = devModeEnabled;
    }

    // Check if webview or not
    this.setAndroidWebView();

    window.cacheImage("https://storage.katha.today/8iGxtT32keLR1pWL3d93w91y");

    // Add event listener for keydown
    window.addEventListener("keydown", (event) =>
      this.handleKeyDownEvent(event.key)
    );

    // If minimum app version needs to be checked
    // if (MIN_REQ_VERSION_ENABLE) {
    //   // Check if current version is lower than the minimum version
    //   let currentVersion = window.getCurrentApkVersion();
    //   if (currentVersion !== null) {
    //     if (+currentVersion < MIN_REQ_VERSION_NUMBER) {
    //       // Show update modal
    //       this.toggleInfoModal(true, "app_update_alert");
    //     }
    //   }
    // }

    // Update subscriptions
    // this.updateSubscriptions(devModeEnabled, selectedDistrict);

    // Update user session data
    let userSession = localStorage.getItem("user-session");
    if (userSession === null) {
      userSession = {
        count: 1,
      };
    } else {
      userSession = JSON.parse(userSession);
      // Update session counter
      userSession.count++;
    }
    userSession.timestamp = Date.now();
    localStorage.setItem("user-session", JSON.stringify(userSession));

    // Calculate window height
    if (this.state.screenViewHeight === 0) {
      stateUpdates.screenViewHeight = window.innerHeight;
    }

    // Load user details from local storage
    const userDetails = JSON.parse(localStorage.getItem("user-details"));
    stateUpdates.userDetails = userDetails;

    stateUpdates.loading = false;

    // Batch state updates
    if (Object.keys(stateUpdates).length > 0) {
      this.setState(stateUpdates);
    }

    // Check if first session
    // if (firstSession && this.state.guideStatus.playTooltip) {
    //   // Animate secondary swiper
    //   let elem = document.querySelector("#secondarySwiper").children[0];
    //   setTimeout(function () {
    //     elem.style.transition = "transform 0.5s ease";
    //     elem.style.transform = "translateY(-30vh)";
    //   }, 1250);
    //   setTimeout(function () {
    //     elem.style.transition = "transform 0.25s ease";
    //     elem.style.transform = "translateY(0vh)";
    //   }, 2000);
    // }

    // Generate top 10 highlights
    // this.generateHighlights();

    // Start a timer to reload periodically if the app is in background
    // setInterval(() => {
    //   // Check if app in background
    //   let isAppInBackground = window.isAppInBackground();
    //   if (isAppInBackground && !this.state.timerPlaying) {
    //     window.gtag("event", "bg_refresh");
    //     this.handleCategorySelection(null, "", "");
    //     firstSession = false;
    //   }
    // }, 15 * 60 * 1000);
  }

  // Update all existing subscriptions in every session init
  async updateSubscriptions(devModeEnabled, selectedDistrict) {
    // List of all subscribed topics
    let subscribedTopics = [];

    // Default
    window.subscribeTopic("ALL");
    subscribedTopics.push("ALL");

    // Premium
    const automaticModeFeatureEnabled = localStorage.getItem(
      "automaticModeFeatureEnabled"
    );
    if (automaticModeFeatureEnabled === "true") {
      window.unsubscribeTopic("non_premium");
      window.subscribeTopic("premium");
      subscribedTopics.push("premium");
    } else {
      window.unsubscribeTopic("premium");
      window.subscribeTopic("non_premium");
      subscribedTopics.push("non_premium");
    }

    // District
    [
      "KL01",
      "KL02",
      "KL03",
      "KL04",
      "KL05",
      "KL06",
      "KL07",
      "KL08",
      "KL09",
      "KL10",
      "KL11",
      "KL12",
      "KL13",
      "KL14",
    ].forEach((topic) => window.unsubscribeTopic(topic));
    if (
      !!selectedDistrict &&
      selectedDistrict !== "" &&
      selectedDistrict !== "not_interested"
    ) {
      window.subscribeTopic(selectedDistrict);
      subscribedTopics.push(selectedDistrict);
    } else {
      window.subscribeTopic("KL00");
      subscribedTopics.push("KL00");
    }

    // Developer mode
    if (!!devModeEnabled) {
      window.subscribeTopic("testing2");
      window.devModeEnabled = true;
      subscribedTopics.push("testing2");
    } else {
      window.unsubscribeTopic("testing2");
    }

    // Category preference based on interactions
    let userPreferences = JSON.parse(localStorage.getItem("userPreferences"));
    let userPrefAsArray = [];
    if (userPreferences !== null) {
      Object.keys(userPreferences).forEach((key) => {
        let item = userPreferences[key];
        userPrefAsArray.push({
          key: key,
          metrics: item,
        });
      });

      userPrefAsArray.forEach((topic, index) => {
        let formattedTopic = topic.key
          .replaceAll(" ", "_")
          .replaceAll("/", "_");

        if (topic.metrics.count >= 8) {
          window.subscribeTopic(formattedTopic);
          subscribedTopics.push(formattedTopic);
        } else {
          window.unsubscribeTopic(formattedTopic);
        }
      });
    }

    this.setState({ subscribedTopics: subscribedTopics });
  }

  setAndroidWebView = async () => {
    const ua = navigator.userAgent.toLowerCase();
    if (!ua.includes("wv")) {
      this.setState({ isAndroidWebView: false });
    } else {
      // Fire session init event
      window.gtag("event", "event_app_session_init");
      // Fire marker event after 30 seconds
      setTimeout(() => {
        // Check if app in foreground or playing in background
        if (
          !this.state.appInBackground ||
          (this.state.appInBackground &&
            (this.state.timerPlaying || this.state.positiveTabAudioPlaying))
        ) {
          window.gtag("event", "event_app_session_30s");
        }
      }, 30000);
    }
  };

  // Function to set AB test type
  async setABTestCategory() {
    let abTestCategory = localStorage.getItem("abTestCategory");
    // Generate group name
    if (abTestCategory === null && firstSession) {
      let ms = new Date().getMilliseconds();
      abTestCategory = ms % 2 === 0 ? "A" : "B";
      localStorage.setItem("abTestCategory", abTestCategory);
      window.gtag("event", "abtest_assign_" + abTestCategory);
    } else if (abTestCategory !== "A" && abTestCategory !== "B") {
      // Clear old ones
      abTestCategory = "";
      localStorage.removeItem("abTestCategory");
    }
    this.setState({ abTestCategory: abTestCategory });
    window.gtag("set", "user_properties", { abTestCategory: abTestCategory });
  }

  // Handles all key downs
  handleKeyDownEvent = async (key) => {
    // The back button is handled inside few individual components
    if (
      this.state.showQuiz ||
      this.state.primarySwiper.activeIndex === 2 ||
      this.state.primarySwiper.activeIndex === 3 ||
      (this.state.primarySwiper.activeIndex === 4 && !firstSession)
    ) {
      return;
    }

    if (key === "p" || key === "P") {
      this.setState({ audioMuted: false });
    }

    // Back button click
    if (key === "q" || key === "Q") {
      // Close modal if open
      if (this.state.infoModalConf.show) {
        this.toggleInfoModal(false);
        return;
      }
      // Clear overlays
      if (this.state.activeOverlay !== "") {
        this.toggleOverlay("");
        return;
      }
      // Back from category interest selection to settings
      if (this.state.showCategoryInterestSelection) {
        this.toggleCategoryInterest(false);
        return;
      }
      // Back from district selection
      if (this.state.showDistrictSelection) {
        this.toggleDistrictSelection(false);
        return;
      }
      // Back from settings page to settings
      if (this.state.showSettingsPage) {
        this.toggleSettingsPage(false);
        return;
      }
      // Back from other tabs
      if (this.state.primarySwiper.activeIndex !== 1) {
        this.onPrimarySwiperChange(1, 0);
        return;
      } else if (
        this.state.primarySwiper.activeIndex === 1 &&
        this.state.secondarySwiper.activeIndex > 0
      ) {
        // Jump to top
        this.jumpToTop();
        return;
      } else if (
        this.state.primarySwiper.activeIndex === 1 &&
        this.state.category !== null
      ) {
        // Clear category
        this.handleCategorySelection(null, "", "");
        return;
      }
      window.quitApplication();
    }

    // Share event start
    if (key === "w" || key === "W") {
      this.setPlayPauseState("pause");
      this.setState({ videoShareInProgress: true });
    }

    // Share event end
    if (key === "e" || key === "E") {
      this.setState({ videoShareInProgress: false }, async () => {
        await this.setPlayPauseState("pause");
      });
    }

    if (key === "y" || key === "Y") {
      this.setState({ appInBackground: true });
    }
    if (key === "u" || key === "U") {
      this.setState({ appInBackground: false });
    }

    if (key === "r" || key === "R") {
      this.setState({ appInBackground: true });
      this.setPlayPauseState("pause");
    }
    if (key === "t" || key === "T") {
      this.setState({ appInBackground: true });
      this.setPlayPauseState("play");
    }
    if (key === "f" || key === "F") {
      this.setState({ appInBackground: true });
      this.prev();
      this.setPlayPauseState("play");
    }
    if (key === "g" || key === "G") {
      this.setState({ appInBackground: true });
      this.next();
      this.setPlayPauseState("play");
    }

    // Home link click
    if (key === "Z") {
      if (
        this.state.primarySwiper.activeIndex === 1 &&
        this.state.secondarySwiper.activeIndex > 0
      ) {
        // Jump to top
        this.jumpToTop();
        return;
      } else if (
        this.state.primarySwiper.activeIndex === 1 &&
        this.state.category !== null
      ) {
        // Clear category
        this.handleCategorySelection(null, "", "");
        return;
      }
    }
  };

  //
  // Generate top 10 highlights
  //
  generateHighlights = async () => {
    // Check if the state already has highlights
    if (this.state.topTenHighlights.length === 0) {
      // Get all news items from local storage
      let newsItems = localStorage.getItem("newsItems");
      newsItems = await JSON.parse(newsItems);

      // Filter out stories
      newsItems = newsItems.filter(
        (i) =>
          i.categories &&
          !i.categories.includes("HFF") &&
          !i.categories.includes("Stories")
      );

      // Sort the items by timestamp
      newsItems.sort((a, b) =>
        new Date(a.enabled_at) > new Date(b.enabled_at) ? -1 : 1
      );

      // Remaining items
      let nonCategoryItems = [];
      // Final list
      let result = [];

      // Iterate over items
      newsItems.forEach((x) => {
        if (x.Highlight && result.length < 10) {
          result.push(x);
        } else {
          nonCategoryItems.push(x);
        }
      });

      // If there are less than 10 items, use trending ones
      if (result.length < 10) {
        // Find trending items from remaining list
        let trendingItems = this.generateTrendingNews(nonCategoryItems);
        trendingItems.forEach((item) => {
          if (result.length < 10) {
            // Add to top items
            result.push(item);
            // Remove from remaining list
            nonCategoryItems = nonCategoryItems.filter(
              (i) => i.uid !== item.uid
            );
          }
        });
      }

      // Update state
      this.setState({ topTenHighlights: result });
    }
  };

  // Triggered on selecting a new category
  handleCategorySelection = async (
    category,
    categoryNameML,
    categoryNameEN
  ) => {
    // Update state
    this.setState(
      {
        category: category,
        categoryNameML: categoryNameML,
        categoryNameEN: categoryNameEN,
        guideStatus: {
          readmoreTooltip: false,
          playTooltip: false,
          swipeLeft: false,
        },
      },
      async () => {
        if (!!category && category.includes("DISTRICT_")) {
          const districtCode = category.split("_")[1];
          window.gtag("event", "district_category_select_" + districtCode);

          // Check if there are district items in state
          let districtItems = [];
          if (this.state.districtItems.length > 0) {
            districtItems = JSON.parse(
              JSON.stringify(this.state.districtItems)
            );
          } else {
            districtItems = await itemsService.districtItems(districtCode);
          }

          // Get all news items from local storage
          let newsItems = localStorage.getItem("newsItems");
          newsItems = await JSON.parse(newsItems);

          // Filter out stories
          newsItems = newsItems.filter(
            (i) =>
              i.categories &&
              !i.categories.includes("HFF") &&
              !i.categories.includes("Stories")
          );

          // Sort the items by timestamp
          newsItems.sort((a, b) =>
            new Date(a.enabled_at) > new Date(b.enabled_at) ? -1 : 1
          );

          // Push the end card (to show all news from selected category has been shown)
          let endCardMessage = null;
          if (districtItems.length === 0) {
            endCardMessage =
              this.state.language === "EN"
                ? "The tales of your neighborhood. Coming Soon!"
                : "മണ്ണിൻ്റെ മണമുള്ള നാട്ടുവാർത്തകൾ. ഉടൻ വരുന്നു!";
          }
          districtItems.push({
            uid: "end_card",
            item_type: "end_card",
            audio_link:
              "https://storage.katha.today/GdTZaM9T9KuK8ADpC7J9coXs?id=224999",
            title: "ഈ വിഭാഗത്തിലെ എല്ലാ വാർത്തകളും കേട്ട് കഴിഞ്ഞു.",
            endCardMessage: endCardMessage,
          });

          // Append an extra item to avoid glitch
          districtItems.push(newsItems[0]);

          // Move to top and update items
          this.setState({ newsItems: districtItems }, () => {
            this.jumpToTop();
          });
        } else if (!!category) {
          window.gtag("event", `event_categories_${category.toLowerCase()}`);

          // Get all news items from local storage
          let newsItems = await localStorage.getItem("newsItems");
          newsItems = await JSON.parse(newsItems);

          // Filter out hidden items and stories older than 24 hours
          let dateYesterday = new Date(
            new Date().setDate(new Date().getDate() - 1)
          );
          newsItems = newsItems.filter(
            (i) =>
              i.categories &&
              !i.categories.includes("HFF") &&
              (!i.categories.includes("Stories") ||
                new Date(i.enabled_at) > dateYesterday)
          );

          // Unread items from the selected category
          let categoryUnReadItems = [];
          // Read items from the selected category
          let categoryReadItems = [];
          // Unread items from the remaining categories
          let nonCategoryUnReadItems = [];
          // Read items from the remaining categories
          let nonCategoryReadItems = [];
          // Final list
          let result = [];

          // Iterate over items
          await newsItems.forEach((x) => {
            // Get the category list of the item
            const categories = (x.categories || []).map((c) => c.toLowerCase());

            // Check if the selected category exist in the item's category list
            const found = categories.find(
              (x) => x.toLowerCase() === category.toLowerCase()
            );
            if (found) {
              // Divide them based on read flag
              if (x.read) {
                categoryReadItems.push(x);
              } else {
                categoryUnReadItems.push(x);
              }
            } else {
              // Divide them based on read flag
              if (x.read) {
                nonCategoryReadItems.push(x);
              } else {
                nonCategoryUnReadItems.push(x);
              }
            }
          });

          // Sort the items by timestamp
          categoryUnReadItems.sort((a, b) =>
            new Date(a.enabled_at) > new Date(b.enabled_at) ? -1 : 1
          );

          // Append the read items after non read items
          result = categoryUnReadItems.concat(categoryReadItems);

          // Push the end card (to show all news from selected category has been shown)
          result.push({
            uid: "end_card",
            item_type: "end_card",
            audio_link:
              "https://storage.katha.today/GdTZaM9T9KuK8ADpC7J9coXs?id=224999",
            title: "ഈ വിഭാഗത്തിലെ എല്ലാ വാർത്തകളും കേട്ട് കഴിഞ്ഞു.",
          });

          // Append an extra item to avoid glitch
          result.push(nonCategoryUnReadItems[0]);

          // Move to top and update items
          this.slideToIndex(0);
          this.setState({ newsItems: result });
        } else {
          // Load default feed
          await this.loadNewsItems(true);
        }

        // Move back to home page
        await this.onPrimarySwiperChange(1, 0);

        // Start audio
        await this.setCurrentAudio(this.state.newsItems[0]);
      }
    );
  };

  // Creates a map of category and the number of news in each category
  uniqueCategories = async (newsItems) => {
    // Mapping object
    // The key will be category name and the value will be the number of items belonging to it
    // Eg : { Viral : 23, Kerala : 12 }
    const result = {};

    // Iterate over the items and create mapping
    newsItems.forEach((x) => {
      if (!!x.categories) {
        x.categories.forEach((category) => {
          if (!!category) {
            let value = result[category];
            if (!!value) {
              result[category] = value + 1;
            } else {
              result[category] = 1;
            }
          }
        });
      }
    });
    return result;
  };

  // Return to home (pariticular item) from categories
  closeWithUid = async (uid) => {
    await this.setCurrentIndexForUID(uid);
    await this.onPrimarySwiperChange(1, 0);
  };

  // Creates the message for sharing in whatsapp
  createShareMessage = (item) => {
    const link = !!item.short_url
      ? item.short_url
      : "https://api-in.katha.today/share/" + item.uid;

    let shareMsg = "*" + item.title + "*\n\n";
    shareMsg += "കൂടുതൽ വാർത്തകളും വിവരങ്ങളും വ്യക്തതയോടെ അറിയാം :\n" + link;
    shareMsg += "\n\n*കഥ ആപ്പ്* ഡൗൺലോഡ് ചെയ്യാം :\n";
    shareMsg += "https://link.katha.today/download";

    return shareMsg;
  };

  // Goes to the next news item
  next = async () => {
    const newsItemsLength = this.state.newsItems.length;
    if (this.state.secondarySwiper.activeIndex < newsItemsLength) {
      window.gtag("event", "event_autoswipe");
      // Move to next item
      this.state.secondarySwiper.slideNext();
    }
  };

  // Goes to the prev news item
  prev = async () => {
    const newsItemsLength = this.state.newsItems.length;
    if (this.state.secondarySwiper.activeIndex < newsItemsLength) {
      // Move to next item
      this.state.secondarySwiper.slidePrev();
    }
  };

  // Flags a news item as read
  markAsRead = async (uid) => {
    // Get the data from local storage
    let markedAsRead = false;
    let newsItems = JSON.parse(await localStorage.getItem("newsItems"));
    let index = newsItems.findIndex((x) => x["uid"] === uid);
    if (index >= 0 && this.state.primarySwiper.activeIndex > 0) {
      // Update flag
      let newsItem = newsItems[index];

      if (!newsItem["read"]) {
        if (this.state.unreadNewsCount === 1) {
          if (this.state.currentSessionLevel === 0) {
            this.setState({
              currentSessionLevel: 1,
              unreadNewsCount: sessionLevel1Count,
            });
            if (this.state.category === null) {
              this.updateToastMessage({
                flag: "unread_count_reset",
                content:
                  this.state.language === "EN"
                    ? "Refreshing feed for more news..."
                    : "കൂടുതൽ വാർത്തക്കായി ഫീഡ് പുതുക്കുന്നു...",
              });
            }
            window.gtag("event", "dynamic_unread_count_reset");
          }
        }

        if (this.state.unreadNewsCount === 1) {
          window.gtag("event", "event_unread_count_zero");
        }
        // Update news count to be shown in category page
        markedAsRead = true;
        // this.setState({unreadNewsCount: this.state.unreadNewsCount - 1});
      }

      newsItem["read"] = true;

      let _pinChains = this.state.pinChains;
      if (
        !!newsItem["pin_chain_uid"] &&
        !!_pinChains[newsItem["pin_chain_uid"]]
      ) {
        _pinChains[newsItem["pin_chain_uid"]][newsItem["uid"]] = true;

        let keys = Object.keys(_pinChains[newsItem["pin_chain_uid"]]);
        let values = Object.values(_pinChains[newsItem["pin_chain_uid"]]);

        if (values.every(Boolean)) {
          newsItems.forEach((x) => {
            if (keys.includes(x["uid"])) {
              x["read"] = true;
            }
          });
        } else {
          newsItem["read"] = false;
        }

        this.setState({ pinChains: _pinChains });
      }

      // Update the data in local storage
      await localStorage.setItem("newsItems", JSON.stringify(newsItems));

      // Send analytics event
      if (newsItem.item_type === "news") {
        window.gtag("event", "event_newsread");
        window.logFirebaseEvent("event_newsread");
        if (this.state.appInBackground) {
          window.gtag("event", "event_bg_newsread");
        }
      }
    }
    return markedAsRead;
  };

  updateToastMessage = async (message) => {
    this.setState({ toastMessage: message });
  };

  // For tracking how long the audio is being listened
  trackAudioPlayTime = (newsItem) => {
    // Check if item is special or story
    if (
      !!newsItem.categories &&
      (newsItem.categories.includes("Special") ||
        newsItem.categories.includes("Stories"))
    ) {
      const audio = document.getElementById("audio");
      let bucketStartTime = Math.floor(audio.currentTime / 10);
      let bucketEndTime = bucketStartTime + 1;
      if (bucketStartTime < 10) {
        bucketStartTime = "0" + bucketStartTime + "0";
      } else {
        bucketStartTime = bucketStartTime + "0";
      }
      if (bucketEndTime < 10) {
        bucketEndTime = "0" + bucketEndTime + "0";
      } else {
        bucketEndTime = bucketEndTime + "0";
      }
      window.gtag(
        "event",
        `event_audioplayed_uid_${newsItem.uid}_${bucketStartTime}_${bucketEndTime}`
      );
    }
  };

  nextUpdates = async (newIndex, oldIndex) => {
    // Pause current audio
    const audio = document.getElementById("audio");
    audio.pause();

    const { newsItems, isAndroidWebView, guideStatus } = this.state;

    let stateUpdates = {};

    if (newIndex > -1 && newIndex < newsItems.length) {
      window.global__viewportUid = newsItems[newIndex].uid;
      // stateUpdates.viewportUid = newsItems[newIndex].uid;
      // this.setState({
      //     viewportUid: newsItems[newIndex].uid
      // });
      if (!!this.state.appFirstOpen) {
        stateUpdates.appFirstOpen = false;
        // this.setState({
        //     appFirstOpen: false
        // });
      }
    } else if (newIndex === newsItems.length) {
      window.gtag("event", "reached_feed_end");
    }

    // Send flags for background play
    if (newIndex < 2) {
      if (this.state.category !== null && newIndex === 1) {
        window.hasPrevious(true);
      } else {
        window.hasPrevious(false);
      }
      window.hasNext(true);
    } else if (newIndex === newsItems.length - 1) {
      window.hasPrevious(true);
      window.hasNext(false);
    } else {
      window.hasPrevious(true);
      window.hasNext(true);
    }

    if (
      !isAndroidWebView &&
      window.location.hostname !== "localhost" &&
      window.location.hostname !== "staging-app.katha.today"
    ) {
      if (this.state.nonWVCounter === 1) {
        this.setState({ nonWVCounter: 2 });
      } else {
        window.gtag("event", `event_wv_redirect`);
        window.open(
          "https://play.google.com/store/apps/details?id=today.katha&referrer=utm_source%3Dapp_in_chrome"
        );
      }
    }

    if (oldIndex > -1 && oldIndex < newsItems.length) {
      // Update User Pref if time spent on a news is >= 4s
      let currentTime = new Date();
      let diff = (currentTime - newsReadTimer) / 1000;
      newsReadTimer = currentTime;
      if (
        newsItems[oldIndex].item_type === "news" ||
        newsItems[oldIndex].district
      ) {
        if (diff < 4) {
          this.updateUserPref(newsItems[oldIndex], "disinterest");
        }
        if (diff >= 7) {
          this.updateUserPref(newsItems[oldIndex], "read_7s");
        }
      } else if (
        newsItems[oldIndex].item_type === "end_card" &&
        newIndex > oldIndex
      ) {
        // Reload once category items are finished
        this.handleCategorySelection(null, "", "");
      }

      // Mark the item as read
      let markedAsRead = await this.markAsRead(newsItems[oldIndex].uid);
      if (markedAsRead) {
        stateUpdates.unreadNewsCount = this.state.unreadNewsCount - 1;
      }
      // Track how much audio was played
      this.trackAudioPlayTime(newsItems[oldIndex]);
      // District news track
      if (newsItems[oldIndex].district) {
        window.gtag(
          "event",
          "district_newsread_" + newsItems[oldIndex].district
        );
        window.gtag("event", "district_newsread_total");
        // If RTD card has been shown, mark the UID in local storage so that it wont appear again
        if (
          newsItems[oldIndex].categories &&
          newsItems[oldIndex].categories.includes("RTD")
        ) {
          localStorage.setItem("lastShownRTD", newsItems[oldIndex].uid);
        }
      }
    }

    const currentItem = newsItems[newIndex];

    if (
      currentItem &&
      currentItem.item_type === "review_us_card" &&
      this.state.appInBackground
    ) {
      await this.next();
    }

    // Events for every non news item card show
    if (currentItem && currentItem.item_type !== "news") {
      window.gtag("event", "show_" + currentItem.uid);
    }

    if (newIndex === 10) {
      window.gtag("event", "crossed_10_swipes");
    }

    if (newIndex % 3 === 0) {
      let _status = window.isAppInBackground();
      if (!!_status && this.state.appInBackground !== _status) {
        stateUpdates.appInBackground = _status;
        // this.setState({appInBackground: _status});
      }
    }

    // Update the audio for next item
    this.setCurrentAudio(currentItem, false);

    // If topbar is visible and swiped down, hide topbar
    if (newIndex < oldIndex || newIndex === 0) {
      if (!this.state.showTopBar) {
        stateUpdates.showTopBar = true;
      }
    } else if (this.state.showTopBar) {
      if (!!this.state.showTopBar) {
        stateUpdates.showTopBar = false;
      }
    }

    if (firstSession) {
      if (newIndex === 1 && guideStatus.playTooltip) {
        window.gtag("event", "show_guide_play");
        window.logFirebaseEvent("guide_show_play");
      } else if (newIndex === 2 && guideStatus.readmoreTooltip) {
        window.gtag("event", "show_guide_read_more");
        window.logFirebaseEvent("guide_show_read_more");
      } else if (newIndex === 3) {
        stateUpdates.guideStatus = guideStatus;
        stateUpdates.guideStatus.playTooltip = false;
      } else if (newIndex === 4) {
        stateUpdates.guideStatus = guideStatus;
        stateUpdates.guideStatus.readmoreTooltip = false;
      } else if (newIndex === 7 && guideStatus.swipeLeft) {
        window.gtag("event", "show_guide_swipe_left");
        window.logFirebaseEvent("guide_show_swipe_left");
        window.logFacebookEvent("event_guide_category_swipe_show");
      }
    }

    if (Object.keys(stateUpdates).length > 0) {
      this.setState(stateUpdates);
    }
  };

  // Loads the audio for current item and plays it
  setCurrentAudio = async (currentItem, forcePlay) => {
    const audio = document.getElementById("audio");
    const audioSource = document.getElementById("audioSource");

    if (!!currentItem && !!currentItem.audio_link) {
      // Check if RTP or not
      if (currentItem.categories && currentItem.categories.includes("RTP")) {
        window.gtag("event", "rtp_card_show");
        // Custom audio
        audioSource.src =
          "https://storage.katha.today/TYqyqYTViws3EVdmbH8LiTft";
      } else {
        audioSource.src = currentItem.audio_link;
      }
      // Show loader
      this.setState({ isFeedAudioLoading: true });
      // Load audio
      await audio.load();
      // If auto mode is enabled, start palying
      if (this.state.automaticMode || forcePlay) {
        await audio.play();
      }
      // Update bg player
      window.setIsDismissed(false);
      window.setCurrentNewsTitle(currentItem.title);
      window.setCurrentNewsImageUrl(currentItem.image_link);
    } else {
      await audio.pause();
      audioSource.src = null;
    }
  };

  // Handles play and pause events
  setPlayPauseState = async (op) => {
    window.gtag("event", `event_audio_${op}`);

    // Get audio element
    const audio = document.getElementById("audio");

    let stateUpdates = {};

    if (op === "pause") {
      if (!!this.state.timerPlaying) {
        stateUpdates.timerPlaying = false;
      }
      if (!!this.state.automaticMode) {
        stateUpdates.automaticMode = false;
      }
      window.setAudioPlaying("false");

      if (Object.keys(stateUpdates).length > 0) {
        this.setState(stateUpdates);
      }
      await audio.pause();
    } else {
      if (!this.state.timerPlaying) {
        stateUpdates.timerPlaying = true;
      }
      if (!this.state.automaticMode) {
        stateUpdates.automaticMode = true;
      }
      window.setAudioPlaying("true");

      if (Object.keys(stateUpdates).length > 0) {
        this.setState(stateUpdates);
      }
      await audio.play();
    }
  };

  // Change main swiper index
  onPrimarySwiperChange = async (newIndex, oldIndex) => {
    // if (
    //   this.state.primarySwiper.activeIndex === undefined ||
    //   this.state.primarySwiper.oldIndex !== newIndex
    // ) {
    //   try {
    //     this.state.primarySwiper.activeIndex = newIndex;
    //     this.state.primarySwiper.slideTo(newIndex);
    //   } catch (e) {}
    //   this.setState({ primarySwiper: this.state.primarySwiper });
    // }
    // let stateUpdates = {};
    // if (newIndex !== 1) {
    //   this.setPlayPauseState("pause");
    //   if (newIndex === 0) {
    //     window.gtag("event", "event_swipe_to_categories");
    //   } else if (newIndex === 2) {
    //     window.gtag("event", "event_swipe_to_positive");
    //   } else if (newIndex === 3) {
    //     window.gtag("event", "event_swipe_to_jobs");
    //   } else if (newIndex === 4) {
    //     window.gtag("event", "event_swipe_to_extras");
    //   }
    //   // Show top bar
    //   if (!this.state.showTopBar) {
    //     stateUpdates.showTopBar = true;
    //   }
    //   if (newIndex !== 2) {
    //     // Clear bg player
    //     window.setIsDismissed(true);
    //     window.setCurrentNewsTitle(null);
    //     window.setCurrentNewsImageUrl(null);
    //   }
    // } else {
    //   const currentItem =
    //     this.state.newsItems[this.state.secondarySwiper.activeIndex];
    //   if (!!this.state.appFirstOpen) {
    //     stateUpdates.appFirstOpen = false;
    //   }
    //   await this.setCurrentAudio(currentItem);
    //   window.gtag("event", "event_swipe_to_news");
    //   // BG play update
    //   let currentItemIndex = this.state.newsItems.findIndex(
    //     (item) => item.uid === window.global__viewportUid
    //   );
    //   if (currentItemIndex > 0) {
    //     let currentItem = this.state.newsItems[currentItemIndex];
    //     // Update bg player
    //     window.setIsDismissed(false);
    //     window.setCurrentNewsTitle(currentItem.title);
    //     window.setCurrentNewsImageUrl(currentItem.image_link);
    //     if (currentItemIndex < 2) {
    //       if (this.state.category !== null && newIndex === 1) {
    //         window.hasPrevious(true);
    //       } else {
    //         window.hasPrevious(false);
    //       }
    //       window.hasNext(true);
    //     } else if (currentItemIndex === this.state.newsItems.length - 1) {
    //       window.hasPrevious(true);
    //       window.hasNext(false);
    //     } else {
    //       window.hasPrevious(true);
    //       window.hasNext(true);
    //     }
    //   } else {
    //     // Clear bg player
    //     window.setIsDismissed(true);
    //     window.setCurrentNewsTitle(null);
    //     window.setCurrentNewsImageUrl(null);
    //   }
    //   if (this.state.secondarySwiper.activeIndex !== 0) {
    //     // Show top bar
    //     if (!!this.state.showTopBar) {
    //       stateUpdates.showTopBar = false;
    //     }
    //   }
    // }
    // if (Object.keys(stateUpdates).length > 0) {
    //   this.setState(stateUpdates);
    // }
  };

  shareAnalyticsTimeWise = async () => {
    const d = new Date(); // 24hrs

    let event_name = null;
    if (d.getHours() >= 7 && d.getHours() < 11) {
      event_name = "event_share_morning";
    } else if (d.getHours() >= 11 && d.getHours() < 15) {
      event_name = "event_share_afternoon";
    } else if (d.getHours() >= 15 && d.getHours() < 19) {
      event_name = "event_share_evening";
    } else if (d.getHours() >= 19 && d.getHours() < 23) {
      event_name = "event_share_night";
    }
    window.gtag("event", event_name);
  };

  //
  // On changing selected district
  //
  selectDistrict = async (selectedDistrictNumber) => {
    // Update state and storage
    this.setState({ selectedDistrict: selectedDistrictNumber });
    localStorage.setItem("selectedDistrict", selectedDistrictNumber);
    window.gtag("event", `event_district_selection_${selectedDistrictNumber}`);
    // Close selection
    this.toggleDistrictSelection(false);
    // Reload news items
    await this.loadNewsItems(false);
  };

  startPlaying = async (currentItem, hasUIDInPath, appFirstOpen) => {
    if (hasUIDInPath || appFirstOpen) {
      this.setState({ appFirstOpen: false });
      this.onPrimarySwiperChange(1, 0);
    } else {
      // this.startSharing(currentItem);
    }
  };

  //
  // Share a particular item
  //
  startSharing = async (currentItem) => {
    // Pause audio
    this.setPlayPauseState("pause");

    // Create share message
    let shareMsg = this.createShareMessage(currentItem);

    if (
      !!currentItem.video_link &&
      (this.state.devModeEnabled ||
        (currentItem.categories.length > 0 &&
          (currentItem.categories.includes("Special") ||
            currentItem.categories.includes("Viral") ||
            currentItem.categories.includes("Stories"))))
    ) {
      // Video share for developer mode or particular categories
      window.shareVideo(currentItem.video_link, shareMsg);
      window.gtag("event", "event_share_video");
    } else {
      // Normal text share
      window.shareText(shareMsg);
    }

    // Send analytics events
    if (currentItem.district) {
      window.gtag("event", "district_news_share_" + currentItem.district);
      window.logFirebaseEvent("district_news_share_" + currentItem.district);
    } else {
      window.gtag("event", "event_share_button");
      window.logFirebaseEvent("feed_whatsapp_share");
    }
    if (!!currentItem.categories) {
      currentItem.categories.forEach((category) => {
        window.gtag("event", `event_share_categories_${category}`);
      });
    }
    window.gtag("event", `event_share_news_${currentItem.uid}`);
    this.shareAnalyticsTimeWise();
  };

  startGenericSharing = async (currentItem) => {
    let shareMsg = this.createShareMessage(currentItem);
    window.shareGeneric(shareMsg);
  };

  // Hide or show category interest selection
  toggleCategoryInterest = (show) => {
    this.setState({ showCategoryInterestSelection: show });
    // If closed, reload news items
    if (!show) {
      // Reload news only if config has been updated
      if (this.state.isCategoryInterestUpdated) {
        this.loadNewsItems(false);
      }
    } else {
      // Reset flag on show
      this.hasCategoryInterestUpdated(false);
      window.gtag("event", "event_settings_categoryselect_click");
    }
  };

  //
  // Hide or show an overlay
  //
  toggleOverlay = (flag) => {
    if (flag !== "") {
      window.gtag("event", "overlay_open_" + flag);
    }
    this.setState({ activeOverlay: flag });
  };

  //
  // Update a state value
  //
  updateGlobalStateValue = async (key, value) => {
    this.setState({ [key]: value });
  };

  // Hide or show settings page
  toggleSettingsPage = (show) => {
    this.setState({ showSettingsPage: show });
  };

  // Update state if category interest config has been changed
  hasCategoryInterestUpdated = (flag) => {
    this.setState({ isCategoryInterestUpdated: flag });
  };

  // Hide or show district selection
  toggleDistrictSelection = (show) => {
    this.setState({ showDistrictSelection: show });
    if (show) {
      window.gtag("event", "event_district_selection_click");
    }
  };

  // Show or hide quiz
  toggleQuiz = (show, swipeToExtras) => {
    this.setState({ showQuiz: show });
    if (swipeToExtras) {
      // Move the primary swiper to category page to pause bg play
      this.onPrimarySwiperChange(4, 1);
    }
  };

  userActivationEvent = async (totalInteractionCount) => {
    const automaticModeFeatureEnabled = localStorage.getItem(
      "automaticModeFeatureEnabled"
    );
    const infoModalCounter = localStorage.getItem("infoModalCounter");

    // Track progress only if premium is not enabled
    if (!automaticModeFeatureEnabled) {
      if (totalInteractionCount >= 30) {
        // Features are unlocked
        window.gtag("event", "event_super_activated_user");
        window.logFirebaseEvent("event_super_activated_user");
        localStorage.setItem("automaticModeFeatureEnabled", true);
        // Update counter to avoid showing the modal again
        localStorage.setItem("infoModalCounter", "30-PLUS");
      } else if (totalInteractionCount >= 16) {
        // Check if the modal for 20+ has been shown or not
        if (infoModalCounter == null || infoModalCounter === "10-20") {
          window.gtag("event", "event_fully_activated_user");
          window.logFirebaseEvent("event_fully_activated_user");
          // Update counter to avoid showing the modal again
          localStorage.setItem("infoModalCounter", "20-30");
        }
      } else if (totalInteractionCount >= 5) {
        // Check if the modal for 10+ has been shown or not
        if (infoModalCounter == null) {
          window.gtag("event", "event_partially_activated_user");
          window.logFirebaseEvent("event_partially_activated_user");
          // Update counter to avoid showing the modal again
          localStorage.setItem("infoModalCounter", "10-20");
        }
      }
    } else {
      // If interactions exceeds 200
      if (
        totalInteractionCount >= 200 &&
        (infoModalCounter == null || infoModalCounter === "100-PLUS")
      ) {
        window.gtag("event", "event_200_interactions_activated_user");
        window.logFirebaseEvent("event_200_interactions_activated_user");
        localStorage.setItem("infoModalCounter", "200-PLUS");

        // If interactions exceeds 100
      } else if (
        totalInteractionCount >= 100 &&
        (infoModalCounter == null || infoModalCounter === "30-PLUS")
      ) {
        window.gtag("event", "event_100_interactions_activated_user");
        window.logFirebaseEvent("event_100_interactions_activated_user");
        localStorage.setItem("infoModalCounter", "100-PLUS");
      }
    }
  };

  updateUserPref = async (newsItem, _op) => {
    // If read more clicked , disable guide
    if (_op === "readmore") {
      let currentGuideStatus = { ...this.state.guideStatus };
      currentGuideStatus.readmoreTooltip = false;
      this.setState({ guideStatus: currentGuideStatus });
    } else if (_op === "play") {
      let currentGuideStatus = { ...this.state.guideStatus };
      currentGuideStatus.playTooltip = false;
      this.setState({ guideStatus: currentGuideStatus });
    }

    if (!!newsItem.categories) {
      const userPreferences = JSON.parse(
        (await localStorage.getItem("userPreferences")) || "{}"
      );

      let _secondary_categories = newsItem.secondary_categories || [];
      let _categories = newsItem.categories.filter(
        (x) => !_secondary_categories.map((x) => x.split(" / ")[0]).includes(x)
      );
      _categories.forEach((newsCategory) => {
        let formattedTopic = newsCategory
          .replaceAll(" ", "_")
          .replaceAll("/", "_");
        userPreferences[newsCategory] = userPreferences[newsCategory] || {};
        userPreferences[newsCategory]["count"] =
          (userPreferences[newsCategory]["count"] || 0) + 1;

        if (_op === "disinterest") {
          userPreferences[newsCategory]["count"] -= 1;
          userPreferences[newsCategory]["score"] =
            (userPreferences[newsCategory]["score"] || 1) -
            1 / Math.pow(2, userPreferences[newsCategory]["count"]);
          if (userPreferences[newsCategory]["score"] < 1) {
            userPreferences[newsCategory]["score"] = 1;
          }
          if (userPreferences[newsCategory]["count"] < 0) {
            userPreferences[newsCategory]["count"] = 0;
          }
          window.unsubscribeTopic(formattedTopic);
        } else if (_op === "notification_click") {
          userPreferences[newsCategory]["score"] =
            (userPreferences[newsCategory]["score"] || 1) +
            2 / Math.pow(2, userPreferences[newsCategory]["count"]);
        } else {
          userPreferences[newsCategory]["score"] =
            (userPreferences[newsCategory]["score"] || 1) +
            1 / Math.pow(2, userPreferences[newsCategory]["count"]);
        }

        if (userPreferences[newsCategory]["count"] >= 8) {
          window.subscribeTopic(formattedTopic);
        }
      });

      _secondary_categories.forEach((secondaryNewsCategory) => {
        let formattedTopic = secondaryNewsCategory
          .replaceAll(" ", "_")
          .replaceAll("/", "_");
        userPreferences[secondaryNewsCategory] =
          userPreferences[secondaryNewsCategory] || {};
        userPreferences[secondaryNewsCategory]["count"] =
          (userPreferences[secondaryNewsCategory]["count"] || 0) + 1;

        if (_op === "disinterest") {
          userPreferences[secondaryNewsCategory]["count"] -= 1;
          userPreferences[secondaryNewsCategory]["score"] =
            (userPreferences[secondaryNewsCategory]["score"] || 1) -
            1 / Math.pow(2, userPreferences[secondaryNewsCategory]["count"]);
          if (userPreferences[secondaryNewsCategory]["score"] < 1) {
            userPreferences[secondaryNewsCategory]["score"] = 1;
          }
          if (userPreferences[secondaryNewsCategory]["count"] < 0) {
            userPreferences[secondaryNewsCategory]["count"] = 0;
          }
          window.unsubscribeTopic(formattedTopic);
        } else if (_op === "notification_click") {
          userPreferences[secondaryNewsCategory]["score"] =
            (userPreferences[secondaryNewsCategory]["score"] || 1) +
            2 / Math.pow(2, userPreferences[secondaryNewsCategory]["count"]);
        } else {
          userPreferences[secondaryNewsCategory]["score"] =
            (userPreferences[secondaryNewsCategory]["score"] || 1) +
            1 / Math.pow(2, userPreferences[secondaryNewsCategory]["count"]);
        }

        if (userPreferences[secondaryNewsCategory]["count"] >= 8) {
          window.subscribeTopic(formattedTopic);
        }
      });

      let totalInteractionCount = 0;
      Object.keys(userPreferences).forEach((key) => {
        let item = userPreferences[key];
        totalInteractionCount += item["count"];
      });

      localStorage.setItem("userPreferences", JSON.stringify(userPreferences));
      localStorage.setItem("totalInteractionCount", totalInteractionCount);

      this.userActivationEvent(totalInteractionCount);

      // Update score in server
      if (
        ["read_7s", "readmore", "play", "share", "notification_click"].includes(
          _op
        ) &&
        !this.state.devModeEnabled
      ) {
        if (_op === "read_7s") {
          _op = "read";
        } else if (_op === "notification_click") {
          _op = "notification";
        }

        if (!newsItem.district) {
          itemsService.countInteraction(newsItem.id, _op);
        }
      }
    }
  };

  // Show or hide info modal
  toggleInfoModal = (show, contentFlag, playAudioOnClose) => {
    // If show, update state
    // This will enable the modal will provided details
    if (show) {
      window.gtag("event", "event_modal_show_" + contentFlag);
      this.setState({
        infoModalConf: {
          show: true,
          contentFlag: contentFlag,
        },
      });
    } else {
      // Animate hiding
      if (document.querySelector(".info-modal")) {
        document.querySelector(".info-modal").style.transform =
          "translateY(100vh)";
      }
      setTimeout(() => {
        this.setState(
          {
            infoModalConf: {
              show: false,
            },
          },
          () => {
            if (playAudioOnClose) {
              // Restart play
              this.setPlayPauseState("play");
            }
          }
        );
      }, 300);
    }
  };

  // Update language in state
  toggleLang = (lang) => {
    this.setState({ language: lang });
  };

  // Update theme in state
  toggleTheme = (theme) => {
    this.setState({ colorTheme: theme });
  };

  // Update username in state
  // updateUsername = (username) => {
  //     this.setState({username: username});
  // };

  // Update active job item
  updateActiveJobItem = (item) => {
    this.setState({ activeJobItem: item });
    // Toggle modal
    this.toggleInfoModal(true, "job_more_options");
  };

  // Set state of positive tab audio play
  setPositiveTabAudioPlaying = (state) => {
    this.setState({ positiveTabAudioPlaying: state });
  };

  // Set state of positive tab overlay
  setPositiveTabOverlay = (state) => {
    if (state) {
      this.setState({ showTopBar: false, disableNav: true });
    } else {
      this.setState({ showTopBar: true, disableNav: false });
    }
  };

  //
  // On selecting an item from search list
  //
  onSearchItemSelect = (item) => {
    // Move to news item
    if (item) {
      this.gotoNewsItem(item.uid);
    }
    // Close overlay
    this.toggleOverlay("");
  };

  //
  // Go to a particular news item
  //
  gotoNewsItem = async (uid) => {
    // Get current list of items
    let newsItems = JSON.parse(JSON.stringify(this.state.newsItems));
    // Check for given item in the list
    let uidItemIndex = newsItems.findIndex((i) => i.uid === uid);
    if (uidItemIndex > -1) {
      // Move item to second position (below trending card)
      newsItems.splice(1, 0, newsItems[uidItemIndex]);
      newsItems.splice(uidItemIndex + 1, 1);
    } else {
      // Get the item from server
      let item = await itemsService.item(uid);
      if (item && item.uid) {
        if (item.categories.includes("Poster")) {
          item.item_type = "poster_card";
        }
        newsItems.splice(1, 0, item);
      }
    }
    // Update state
    this.setState(
      {
        newsItems: newsItems,
        showTopBar: false,
        activeOverlay: "",
      },
      async () => {
        newsReadTimer = new Date();
        // Go to feed
        if (this.state.primarySwiperActiveIndex !== 1) {
          this.onPrimarySwiperChange(1, 0);
        }
        // Scroll to second card
        this.slideToIndex(1);
      }
    );
  };

  render() {
    const {
      newsItems,
      loading,
      uniqueCategories,
      selectedDistrict,
      audioMuted,
      timerPlaying,
      hasUIDInPath,
      videoShareInProgress,
      appFirstOpen,
      unreadNewsCount,
      showCategoryInterestSelection,
      secondarySwiper,
      activeJobItem,
      language,
      colorTheme,
      showTopBar,
      showQuiz,
      disableNav,
      categoryInterestConfig,
      primarySwiper,
      category,
      categoryNameEN,
      categoryNameML,
      infoModalConf,
      devModeEnabled,
      secondarySwiperInitSlide,
      automaticMode,
      showSettingsPage,
      appInBackground,
      toastMessage,
      toastConfetti,
    } = this.state;

    let primarySwiperHeight = "100vh";
    if (primarySwiper.activeIndex !== 0) {
      primarySwiperHeight = "92vh";
    }
    if (disableNav) {
      primarySwiperHeight = "100vh";
    }

    return (
      <div className="text-center">
        {loading && <Loading colorTheme={colorTheme} />}

        <Toast
          message={toastMessage}
          confetti={toastConfetti}
          updateToastMessage={this.updateToastMessage}
          handleCategorySelection={this.handleCategorySelection}
          updateGlobalStateValue={this.updateGlobalStateValue}
        />

        <>
          <audio
            id="audio"
            muted={audioMuted}
            onPlaying={(event) => {
              window.setAudioPlaying("true");
            }}
            onEnded={() => {
              setTimeout(async () => {
                this.next();
              }, 500);
              window.setAudioPlaying("false");
              window.setCurrentNewsTitle(null);
              window.setCurrentNewsImageUrl(null);
            }}
            onCanPlay={() => {
              this.setState({ isFeedAudioLoading: false });
            }}
          >
            <source id="audioSource" src="" />
          </audio>

          {!loading && (
            <div
              className="bg-lbs dark:bg-dbs"
              style={{ height: "100vh", overflow: "hidden" }}
            >
              {/* Fixed navbar */}
              <TopNav
                showTopBar={showTopBar}
                primarySwiperActiveIndex={primarySwiper.activeIndex}
                handleCategorySelection={this.handleCategorySelection}
                selectedCategory={category}
                selectedCategoryNameEN={categoryNameEN}
                selectedCategoryNameML={categoryNameML}
                language={language}
                onPrimarySwiperChange={this.onPrimarySwiperChange}
                unreadNewsCount={unreadNewsCount}
                toggleSettingsPage={this.toggleSettingsPage}
                toggleInfoModal={this.toggleInfoModal}
                updateGlobalStateValue={this.updateGlobalStateValue}
                toggleOverlay={this.toggleOverlay}
              ></TopNav>

              <Swiper
                id="primarySwiper"
                spaceBetween={0}
                slidesPerView={1}
                onSlideChangeTransitionEnd={(swiper) =>
                  this.onPrimarySwiperChange(
                    swiper.activeIndex,
                    swiper.previousIndex
                  )
                }
                // onSlideChange={(swiper) => this.onPrimarySwiperChange(swiper.activeIndex, swiper.previousIndex)}
                onSwiper={(swiper) => {
                  if (this.state.primarySwiper !== swiper) {
                    this.setState({ primarySwiper: swiper });
                  }
                }}
                initialSlide={1}
                allowSlideNext={!disableNav}
                allowSlidePrev={!disableNav}
                speed={200}
                resistance={true}
                resistanceRatio={0.2}
                noSwiping={true}
                noSwipingClass={"primary-swiper-disable-el"}
                style={{ height: primarySwiperHeight }}
              >
                {/* <SwiperSlide>
                  <Suspense fallback={<></>}>
                    <Categories
                      handleCategorySelection={this.handleCategorySelection}
                      uniqueCategories={uniqueCategories}
                      selectedDistrict={selectedDistrict}
                      onPrimarySwiperChange={this.onPrimarySwiperChange}
                      toggleDistrictSelection={this.toggleDistrictSelection}
                      categoryInterestConfig={categoryInterestConfig}
                      primarySwiperActiveIndex={primarySwiper.activeIndex}
                      language={language}
                      toggleInfoModal={this.toggleInfoModal}
                      devModeEnabled={devModeEnabled}
                      subscribedTopics={this.state.subscribedTopics}
                      toggleOverlay={this.toggleOverlay}
                    />
                  </Suspense>
                </SwiperSlide> */}

                {/* <SwiperSlide>
                  <div className="absolute top-[10vh] left-1/2 -translate-x-1/2 z-10 p-2 hidden justify-center border border-lbr dark:border-dbr bg-lbp dark:bg-dbp rounded-full text-accent refresh-spinner">
                    <span className="animate-spin">
                      <svg
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <polyline points="23 4 23 10 17 10"></polyline>
                        <polyline points="1 20 1 14 7 14"></polyline>
                        <path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path>
                      </svg>
                    </span>
                  </div>

                  <Swiper
                    id="secondarySwiper"
                    onSlideChangeTransitionEnd={(swiper) => {
                      // If app in foreground
                      if (!this.state.appInBackground) {
                        this.nextUpdates(
                          swiper.activeIndex,
                          swiper.previousIndex
                        );
                      }
                    }}
                    onSlideChange={(swiper) => {
                      // If app in background
                      if (this.state.appInBackground) {
                        this.nextUpdates(
                          swiper.activeIndex,
                          swiper.previousIndex
                        );
                      }
                    }}
                    onSwiper={(swiper) => {
                      if (this.state.secondarySwiper !== swiper) {
                        this.setState({ secondarySwiper: swiper });
                      }
                    }}
                    direction={"vertical"}
                    style={{
                      height: "92vh",
                      padding: "1rem",
                      boxSizing: "border-box",
                    }}
                    className="bg-lbs dark:bg-dbs"
                    slidesPerView={1}
                    spaceBetween={20}
                    initialSlide={secondarySwiperInitSlide}
                    virtual={{
                      addSlidesAfter: 3,
                      addSlidesBefore: 0,
                      cache: true,
                    }}
                    speed={300}
                    resistance={false}
                    resistanceRatio={0.1}
                    noSwiping={true}
                    noSwipingClass={"secondary-swiper-disable-el"}
                    onSlideResetTransitionEnd={(swiper) => {
                      // Reload news items on pull down
                      // https://stackoverflow.com/questions/69138900/swiperjs-detect-swipe-direction
                      if (
                        swiper.activeIndex === 0 &&
                        swiper.touches.diff > 0 &&
                        !firstSession
                      ) {
                        window.gtag("event", "feed_refresh_pull");
                        this.handleCategorySelection(null, "", "");
                      }
                    }}
                    onTouchMove={(swiper, event) => {
                      if (
                        swiper.activeIndex === 0 &&
                        swiper.touches.diff > 100 &&
                        !firstSession
                      ) {
                        document.querySelector(
                          ".refresh-spinner"
                        ).style.display = "flex";
                      }
                      event.preventDefault();
                    }}
                    onTransitionEnd={() => {
                      document.querySelector(".refresh-spinner").style.display =
                        "none";
                    }}
                  >
                    {newsItems.map((item, index) => (
                      <SwiperSlide
                        key={item.uid}
                        virtualIndex={index}
                        className="secondary-swiper-slide"
                      >
                        <Feed
                          item={item}
                          category={category}
                          updateUserPref={this.updateUserPref}
                          startSharing={this.startSharing}
                          timerPlaying={timerPlaying}
                          setPlayPauseState={this.setPlayPauseState}
                          appFirstOpen={appFirstOpen}
                          hasUIDInPath={hasUIDInPath}
                          startPlaying={this.startPlaying}
                          androidOpenURL={this.androidOpenURL}
                          next={this.next}
                          automaticMode={automaticMode}
                          secondarySwiper={secondarySwiper}
                          jumpToItem={this.jumpToItem}
                          gotoNewsItem={this.gotoNewsItem}
                          language={language}
                          toggleQuiz={this.toggleQuiz}
                          toggleInfoModal={this.toggleInfoModal}
                          handleCategorySelection={this.handleCategorySelection}
                          guideStatus={this.state.guideStatus}
                          virtualIndex={index}
                          isFeedAudioLoading={this.state.isFeedAudioLoading}
                          firstSession={firstSession}
                          onPrimarySwiperChange={this.onPrimarySwiperChange}
                          updateGlobalStateValue={this.updateGlobalStateValue}
                          screenViewHeight={this.state.screenViewHeight}
                          toggleOverlay={this.toggleOverlay}
                          topTenHighlights={this.state.topTenHighlights}
                        />
                      </SwiperSlide>
                    ))}
                    <SwiperSlide className="secondary-swiper-slide-end text-lts dark:text-dts flex flex-col justify-center">
                      <div>
                        {language === "EN"
                          ? "You've reached at the end of your feed."
                          : "നിങ്ങൾ ഫീഡിന്റെ അവസാനത്തിലെത്തിയിരിക്കുന്നു."}
                      </div>
                      <div
                        className="rounded-full p-4 font-bold bg-accent text-dtp mt-4 flex items-center justify-center"
                        onClick={() => {
                          this.loadNewsItems(true);
                        }}
                      >
                        <span className="mr-2">
                          <FiRefreshCw />
                        </span>
                        Refresh Feed
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </SwiperSlide> */}

                <SwiperSlide>
                  <Suspense fallback={<></>}>
                    <PositiveTab
                      screenViewHeight={this.state.screenViewHeight}
                      language={language}
                      jumpToItem={this.jumpToItem}
                      primarySwiperActiveIndex={primarySwiper.activeIndex}
                      colorTheme={colorTheme}
                      startSharing={this.startSharing}
                      androidOpenURL={this.androidOpenURL}
                      updateUserPref={this.updateUserPref}
                      startGenericSharing={this.startGenericSharing}
                      createShareMessage={this.createShareMessage}
                      onPrimarySwiperChange={this.onPrimarySwiperChange}
                      toggleSettingsPage={this.toggleSettingsPage}
                      showSettingsPage={showSettingsPage}
                      setPositiveTabAudioPlaying={
                        this.setPositiveTabAudioPlaying
                      }
                      appInBackground={appInBackground}
                      setPositiveTabOverlay={this.setPositiveTabOverlay}
                      updateGlobalStateValue={this.updateGlobalStateValue}
                      infoModalConf={this.state.infoModalConf}
                      toggleInfoModal={this.toggleInfoModal}
                      activePositiveItem={this.state.activePositiveItem}
                    />
                  </Suspense>
                </SwiperSlide>

                {/* <SwiperSlide>
                  <Suspense fallback={<></>}>
                    <JobsTab
                      language={language}
                      updateActiveJobItem={this.updateActiveJobItem}
                      updateGlobalStateValue={this.updateGlobalStateValue}
                      isJobSearchOpen={this.state.isJobSearchOpen}
                      showSettingsPage={showSettingsPage}
                      toggleSettingsPage={this.toggleSettingsPage}
                      infoModalConf={infoModalConf}
                      toggleInfoModal={this.toggleInfoModal}
                      primarySwiperActiveIndex={primarySwiper.activeIndex}
                      onPrimarySwiperChange={this.onPrimarySwiperChange}
                    />
                  </Suspense>
                </SwiperSlide> */}

                {/* {!firstSession && (
                  <SwiperSlide>
                    <Suspense fallback={<></>}>
                      <ExtrasTab
                        language={language}
                        primarySwiperActiveIndex={primarySwiper.activeIndex}
                        onPrimarySwiperChange={this.onPrimarySwiperChange}
                        toggleQuiz={this.toggleQuiz}
                        toggleInfoModal={this.toggleInfoModal}
                        infoModalConf={infoModalConf}
                        quizVisible={showQuiz}
                        colorTheme={colorTheme}
                        toggleSettingsPage={this.toggleSettingsPage}
                        showSettingsPage={showSettingsPage}
                        selectedDistrict={selectedDistrict || "KL01"}
                        toggleDistrictSelection={this.toggleDistrictSelection}
                        showDistrictSelection={this.state.showDistrictSelection}
                        toggleOverlay={this.toggleOverlay}
                        activeOverlay={this.state.activeOverlay}
                      />
                    </Suspense>
                  </SwiperSlide>
                )} */}
              </Swiper>

              {/* <BottomNav
                primarySwiperActiveIndex={primarySwiper.activeIndex}
                onPrimarySwiperChange={this.onPrimarySwiperChange}
                unreadNewsCount={unreadNewsCount}
                language={language}
                handleKeyDownEvent={this.handleKeyDownEvent}
                firstSession={firstSession}
              ></BottomNav> */}
            </div>
          )}

          {/* District selection component */}
          {!loading && this.state.showDistrictSelection && (
            <DistrictSelection
              selectDistrict={this.selectDistrict}
              toggleDistrictSelection={this.toggleDistrictSelection}
              language={this.state.language}
            />
          )}

          {/* Share modal */}
          {videoShareInProgress && (
            <div className="absolute w-screen h-screen top-0 z-10 flex flex-col bg-[#000000cc]">
              <div className="grow"></div>
              <div className="p-4 rounded-t bg-lbp dark:bg-dbp flex flex-col items-center">
                <div className="mb-2 text-ltp dark:text-dtp">
                  {language === "EN" ? "Sharing" : "ഷെയർ ചെയ്യുന്നു"}
                </div>
                <span className="animate-spin">
                  <FiLoader size="1.2rem" className="text-accent" />
                </span>
              </div>
            </div>
          )}

          {/* Category selection view */}
          {!loading && showCategoryInterestSelection && (
            <Suspense fallback={<></>}>
              <CategoryInterestSelection
                toggleCategoryInterest={this.toggleCategoryInterest}
                language={language}
                hasCategoryInterestUpdated={this.hasCategoryInterestUpdated}
              />
            </Suspense>
          )}

          {/* Settings view */}
          {!loading && showSettingsPage && (
            <Suspense fallback={<></>}>
              <Settings
                selectedDistrict={this.state.selectedDistrict}
                toggleCategoryInterest={this.toggleCategoryInterest}
                toggleDistrictSelection={this.toggleDistrictSelection}
                toggleInfoModal={this.toggleInfoModal}
                language={language}
                colorTheme={colorTheme}
                toggleSettingsPage={this.toggleSettingsPage}
              />
            </Suspense>
          )}

          {/* Overlay container */}
          {this.state.activeOverlay !== "" && (
            <Suspense fallback={<></>}>
              <Overlays
                language={this.state.language}
                activeOverlay={this.state.activeOverlay}
                toggleOverlay={this.toggleOverlay}
                newsItems={this.state.newsItems}
                onSearchItemSelect={this.onSearchItemSelect}
                gotoNewsItem={this.gotoNewsItem}
                topTenHighlights={this.state.topTenHighlights}
                toggleQuiz={this.toggleQuiz}
                screenViewHeight={this.state.screenViewHeight}
                toggleInfoModal={this.toggleInfoModal}
              />
            </Suspense>
          )}

          {/* Info modal */}
          {infoModalConf.show && (
            <Suspense fallback={<></>}>
              <InfoModal
                conf={infoModalConf}
                toggleInfoModal={this.toggleInfoModal}
                language={language}
                colorTheme={colorTheme}
                toggleLang={this.toggleLang}
                toggleTheme={this.toggleTheme}
                newsItems={newsItems}
                setPlayPauseState={this.setPlayPauseState}
                androidOpenURL={this.androidOpenURL}
                updateUserPref={this.updateUserPref}
                createShareMessage={this.createShareMessage}
                startGenericSharing={this.startGenericSharing}
                startSharing={this.startSharing}
                secondarySwiper={secondarySwiper}
                toggleCategoryInterest={this.toggleCategoryInterest}
                toggleDistrictSelection={this.toggleDistrictSelection}
                activeJobItem={activeJobItem}
                updateGlobalStateValue={this.updateGlobalStateValue}
                toggleOverlay={this.toggleOverlay}
                activePositiveItem={this.state.activePositiveItem}
                userDetails={this.state.userDetails}
              />
            </Suspense>
          )}
        </>

        <Provider config={rollbarConfig}>
          <ErrorBoundary>
            {/* Quiz component */}
            {showQuiz && (
              <Quiz
                language={language}
                toggleInfoModal={this.toggleInfoModal}
                toggleQuiz={this.toggleQuiz}
                gotoNewsItem={this.gotoNewsItem}
                userDetails={this.state.userDetails}
                isAndroidWebView={this.state.isAndroidWebView}
              />
            )}
          </ErrorBoundary>
        </Provider>
      </div>
    );
  }
}

function TestError() {
  const a = null;
  return a.hello();
}

export default App;
