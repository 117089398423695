import React, {Component} from "react";
import localStorage from "../../../services/localStorage";
import Rating from "react-rating";
import {MdStar, MdStarBorder} from 'react-icons/md';

class ReviewUsCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rating: 4.5,
            suggestionDialogue: false,
        };
    }

    componentDidMount() {
        // ttl of 2 days by default
        // If a rating is selected, ttl becomes infinity
        localStorage.setItem('reviewUsCardShown', true, 2*24*60*60*1000);
    }

    handleRatingClick = async (rating) => {
        this.setState({rating: rating});
        await localStorage.setItem('reviewUsCardShown', true);
        window.gtag('event', `event_review_us_card_rating_${rating}`);

        if (rating < 5) {
            this.setState({suggestionDialogue: true});
        } else {
            this.setState({suggestionDialogue: false});
            window.open('https://play.google.com/store/apps/details?id=today.katha');
        }
    };

    render() {
        const {
            item
        } = this.props;

        const {
            rating, suggestionDialogue
        } = this.state;

        // Text based on selected language
        let textContent = this.props.language==='EN' ? {
            mainText : 'Enjoying Katha App? Rate us on PlayStore.',
            suggestionText : "We'll be happy to hear about suggestions and complaints.",
            suggestionBtn : "Talk To Us!",
        } : {
            mainText : 'നിങ്ങൾക്ക് കഥ ആപ്പ് ഇഷ്ടമായോ? താഴെ ക്ലിക്കു ചെയ്‌ത് അപ്ലിക്കേഷൻ റേറ്റു ചെയ്യുക.',
            suggestionText : "ആപ്പ് ഉപയോഗിക്കുന്നതിൽ വല്ല ബുദ്ധിമുട്ടോ മറ്റെന്തെങ്കിലും അഭിപ്രായ-നിർദ്ദേശങ്ങളോ ഉണ്ടെങ്കിൽ ഞങ്ങളോട് നേരിട്ട് പറയാം.",
            suggestionBtn : "ഞങ്ങളോട് പറയു!",
        }

        return (
            <div className='h-full border border-lbr dark:border-dbr flex flex-col rounded bg-lbp dark:bg-dbp'>
                <div className="grow flex items-center justify-center">
                    <div className="px-4">
                        <div className="text-ltp dark:text-dtp mb-4">
                            {textContent.mainText}
                        </div>

                        {/* Rating  */}
                        <div className="border border-lbr dark:border-dbr rounded p-4 mb-4">
                            <Rating
                                initialRating={rating}
                                emptySymbol={<MdStarBorder className="text-4xl text-gold mx-1"/>}
                                fullSymbol={<MdStar className="text-4xl text-gold mx-1"/>}
                                onChange={(rating) => this.handleRatingClick(rating)}
                            />
                        </div>

                        {suggestionDialogue && <>
                            <div className="text-ltp dark:text-dtp mb-4">
                                {textContent.suggestionText}
                            </div>

                            <div className="bg-accent p-4 font-bold text-dtp mt-4 flex items-center justify-center rounded-full" style={{padding:'1rem'}} onClick={() => {
                                window.gtag('event', 'event_feedbacklink');
                                window.open('http://wa.me/917907741997?text=Hello%20%E0%B4%95%E0%B4%A5!');
                            }}>
                                <span className="mr-2">
                                    <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" ><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg>
                                </span>
                                {textContent.suggestionBtn}
                            </div>
                        </>}

                        {!suggestionDialogue && <>
                            <div className="bg-accent p-4 font-bold text-dtp mt-4 flex items-center justify-center rounded-full" style={{padding:'1rem'}} onClick={() => {
                                window.open('https://play.google.com/store/apps/details?id=today.katha');
                            }}>
                                <span className="mr-2">
                                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
                                </span>
                                {this.props.language==='EN' ? 'Rate on Playstore' : 'റേറ്റ് ചെയ്യാം'}
                            </div>
                        </>}

                    </div>
                </div>
                <div className="flex justify-center text-accent pb-4 animate-bounce" onClick={()=>{this.props.secondarySwiper.slideNext()}}>
                    <span>
                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round"><polyline points="17 11 12 6 7 11"></polyline><polyline points="17 18 12 13 7 18"></polyline></svg>
                    </span>
                </div>
            </div>

        );
    }
}

export default ReviewUsCard;