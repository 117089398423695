import React, {Component} from "react";

class UnreadCompletionCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            confetti: false
        };
    }

    render() {
        const {
            item
        } = this.props;

        const {
            confetti
        } = this.state;

        // Text based on selected language
        let textContent = this.props.language==='EN' ? {
            mainText: 'Good Job',
            subText1: `You saved`,
            subText2: 'minutes by covering all the important news in short.',
            suggestionText: 'Swipe up for more news. Visit the positive or extra tabs for other information'
        } : {
            mainText: 'അഭിനന്ദനങ്ങൾ!',
            subText1: `നിങ്ങളുടെ വിലയേറിയ`,
            subText2: 'മിനുട്ടുകൾ കൂടി കഥ ആപ്പിലൂടെ വാർത്തകൾ ചുരുക്കത്തിൽ അറിഞ്ഞത് വഴി ലാഭിച്ചിരിക്കുന്നു.',
            suggestionText: 'കൂടുതൽ വാർത്തകൾക്കായി മുകളിലോട്ട് സ്വൈപ്പ് ചെയ്യുക. മറ്റു അറിവുകൾക്കായി പോസിറ്റീവ്/കൂടുതൽ ടാബുകൾ സന്ദർശിക്കുക'
        }

        if (window.global__viewportUid === item.uid && !this.state.confetti) {
            this.setState({confetti: true});
        }

        return (
            <div className='h-full border border-lbr dark:border-dbr flex flex-col rounded bg-lbp dark:bg-dbp'>
                <div className="grow flex items-center justify-center">
                    <div className="px-4">
                        <div className="text-4xl mb-4">🎉🎉🎉</div>
                        <div className="text-lg text-accent font-bold mb-2">
                            {textContent.mainText}
                        </div>
                        <div className="text-ltp dark:text-dtp mb-2">
                            <span>
                                {textContent.subText1}
                            </span>
                            <span className="text-accent mx-2 font-bold">
                                {parseInt(item.total_unread_count / 2)}
                            </span>
                            <span>
                                {textContent.subText2}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="text-lts dark:text-dts text-sm p-4">
                    {textContent.suggestionText}
                </div>
                <div className="flex justify-center text-accent pb-4 animate-bounce" onClick={()=>{this.props.secondarySwiper.slideNext()}}>
                    <span>
                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round"><polyline points="17 11 12 6 7 11"></polyline><polyline points="17 18 12 13 7 18"></polyline></svg>
                    </span>
                </div>
            </div>
        );
    }
}

export default UnreadCompletionCard;