import React, { useState } from "react";
import "./index.css";

const NewsItemBottomActions = (props) => {
  return (
    <div className="flex p-4 relative">
      {/* Play/pause option */}
      <span
        className="text-accent flex items-center justify-center grow relative"
        onClick={() => {
          if (props.guideStatus.playTooltip && props.virtualIndex === 1) {
            // setConfetti(true);
            window.logFacebookEvent("event_guide_play_completed");
            window.gtag("event", "event_guide_play_completed");
            window.logFirebaseEvent("guide_click_play");
          }
          props.onPlayPauseClick();
        }}
        style={{
          filter:
            props.guideStatus.readmoreTooltip && props.virtualIndex === 2
              ? "grayscale(100%)"
              : "",
        }}
      >
        {/* Show play btn by default */}
        {!props.timerPlaying && (
          <>
            <svg
              viewBox="0 0 24 24"
              width="24"
              height="24"
              stroke="currentColor"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polygon points="5 3 19 12 5 21 5 3"></polygon>
            </svg>
          </>
        )}

        {/* Show loader if in played state and audio is being loaded */}
        {props.timerPlaying && props.isFeedAudioLoading && (
          <span className="animate-spin">
            <svg
              viewBox="0 0 24 24"
              width="24"
              height="24"
              stroke="currentColor"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line x1="12" y1="2" x2="12" y2="6"></line>
              <line x1="12" y1="18" x2="12" y2="22"></line>
              <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
              <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
              <line x1="2" y1="12" x2="6" y2="12"></line>
              <line x1="18" y1="12" x2="22" y2="12"></line>
              <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
              <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
            </svg>
          </span>
        )}

        {/* Show pause if audio is being played */}
        {props.timerPlaying && !props.isFeedAudioLoading && (
          <>
            <svg
              viewBox="0 0 24 24"
              width="24"
              height="24"
              stroke="currentColor"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <rect x="6" y="4" width="4" height="16"></rect>
              <rect x="14" y="4" width="4" height="16"></rect>
            </svg>
          </>
        )}

        {/* Tooltip */}
        {props.guideStatus.playTooltip && props.virtualIndex === 1 && (
          <div className="bg-dbs rounded p-4 text-sm font-bold text-dtp whitespace-nowrap absolute guide-tooltip left-0 bottom-10">
            വാർത്ത കേട്ടറിയുവാൻ <br /> ഇവിടെ ക്ലിക്ക് ചെയ്യുക.
          </div>
        )}
      </span>

      {/* Read more option */}
      <span
        className="relative text-accent flex items-center grow justify-center"
        onClick={() => {
          if (!props.guideStatus.playTooltip || props.virtualIndex !== 1) {
            if (props.guideStatus.readmoreTooltip && props.virtualIndex === 2) {
              window.gtag("event", "event_guide_readmore_completed");
              window.logFirebaseEvent("guide_click_read_more");
            }
            props.onReadMoreClick();
          }
        }}
        style={{
          filter:
            props.guideStatus.playTooltip && props.virtualIndex === 1
              ? "grayscale(100%)"
              : "",
        }}
      >
        <svg
          viewBox="0 0 24 24"
          width="24"
          height="24"
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
          <polyline points="15 3 21 3 21 9"></polyline>
          <line x1="10" y1="14" x2="21" y2="3"></line>
        </svg>
        {/* Tooltip */}
        {props.guideStatus.readmoreTooltip && props.virtualIndex === 2 && (
          <div className="bg-dbs rounded p-4 text-sm font-bold text-dtp whitespace-nowrap absolute guide-tooltip left-0 bottom-10">
            കൂടുതൽ വായിക്കുവാൻ <br /> ഇവിടെ ക്ലിക്ക് ചെയ്യുക.
          </div>
        )}
      </span>

      {/* Share option */}
      <span
        className="relative text-accent flex items-center grow justify-center"
        onClick={() => {
          if (
            !(
              (props.guideStatus.readmoreTooltip && props.virtualIndex === 2) ||
              (props.guideStatus.playTooltip && props.virtualIndex === 1)
            )
          ) {
            props.onShareClick();
          }
        }}
        style={{
          filter:
            (props.guideStatus.readmoreTooltip && props.virtualIndex === 2) ||
            (props.guideStatus.playTooltip && props.virtualIndex === 1)
              ? "grayscale(100%)"
              : "",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          enableBackground="new 0 0 24 24"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="currentColor"
        >
          <g>
            <rect fill="none" height="24" width="24" y="0" />
            <rect fill="none" height="24" width="24" y="0" />
          </g>
          <g>
            <g>
              <g>
                <path d="M19.05,4.91C17.18,3.03,14.69,2,12.04,2c-5.46,0-9.91,4.45-9.91,9.91c0,1.75,0.46,3.45,1.32,4.95L2.05,22l5.25-1.38 c1.45,0.79,3.08,1.21,4.74,1.21h0c0,0,0,0,0,0c5.46,0,9.91-4.45,9.91-9.91C21.95,9.27,20.92,6.78,19.05,4.91z M12.04,20.15 L12.04,20.15c-1.48,0-2.93-0.4-4.2-1.15l-0.3-0.18l-3.12,0.82l0.83-3.04l-0.2-0.31c-0.82-1.31-1.26-2.83-1.26-4.38 c0-4.54,3.7-8.24,8.24-8.24c2.2,0,4.27,0.86,5.82,2.42c1.56,1.56,2.41,3.63,2.41,5.83C20.28,16.46,16.58,20.15,12.04,20.15z M16.56,13.99c-0.25-0.12-1.47-0.72-1.69-0.81c-0.23-0.08-0.39-0.12-0.56,0.12c-0.17,0.25-0.64,0.81-0.78,0.97 c-0.14,0.17-0.29,0.19-0.54,0.06c-0.25-0.12-1.05-0.39-1.99-1.23c-0.74-0.66-1.23-1.47-1.38-1.72c-0.14-0.25-0.02-0.38,0.11-0.51 c0.11-0.11,0.25-0.29,0.37-0.43c0.12-0.14,0.17-0.25,0.25-0.41c0.08-0.17,0.04-0.31-0.02-0.43c-0.06-0.12-0.56-1.34-0.76-1.84 c-0.2-0.48-0.41-0.42-0.56-0.43C8.86,7.33,8.7,7.33,8.53,7.33c-0.17,0-0.43,0.06-0.66,0.31C7.65,7.89,7.01,8.49,7.01,9.71 c0,1.22,0.89,2.4,1.01,2.56c0.12,0.17,1.75,2.67,4.23,3.74c0.59,0.26,1.05,0.41,1.41,0.52c0.59,0.19,1.13,0.16,1.56,0.1 c0.48-0.07,1.47-0.6,1.67-1.18c0.21-0.58,0.21-1.07,0.14-1.18S16.81,14.11,16.56,13.99z" />
              </g>
            </g>
          </g>
        </svg>
      </span>

      {/* More options modal */}
      <span
        className="relative text-accent flex items-center grow justify-center"
        onClick={() => {
          if (
            !(
              (props.guideStatus.readmoreTooltip && props.virtualIndex === 2) ||
              (props.guideStatus.playTooltip && props.virtualIndex === 1)
            )
          ) {
            props.toggleInfoModal(true, "news_more_options");
          }
        }}
        style={{
          filter:
            (props.guideStatus.readmoreTooltip && props.virtualIndex === 2) ||
            (props.guideStatus.playTooltip && props.virtualIndex === 1)
              ? "grayscale(100%)"
              : "",
        }}
      >
        <svg
          viewBox="0 0 24 24"
          width="24"
          height="24"
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle cx="12" cy="12" r="1"></circle>
          <circle cx="12" cy="5" r="1"></circle>
          <circle cx="12" cy="19" r="1"></circle>
        </svg>
      </span>
    </div>
  );
};

const NewsCard = (props) => {
  // News source
  const [source, setSource] = useState(null);

  // Whether RTP or not
  const [isRTP, setIsRTP] = useState(false);

  // Whether RTD or not
  const isRTD = props.item.categories && props.item.categories.includes("RTD");

  // Whether feed ad or not
  const isFeedAd =
    props.item.categories && props.item.categories.includes("FEED_AD");

  const [isHighlight, setHighlight] = useState(false);
  const [audioDuration, setAudioDuration] = useState(null);
  const [timestamp, setTimestamp] = useState("");
  const [curTime, setCurTime] = useState(0);

  const endAudio = async () => {
    const audio = document.getElementById("audio");
    if (!!audio) {
      await audio.pause();
      audio.currentTime = 0;
    }
  };

  const handleKeyDownEvent = (event, uid) => {
    if (event.key === "a" || event.key === "A") {
      endAudio();
    }
  };

  const readableDuration = (seconds) => {
    let sec = Math.floor(seconds);
    let min = Math.floor(sec / 60);
    min = min >= 10 ? min : "0" + min;
    sec = Math.floor(sec % 60);
    sec = sec >= 10 ? sec : "0" + sec;
    return min + ":" + sec;
  };

  const {
    item,
    updateUserPref,
    startSharing,
    timerPlaying,
    setPlayPauseState,
    appFirstOpen,
    hasUIDInPath,
    startPlaying,
    androidOpenURL,
  } = props;

  const [newsTitle, setNewsTitle] = useState(item.title);

  React.useEffect(() => {
    window.addEventListener("keydown", (event) =>
      handleKeyDownEvent(event, item.uid)
    );

    window.cacheImage(item.image_link);

    // Check source
    if (!item.district) {
      if (
        item.categories &&
        (item.categories.includes("Special") ||
          item.categories.includes("Stories"))
      ) {
        setSource("SPECIAL");
      } else if (item.categories && item.categories.includes("Non-News")) {
        setSource("Non-News");
      } else if (item.source) {
        setSource(item.source);
      }
    }

    // Check if highlight or not
    if (item.Highlight) {
      setHighlight(true);
    }

    if (!!item.enabled_at) {
      const dateOneObj = new Date(item.enabled_at);
      const dateTwoObj = new Date();
      const milliseconds = Math.abs(dateTwoObj - dateOneObj);
      const hours = milliseconds / 36e5;
      if (props.language === "EN") {
        if (hours < 1) {
          setTimestamp("A while ago");
        } else if (hours >= 1 && hours < 2) {
          setTimestamp("1 hour ago");
        } else if (hours >= 2 && hours < 3) {
          setTimestamp("2 hours ago");
        } else if (hours >= 3 && hours < 5) {
          setTimestamp("3 hours ago");
        }
      } else {
        if (hours < 1) {
          setTimestamp("അല്പം മുമ്പ്");
        } else if (hours >= 1 && hours < 2) {
          setTimestamp("1 മണിക്കൂർ മുമ്പ്");
        } else if (hours >= 2 && hours < 3) {
          setTimestamp("2 മണിക്കൂർ മുമ്പ്");
        } else if (hours >= 3 && hours < 5) {
          setTimestamp("3 മണിക്കൂർ മുമ്പ്");
        }
      }
    }

    if (!!item.audio_link) {
      if (!!item.audio_duration) {
        setAudioDuration(readableDuration(item.audio_duration));
      } else {
        let audioElem = new Audio(item.audio_link);
        audioElem.addEventListener("loadeddata", () => {
          setAudioDuration(readableDuration(audioElem.duration));
        });
      }
    }

    // Trim if title exceeds a particular length
    if (item.title.length > 100) {
      let trimmedTitle = item.title.slice(0, 100);
      // If there are no period(.) at end, add ellipsis
      if (trimmedTitle[trimmedTitle.length - 1] !== ".") {
        trimmedTitle += ". . .";
      }
      setNewsTitle(trimmedTitle);
    }

    // Check if the item has RTP category
    if (item.categories && item.categories.includes("RTP")) {
      setIsRTP(true);
    }

    // Add time change event listener to audio
    const audioEl = document.getElementById("audio");
    const setAudioTime = () => setCurTime(audioEl.currentTime);
    audioEl.addEventListener("timeupdate", setAudioTime);

    // cleanup this component
    return () => {
      audioEl.removeEventListener("timeupdate", setAudioTime);
      window.removeEventListener("keydown", (event) =>
        handleKeyDownEvent(event, item.uid)
      );
    };
  }, []);

  // On clicking share button
  const onShareClick = () => {
    updateUserPref(item, "share");
    startSharing(item);
  };

  // On clicking play/pause button
  const onPlayPauseClick = async () => {
    // If playing, pause
    if (timerPlaying) {
      setPlayPauseState("pause");
    } else {
      if (appFirstOpen || hasUIDInPath) {
        await startPlaying(item, hasUIDInPath, appFirstOpen);
      }
      setPlayPauseState("play");
      updateUserPref(item, "play");

      // Analytics
      window.gtag("event", `event_newsplay_uid_${item.uid}`);
      if (!!item.categories) {
        item.categories.forEach((category) => {
          window.gtag("event", `event_newsplay_category_${category}`);
        });
      }
    }
  };

  // On clicking read more button
  const onReadMoreClick = () => {
    if (!!item.link) {
      // Pause audio
      setPlayPauseState("pause");

      androidOpenURL(item);
      updateUserPref(item, "readmore");

      window.gtag("event", "read_more_by_uid_" + item.uid);
      window.logFirebaseEvent("read_more_by_uid_" + item.uid);
      if (item.district) {
        window.gtag("event", "district_read_more_" + item.district);
        window.logFirebaseEvent("district_read_more_" + item.district);
      } else {
        window.gtag("event", "event_readmore_click");
        window.logFirebaseEvent("feed_read_more");
      }
      if (item.source) {
        window.gtag("event", "read_more_by_source_" + item.source);
        window.logFirebaseEvent("read_more_by_source_" + item.source);
      }
    } else {
      // Pause audio
      setPlayPauseState("pause");
      // Fallback to website
      window.androidOpenURL("https://katha.today");
    }
  };

  // On positive tab redirection
  const redirectToPositive = () => {
    window.gtag("event", `rtp_click_` + item.uid);
    // Add query params in URL
    var newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      "?tab=positive&uid=" +
      item.uid;
    window.history.pushState({ path: newurl }, "", newurl);
    // Move to positive tab
    props.onPrimarySwiperChange(2, 1);
  };

  // Redirect to district news
  const redirectToDistrict = () => {
    window.gtag("event", `rtd_click_` + item.uid);
    // Find district name
    const districtNameMapping =
      props.language === "EN"
        ? {
            KL01: "Trivandrum",
            KL02: "Kollam",
            KL03: "Pathanamthitta",
            KL04: "Alappuzha",
            KL05: "Kottayam",
            KL06: "Idukki",
            KL07: "Ernakulam",
            KL08: "Thrissur",
            KL09: "Palakkad",
            KL10: "Malappuram",
            KL11: "Kozhikode",
            KL12: "Wayanad",
            KL13: "Kannur",
            KL14: "Kasaragod",
          }
        : {
            KL01: "തിരുവനന്തപുരം",
            KL02: "കൊല്ലം",
            KL03: "പത്തനംതിട്ട",
            KL04: "ആലപ്പുഴ",
            KL05: "കോട്ടയം",
            KL06: "ഇടുക്കി",
            KL07: "എറണാകുളം",
            KL08: "തൃശ്ശൂർ",
            KL09: "പാലക്കാട്",
            KL10: "മലപ്പുറം",
            KL11: "കോഴിക്കോട്",
            KL12: "വയനാട്",
            KL13: "കണ്ണൂർ",
            KL14: "കാസർഗോഡ്",
          };
    let districtName = districtNameMapping[item.district];
    // Go to category
    props.handleCategorySelection(
      "DISTRICT_" + item.district,
      districtName,
      districtName
    );
    // Mark as shown
    localStorage.setItem("lastShownRTD", item.uid);
  };

  return (
    <div
      className={
        "h-full border border-lbr dark:border-dbr text-ltp dark:text-dtp flex flex-col rounded bg-lbp dark:bg-dbp select-none text-left overflow-hidden " +
        item.uid
      }
    >
      <div
        className={`h-[35vh] relative border-b border-lbr dark:border-dbr news-img-${item.uid}`}
        onClick={() => {
          if (props.item.district) {
            props.toggleInfoModal(true, "district_news_more_options");
          } else {
            props.toggleInfoModal(true, "news_more_options");
          }
        }}
      >
        <img
          src={item.image_link}
          className="h-full w-full absolute top-0 object-cover object-center"
        />
        {timestamp && (
          <div className="bg-[#000000cc] text-dtp absolute bottom-2 left-4 text-sm rounded-sm px-2 py-1 flex items-center">
            {timestamp}
          </div>
        )}
        {!!audioDuration && (
          <div className="bg-[#000000cc] text-dtp absolute bottom-2 right-4 text-sm rounded-sm px-2 py-1 flex items-center">
            <span className="mr-1">
              <svg
                viewBox="0 0 24 24"
                width="12"
                height="12"
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M3 18v-6a9 9 0 0 1 18 0v6"></path>
                <path d="M21 19a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h3zM3 19a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2H3z"></path>
              </svg>
            </span>
            {audioDuration}
          </div>
        )}
      </div>

      <div className="flex flex-col grow py-2">
        <div
          className="font-bold text-lg px-4 pb-2"
          onClick={() => {
            if (props.item.district) {
              props.toggleInfoModal(true, "district_news_more_options");
            } else {
              props.toggleInfoModal(true, "news_more_options");
            }
          }}
        >
          {newsTitle}
        </div>

        <div
          className="text-lts dark:text-dts line-clamp-10 px-4"
          onClick={() => {
            if (props.item.district) {
              props.toggleInfoModal(true, "district_news_more_options");
            } else {
              props.toggleInfoModal(true, "news_more_options");
            }
          }}
        >
          {source && source === "Non-News" && (
            <span className="font-bold text-accent">അറിവിന്റെ ലോകം</span>
          )}
          {source && source === "SPECIAL" && (
            <span className="font-bold text-accent">കഥ-സ്പെഷ്യൽ</span>
          )}
          {source && source !== "SPECIAL" && source !== "Non-News" && (
            <span className="font-bold text-lts dark:text-dts">{source}</span>
          )}
          {source && (
            <span className="font-bold text-lts dark:text-dts mx-2">•</span>
          )}
          <span className="break-words">{item.content}</span>
        </div>

        {/* <div className="flex px-4">
          {isHighlight && (
            <span
              className="flex mt-2 bg-lbs dark:bg-dbs text-accent text-sm items-center px-2 py-1 rounded-sm"
              onClick={() => {
                window.gtag("event", "top_ten_from_news");
                // Pause audio
                setPlayPauseState("pause");
                // Open stories
                props.toggleOverlay("STORIES_TOP10");
              }}
            >
              <span className="mr-2">
                <svg
                  viewBox="0 0 24 24"
                  width="12"
                  height="12"
                  stroke="currentColor"
                  strokeWidth="2"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
                  <polyline points="17 6 23 6 23 12"></polyline>
                </svg>
              </span>
              {props.language === "EN" ? "Top-10" : "ടോപ്-10"}
            </span>
          )}
        </div> */}
      </div>

      {(!props.guideStatus.swipeLeft || ![7].includes(props.virtualIndex)) &&
        !isRTP &&
        !isRTD &&
        !isFeedAd && (
          <>
            {/* Audio seeker */}
            <div className="h-px bg-lbr dark:bg-dbr flex">
              <div
                className="h-px bg-accent transition-all duration-500 ease-linear"
                style={{ width: (curTime / item.audio_duration) * 100 + "%" }}
              ></div>
            </div>

            {/* Options  */}
            {!props.item.district && (
              <NewsItemBottomActions
                onPlayPauseClick={onPlayPauseClick}
                onReadMoreClick={onReadMoreClick}
                timerPlaying={props.timerPlaying}
                language={props.language}
                onShareClick={onShareClick}
                toggleInfoModal={props.toggleInfoModal}
                guideStatus={props.guideStatus}
                virtualIndex={props.virtualIndex}
                isFeedAudioLoading={props.isFeedAudioLoading}
              />
            )}

            {props.item.district && (
              <div className="flex items-center p-4">
                <span
                  className="text-lts dark:text-dts grow text-xs"
                  onClick={() => {
                    window.gtag("event", "powered_by_click");
                  }}
                >
                  {item.source && (
                    <>
                      Powered by <br />{" "}
                      <span className="font-bold">{item.source}</span>
                    </>
                  )}
                </span>
                <span
                  className="relative text-accent flex items-center ml-6"
                  onClick={() => {
                    onReadMoreClick();
                  }}
                >
                  <svg
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                    <polyline points="15 3 21 3 21 9"></polyline>
                    <line x1="10" y1="14" x2="21" y2="3"></line>
                  </svg>
                </span>
                <span
                  className="relative text-accent flex items-center ml-6"
                  onClick={() => {
                    onShareClick();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    enableBackground="new 0 0 24 24"
                    height="24px"
                    viewBox="0 0 24 24"
                    width="24px"
                    fill="currentColor"
                  >
                    <g>
                      <rect fill="none" height="24" width="24" y="0" />
                      <rect fill="none" height="24" width="24" y="0" />
                    </g>
                    <g>
                      <g>
                        <g>
                          <path d="M19.05,4.91C17.18,3.03,14.69,2,12.04,2c-5.46,0-9.91,4.45-9.91,9.91c0,1.75,0.46,3.45,1.32,4.95L2.05,22l5.25-1.38 c1.45,0.79,3.08,1.21,4.74,1.21h0c0,0,0,0,0,0c5.46,0,9.91-4.45,9.91-9.91C21.95,9.27,20.92,6.78,19.05,4.91z M12.04,20.15 L12.04,20.15c-1.48,0-2.93-0.4-4.2-1.15l-0.3-0.18l-3.12,0.82l0.83-3.04l-0.2-0.31c-0.82-1.31-1.26-2.83-1.26-4.38 c0-4.54,3.7-8.24,8.24-8.24c2.2,0,4.27,0.86,5.82,2.42c1.56,1.56,2.41,3.63,2.41,5.83C20.28,16.46,16.58,20.15,12.04,20.15z M16.56,13.99c-0.25-0.12-1.47-0.72-1.69-0.81c-0.23-0.08-0.39-0.12-0.56,0.12c-0.17,0.25-0.64,0.81-0.78,0.97 c-0.14,0.17-0.29,0.19-0.54,0.06c-0.25-0.12-1.05-0.39-1.99-1.23c-0.74-0.66-1.23-1.47-1.38-1.72c-0.14-0.25-0.02-0.38,0.11-0.51 c0.11-0.11,0.25-0.29,0.37-0.43c0.12-0.14,0.17-0.25,0.25-0.41c0.08-0.17,0.04-0.31-0.02-0.43c-0.06-0.12-0.56-1.34-0.76-1.84 c-0.2-0.48-0.41-0.42-0.56-0.43C8.86,7.33,8.7,7.33,8.53,7.33c-0.17,0-0.43,0.06-0.66,0.31C7.65,7.89,7.01,8.49,7.01,9.71 c0,1.22,0.89,2.4,1.01,2.56c0.12,0.17,1.75,2.67,4.23,3.74c0.59,0.26,1.05,0.41,1.41,0.52c0.59,0.19,1.13,0.16,1.56,0.1 c0.48-0.07,1.47-0.6,1.67-1.18c0.21-0.58,0.21-1.07,0.14-1.18S16.81,14.11,16.56,13.99z" />
                        </g>
                      </g>
                    </g>
                  </svg>
                </span>
                <span
                  className="relative text-accent flex items-center ml-6"
                  onClick={() => {
                    props.toggleInfoModal(true, "district_news_more_options");
                  }}
                >
                  <svg
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <circle cx="12" cy="12" r="1"></circle>
                    <circle cx="12" cy="5" r="1"></circle>
                    <circle cx="12" cy="19" r="1"></circle>
                  </svg>
                </span>
              </div>
            )}
          </>
        )}

      {/* Guide */}
      {props.guideStatus.swipeLeft && (
        <>
          {props.virtualIndex === 7 && (
            <div
              className="bg-gradient-to-b from-[#2ab1ba] to-[#0396ff] flex items-center text-dtp p-4 text-sm"
              onClick={() => {
                window.gtag("event", `guide_swipe_page7_click`);
                window.logFirebaseEvent("guide_click_swipe_left");
                props.onPrimarySwiperChange(0, 1);
              }}
            >
              <div className="mr-2 animate-bounce-h">
                <svg
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  stroke="currentColor"
                  strokeWidth="2"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <polyline points="11 17 6 12 11 7"></polyline>
                  <polyline points="18 17 13 12 18 7"></polyline>
                </svg>
              </div>
              <span>
                വൈവിധ്യമാർന്ന വാർത്തക്കായി വലത്തോട്ട് സ്വൈപ്പ് ചെയ്യുക.
              </span>
            </div>
          )}
        </>
      )}

      {/* Redirection to positive */}
      {isRTP && (
        <>
          <div
            className="bg-gradient-to-b from-[#2ab1ba] to-[#0396ff] flex items-center text-dtp p-4 text-sm"
            onClick={() => {
              redirectToPositive();
            }}
          >
            <span>പൂർണ്ണമായി കേൾക്കാനായി ഇവിടെ ക്ലിക്ക് ചെയ്യുക.</span>
            <span className="ml-2 animate-bounce-h">
              <svg
                viewBox="0 0 24 24"
                width="24"
                height="24"
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <polyline points="13 17 18 12 13 7"></polyline>
                <polyline points="6 17 11 12 6 7"></polyline>
              </svg>
            </span>
          </div>
        </>
      )}

      {/* Redirection to district category */}
      {isRTD && (
        <>
          <div
            className="bg-gradient-to-b from-[#2ab1ba] to-[#0396ff] flex items-center text-dtp p-4 text-sm"
            onClick={() => {
              redirectToDistrict();
            }}
          >
            <div className="mr-2 animate-bounce-h">
              <svg
                viewBox="0 0 24 24"
                width="24"
                height="24"
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <polyline points="11 17 6 12 11 7"></polyline>
                <polyline points="18 17 13 12 18 7"></polyline>
              </svg>
            </div>
            <span>
              നിങ്ങളുടെ ജില്ലയിലെ കൂടുതൽ വാർത്തകൾ വായിക്കാനായി ഇവിടെ ക്ലിക്ക്
              ചെയ്യുക.
            </span>
          </div>
        </>
      )}

      {/* Redirection to ad page */}
      {isFeedAd && (
        <>
          <div
            className="bg-gradient-to-b from-[#2ab1ba] to-[#0396ff] flex items-center text-dtp p-4 text-sm"
            onClick={() => {
              window.gtag("event", `feed_ad_click`);
              onReadMoreClick();
            }}
          >
            <span className="grow">
              {props.language === "EN"
                ? "Click here to know more"
                : "കൂടുതൽ അറിയാൻ ഇവിടെ ക്ലിക്ക് ചെയ്യുക"}
            </span>
            <div className="ml-2">
              <svg
                viewBox="0 0 24 24"
                width="24"
                height="24"
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                <polyline points="15 3 21 3 21 9"></polyline>
                <line x1="10" y1="14" x2="21" y2="3"></line>
              </svg>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default React.memo(NewsCard);
