import React, {Component} from "react";

class DistrictSelection extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    renderDistrictCard = (districtML, districtNumber, districtEN) => {
        return (
            <div className="w-[48%] h-32 rounded overflow-hidden mb-4 relative border border-lbr dark:border-dbr" onClick={() => this.props.selectDistrict(districtNumber)}>
                <img className='w-full h-full object-cover object-center' src={require(`../../assets/districts/${districtNumber}.jpg`)} alt=""/>
                <div className="absolute w-full h-full bg-gradient-to-t from-dbs to-transparent top-0 flex items-end justify-center pb-4 font-bold text-dtp">
                    <span>{this.props.language==='EN' ? districtEN : districtML}</span>
                </div>
            </div>
        );
    };

    render() {

        // Text based on selected language
        let textContent = this.props.language==='EN' ? {
            header : 'District',
            desc : 'Select district to hear news from your district.',
            notInterestedBtn : 'Not Interested'
        } : {
            header : 'ജില്ല',
            desc : 'പ്രാദേശിക വാർത്തകൾ അറിയാനായി ജില്ല തിരഞ്ഞെടുക്കുക.',
            notInterestedBtn : 'താൽപ്പര്യമില്ല'
        }

        return (
            <div className="bg-lbs dark:bg-dbs text-ltp dark:text-dtp absolute top-0 w-screen h-screen text-left z-20 overflow-auto">

                <div className='h-[8vh] flex items-center border-b border-lbr dark:border-dbr sticky top-0 px-4 text-accent bg-lbp dark:bg-dbp z-30' onClick={() => {this.props.toggleDistrictSelection(false)}}>
                    <span>
                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>
                    </span>
                    <span className="text-ltp dark:text-dtp font-bold grow text-center">
                        {this.props.language==='EN' ? 'District' : 'ജില്ല'}
                    </span>
                    <span className="opacity-0">
                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                    </span>
                </div>

                <div className="m-4 text-center text-sm text-lts dark:text-dts">
                    {textContent.desc}
                </div>

                <div className='bg-accent p-4 mx-4 mb-4 text-dtp font-bold rounded-full flex justify-center' onClick={()=>this.props.selectDistrict('not_interested')}>
                    <span className="mr-2">
                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="4.93" y1="4.93" x2="19.07" y2="19.07"></line></svg>
                    </span>
                    <span>
                        {this.props.language==='EN' ? 'Not Interested' : 'താൽപ്പര്യമില്ല'}
                    </span>
                </div>

                <div className="flex flex-wrap mx-4 justify-between">
                    {this.renderDistrictCard("തിരുവനന്തപുരം", "KL01", "Trivandrum")}
                    {this.renderDistrictCard("കൊല്ലം", "KL02", "Kollam")}
                    {this.renderDistrictCard("പത്തനംതിട്ട", "KL03", "Pathanamthitta")}
                    {this.renderDistrictCard("ആലപ്പുഴ ", "KL04", "Alappuzha")}
                    {this.renderDistrictCard("കോട്ടയം", "KL05", "Kottayam")}
                    {this.renderDistrictCard("ഇടുക്കി", "KL06", "Idukki")}
                    {this.renderDistrictCard("എറണാകുളം", "KL07", "Ernakulam")}
                    {this.renderDistrictCard("തൃശ്ശൂർ", "KL08", "Thrissur")}
                    {this.renderDistrictCard("പാലക്കാട്", "KL09", "Palakkad")}
                    {this.renderDistrictCard("മലപ്പുറം", "KL10", "Malappuram")}
                    {this.renderDistrictCard("കോഴിക്കോട്", "KL11", "Kozhikode")}
                    {this.renderDistrictCard("വയനാട്", "KL12", "Wayanad")}
                    {this.renderDistrictCard("കണ്ണൂർ", "KL13", "Kannur")}
                    {this.renderDistrictCard("കാസർഗോഡ്", "KL14", "Kasaragod")}
                </div>

            </div>
        );
    }
}

export default DistrictSelection;