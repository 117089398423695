import React, { Component } from "react";
import CategoryInterestCard from "./CategoryInterestCard";
import DistrictSelectionCard from "./DistrictSelectionCard";
import EndCard from "./EndCard";
import IntroCard from "./IntroCard";
import UnreadCompletionCard from "./UnreadCompletionCard";
import ShareUsCard from "./ShareUsCard";
import SeasonalCard from "./SeasonalCard";
import ReviewUsCard from "./ReviewUsCard";
import PosterCard from "./PosterCard";
import NewsCard from "./NewsCard";
import QuizEntryCard from "./QuizEntryCard";
import WordOfTheDayCard from "./WordOfTheDayCard";

class Feed extends Component {
  render() {
    const {
      item,
      category,
      updateUserPref,
      startPlaying,
      timerPlaying,
      setPlayPauseState,
      appFirstOpen,
      hasUIDInPath,
      startSharing,
      androidOpenURL,
      next,
      secondarySwiper,
      language,
      toggleQuiz,
      toggleInfoModal,
      gotoNewsItem,
      handleCategorySelection,
      guideStatus,
      virtualIndex,
    } = this.props;

    if (item.item_type === "district_selection_card") {
      return (
        <DistrictSelectionCard
          secondarySwiper={secondarySwiper}
          language={language}
          updateGlobalStateValue={this.props.updateGlobalStateValue}
        />
      );
    } else if (item.item_type === "category_interest_card") {
      return (
        <CategoryInterestCard
          secondarySwiper={secondarySwiper}
          language={language}
        />
      );
    } else if (item.item_type === "intro_card") {
      return (
        <IntroCard
          item={item}
          gotoNewsItem={gotoNewsItem}
          language={language}
          screenViewHeight={this.props.screenViewHeight}
          handleCategorySelection={handleCategorySelection}
          toggleOverlay={this.props.toggleOverlay}
          toggleInfoModal={toggleInfoModal}
          topTenHighlights={this.props.topTenHighlights}
        />
      );
    } else if (item.item_type === "quiz_entry_card") {
      return (
        <QuizEntryCard
          item={item}
          language={language}
          toggleQuiz={toggleQuiz}
          secondarySwiper={secondarySwiper}
        />
      );
    } else if (item.item_type === "end_card") {
      return (
        <EndCard
          key={item.uid}
          item={item}
          category={category}
          language={language}
          secondarySwiper={secondarySwiper}
        />
      );
    } else if (item.item_type === "share_us_card") {
      return (
        <ShareUsCard
          key={item.uid}
          item={item}
          language={language}
          secondarySwiper={secondarySwiper}
        />
      );
    } else if (item.item_type === "unread_completion_card") {
      return (
        <UnreadCompletionCard
          key={item.uid}
          item={item}
          language={language}
          secondarySwiper={secondarySwiper}
        />
      );
    } else if (item.item_type === "review_us_card") {
      return (
        <ReviewUsCard
          key={item.uid}
          item={item}
          language={language}
          secondarySwiper={secondarySwiper}
        />
      );
    } else if (item.item_type === "poster_card") {
      return <PosterCard key={item.uid} item={item} language={language} />;
    } else if (item.item_type === "seasonal_card") {
      return (
        <SeasonalCard
          key={item.uid}
          item={item}
          language={language}
          secondarySwiper={secondarySwiper}
          toggleOverlay={this.props.toggleOverlay}
        />
      );
    } else if (item.item_type === "wotd_card") {
      return <WordOfTheDayCard key={item.uid} language={language} />;
    } else {
      return (
        <NewsCard
          key={item.uid}
          item={item}
          updateUserPref={updateUserPref}
          startSharing={startSharing}
          timerPlaying={timerPlaying}
          setPlayPauseState={setPlayPauseState}
          appFirstOpen={appFirstOpen}
          hasUIDInPath={hasUIDInPath}
          startPlaying={startPlaying}
          androidOpenURL={androidOpenURL}
          next={next}
          secondarySwiper={secondarySwiper}
          language={language}
          toggleInfoModal={toggleInfoModal}
          handleCategorySelection={handleCategorySelection}
          guideStatus={guideStatus}
          virtualIndex={virtualIndex}
          isFeedAudioLoading={this.props.isFeedAudioLoading}
          firstSession={this.props.firstSession}
          onPrimarySwiperChange={this.props.onPrimarySwiperChange}
          toggleOverlay={this.props.toggleOverlay}
        />
      );
    }
  }
}

export default Feed;
