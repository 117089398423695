// export const API_PRODUCTION_URL = 'https://wmkrba1y1g.execute-api.us-east-1.amazonaws.com/prod';
export const API_PRODUCTION_URL = "https://api-in.katha.today/api/v1";
export const API_BASE_URL = API_PRODUCTION_URL;
export const WA_CURRENT_GROUP_ID = "EXfA3cCJ0OyFXelRnEq9Pi";

// Minimum version number required for app to work properly
// This will be compared with current version to force the user to update the app if the enabled flag is set to true
export const MIN_REQ_VERSION_NUMBER = 3.7;
export const MIN_REQ_VERSION_ENABLE = true;

export default {
    API_BASE_URL,
    WA_CURRENT_GROUP_ID
};
